import { Button, MenuItem, TextField } from "@material-ui/core";
import React, { memo, useState } from "react";
import {
  Control,
  useFieldArray,
  UseFieldArrayReturn,
  useForm,
  useWatch,
} from "react-hook-form";
import CustomCheckboxLabel from "../System/Components/CustomCheckboxLabel";
import QAmount from "./components/QAmount";
import QCheckboxLabel from "./components/QCheckboxLabel";
import QSelect from "./components/QSelect";
import QTextField from "./components/QTextField";

const ParseQuest = ({ form }): any => {
  const fields = useWatch({
    name: "fields",
    control: form.control,
  });

  return fields?.map((field) => (
    <div key={field.id} className="mb-2 border-b border-gray-200">
      {field.type === "checkbox" && (
        <div className="flex flex-col">
          <b>{field.label}</b>
          <span>{field.value ? "Yes" : "No"}</span>
        </div>
      )}

      {field.type === "input" && (
        <div className="flex flex-col">
          <b>{field.label}</b>
          <span>{field.value || "-"}</span>
        </div>
      )}

      {field.type === "amount" && (
        <div className="flex flex-col">
          <b>{field.label}</b>
          <span>{field.value || "-"}</span>
        </div>
      )}

      {field.type === "select" && (
        <div className="flex flex-col">
          <b>{field.label}</b>
          <span>{field.value || "-"}</span>
        </div>
      )}
    </div>
  ));
};

const ParseField = ({
  index,
  field,
  fieldArrayControl,
  control,
}: {
  index: number;
  field: any;
  fieldArrayControl: UseFieldArrayReturn<any, "fields">;
  control: Control<any>;
}) => {
  return (
    <div className="flex justify-between mb-2 pb-2 w-full items-end">
      <div className="w-full">
        {field.type === "checkbox" && (
          <QCheckboxLabel
            name={`fields.${index}.value`}
            label={field.label}
            control={control}
            rules={field.rules}
            required={field.required}
          />
        )}

        {field.type === "input" && (
          <QTextField
            name={`fields.${index}.value`}
            label={field.label}
            control={control}
            rules={field.rules}
            required={field.required}
            fullWidth
          />
        )}

        {field.type === "amount" && (
          <QAmount
            name={`fields.${index}.value`}
            label={field.label}
            control={control}
            rules={field.rules}
            required={field.required}
            fullWidth
          />
        )}

        {field.type === "select" && (
          <QSelect
            name={`fields.${index}.value`}
            label={field.label}
            control={control}
            rules={field.rules}
            required={field.required}
            options={field.options}
            fullWidth
          />
        )}
      </div>
      <div className="ml-2">
        <a onClick={() => fieldArrayControl?.remove(index)}>Remove</a>
      </div>
    </div>
  );
};

export default memo(() => {
  const form = useForm({
    mode: "onChange",
    defaultValues: {
      fields: [],
    },
  });

  const fieldArrayControl = useFieldArray<any>({
    control: form.control,
    name: "fields",
  });

  const [type, setType] = useState("input");
  const [label, setLabel] = useState("Test");
  const [required, setRequired] = useState(false);
  const [selectOptions, setSelectOptions] = useState([
    {
      label: "None",
      value: "",
    },
    {
      label: "",
      value: "",
    },
  ]);

  return (
    <div className="p-8 flex justify-center">
      <div className="flex max-w-[80rem] w-full">
        <div className="flex-1 border border-main rounded-md p-4 mr-2">
          <div className="flex flex-col">
            <h2 className="text-xl mb-2">Generator</h2>

            <div className="flex mb-2">
              <TextField
                value={type}
                onChange={(e: any) => setType(e.target.value)}
                select
                label="Type"
                InputLabelProps={{
                  shrink: true,
                }}
              >
                <MenuItem key="input" value="input">
                  Input
                </MenuItem>

                <MenuItem key="amount" value="amount">
                  Amount
                </MenuItem>

                <MenuItem key="select" value="select">
                  Select
                </MenuItem>

                <MenuItem key="checkbox" value="checkbox">
                  Checkbox
                </MenuItem>
              </TextField>
            </div>

            <div className="w-full">
              <TextField
                label="Label"
                value={label}
                onChange={(e) => setLabel(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                required={required}
              />

              <CustomCheckboxLabel
                checked={required}
                onChange={(e: any) => setRequired(e.target.checked)}
                label="Required"
                isSmall
              />

              {type === "select" && (
                <div className="flex flex-col mt-2">
                  {selectOptions.map((o, index) => (
                    <div
                      key={index}
                      className="flex flex-col bg-gray-100 mb-2 rounded-md p-2"
                    >
                      <span className="mb-2">Option {index + 1}</span>

                      <TextField
                        label="Label"
                        value={o.label}
                        disabled={index === 0}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(e: any) => {
                          setSelectOptions((prevState) => {
                            prevState[index].label = e.target.value;
                            return [...prevState];
                          });
                        }}
                      />
                      <TextField
                        label="Value"
                        disabled={index === 0}
                        value={o.value}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(e: any) => {
                          setSelectOptions((prevState) => {
                            prevState[index].value = e.target.value;
                            return [...prevState];
                          });
                        }}
                      />
                    </div>
                  ))}

                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      setSelectOptions((prevState) => [
                        ...prevState,
                        {
                          label: "",
                          value: "",
                        },
                      ]);
                    }}
                  >
                    Add Option
                  </Button>
                </div>
              )}
            </div>
          </div>

          <div className="w-full flex justify-end">
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                fieldArrayControl.append({
                  type: type,
                  label,
                  rules: {},
                  required,
                  options:
                    type === "select"
                      ? selectOptions.filter(
                          (o, i) => (o.value && i > 0) || i === 0
                        )
                      : undefined,
                });

                setType("input");
                setLabel("Test");
                setRequired(false);
                setSelectOptions([
                  {
                    label: "None",
                    value: "",
                  },
                  {
                    label: "",
                    value: "",
                  },
                ]);
              }}
            >
              Add Field
            </Button>
          </div>
        </div>

        <div className="flex-1 border border-main rounded-md p-4 ml-2">
          <h2 className="text-xl mb-4">View</h2>

          {fieldArrayControl?.fields.map((field, index) => {
            return (
              <ParseField
                key={field.id}
                index={index}
                field={field}
                fieldArrayControl={fieldArrayControl}
                control={form.control}
              />
            );
          })}

          {fieldArrayControl?.fields.length === 0 && <p>Empty</p>}

          <h2 className="text-xl mb-4">Result</h2>

          <ParseQuest form={form} />

          {fieldArrayControl?.fields.length === 0 && <p>Empty</p>}

          <div className="flex justify-end w-full">
            <Button
              color="primary"
              variant="contained"
              onClick={form.handleSubmit((values) => {
                console.log(values);
              })}
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
});
