import { TrendingUp } from "@material-ui/icons";
import React from "react";
import { formatToCurrencyString } from "../../../../../../helpers";
import { useGetAccounts } from "../hooks/useGetAccounts";

const CurrentRate = ({ rate, form, userId }) => {
  const { fromAccount, toAccount } = useGetAccounts({ userId, form });

  return !rate ? null : (
    <div className="flex justify-end">
      <div className="flex bg-white text-main rounded-2xl z-10 w-auto left-1/2 items-center p-1 border-white">
        <TrendingUp />
        <b className="ml-2 text-lg">
          {formatToCurrencyString(1, fromAccount?.currencyId, true)} ={" "}
          {formatToCurrencyString(
            rate?.toFixed(8),
            toAccount?.currencyId,
            false,
            false,
            undefined,
            4
          )}
        </b>
      </div>
    </div>
  );
};

export default CurrentRate;
