import {
  LinearProgress,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import { view } from "@risingstack/react-easy-state";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import { formatToCurrencyString } from "../../../../../../../helpers";
import { formatDateToMMddyyyyHHmm } from "../../../../../../../helpers/formatDate";
import { getTaskStateBooleans } from "../../../../../../../helpers/task";
import tasks from "../../../../../../../store/modules/tasks";
import templates from "../../../../../../../store/modules/templates";
import { Alert, FormActions } from "../../../../../../System";
import Currency from "./Currency";
import TemplateEditor from "./TemplateEditor";
import Type from "./Type";

const Form = view(({ setOpen, taskId, body, task = {} }) => {
  const [step, setStep] = useState(1);
  const { items, loading: loadingItems } = templates;

  const refActions = useRef<any>(undefined);
  const history = useHistory();

  const data = useMemo(() => {
    return items.map((item: any) => {
      if (taskId) {
        item.subject = item.subject.replaceAll("{NotificationId}", taskId);
      }
      if (body.Representative) {
        item.body = item.body.replaceAll("{UserName}", body.Representative);
      } else if (body.Name) {
        item.body = item.body.replaceAll("{UserName}", body.Name);
      }

      if (body.Amount) {
        item.body = item.body.replaceAll(
          "{Amount}",
          formatToCurrencyString(body.Amount, body.Currency)
        );
      }

      if (taskId) {
        item.body = item.body.replaceAll("{NotificationId}", taskId);
      }

      if (task?.ts) {
        item.body = item.body.replaceAll(
          "{NotificationAt}",
          formatDateToMMddyyyyHHmm(task?.ts)
        );
      }

      if (body.ToAccountNumber) {
        item.body = item.body.replaceAll(
          "{AccountNumber}",
          body.ToAccountNumber
        );
      }

      if (taskId) {
        item.body = item.body.replaceAll("{NotificationId}", taskId);
      }

      if (body.NameOnAccount) {
        item.body = item.body.replaceAll("{AccountName}", body.NameOnAccount);
      }

      if (body.NameOnAccount) {
        item.body = item.body.replaceAll(
          "{SendingAccountName}",
          body.NameOnAccount
        );
      }

      if (task?.fromUser?.name) {
        item.body = item.body.replaceAll(
          "{MigomAccountName}",
          task?.fromUser?.name
        );
      }

      return item;
    });
  }, [
    body.Amount,
    body.Currency,
    body.Name,
    body.NameOnAccount,
    body.Representative,
    body.ToAccountNumber,
    items,
    task?.fromUser?.name,
    task?.ts,
    taskId,
  ]);

  const [template, setTemplate] = useState<number | undefined>();
  const [payload, setPayload] = useState<Record<string, any>>();
  const [loading, setLoading] = useState(false);

  const init = useRef<any>(false);

  useEffect(() => {
    if (
      data &&
      data?.length > 0 &&
      typeof template === "number" &&
      template > 0
    ) {
      setPayload(data[template]);
    }

    if (!init.current && data && data?.length === 1) {
      init.current = true;
      setPayload(data[0]);
      setTemplate(0);
      setStep(2);
    }
  }, [template, data]);

  const goBack = () => {
    setStep((prevState) => {
      return prevState - 1;
    });
  };

  const next = () => {
    setStep((prevState) => {
      return prevState + 1;
    });
  };

  const close = () => {
    setOpen(false);
  };

  const [openResult, setOpenResult] = useState(false);

  const confirm = useCallback(
    async (event) => {
      event.preventDefault();

      setLoading(true);

      const result = await tasks.sendWireInfo(taskId, {
        message: payload?.body,
        subject: payload?.subject,
        toUserId: task?.fromUser?.id,
        toEmail: body?.Email,
      });

      if (result) {
        setOpenResult(true);
        history.goBack();
      }

      setLoading(false);
    },
    [
      body?.Email,
      history,
      payload?.body,
      payload?.subject,
      task?.fromUser?.id,
      taskId,
    ]
  );

  const dataMap = data.map((item, index) => {
    const selectTemplate = (event) => {
      event.preventDefault();
      setTemplate(index);
      refActions.current.scrollIntoView({
        block: "center",
        behavior: "smooth",
      });
    };
    const htmlString = <div dangerouslySetInnerHTML={{ __html: item.body }} />;
    return (
      <ListItem
        key={index}
        onClick={selectTemplate}
        selected={index === template}
        style={{ cursor: "pointer" }}
      >
        <ListItemText
          primary={
            <b>
              #{index + 1}. {item.name} || {item.depositMessageTypeId} |{" "}
              {item.currencyId} | {item.subject}
            </b>
          }
          secondary={htmlString}
        />
      </ListItem>
    );
  });

  return (
    <>
      {step === 1 && (
        <div>
          <div className="flex">
            <div className="mr-2">
              <Currency />
            </div>
            <div>
              <Type />
            </div>
          </div>

          {loadingItems && <LinearProgress />}

          {!loadingItems && (
            <>
              {data?.length > 0 ? (
                <List>{dataMap}</List>
              ) : (
                <>
                  <p className="text-center text-base text-main">
                    At the moment, there are no templates for currency{" "}
                    {body?.Currency} and type {body?.SwiftOrSepa}
                  </p>
                  <p className="text-center">
                    <a
                      onClick={() => {
                        templates.fetchItems({ currency: "", type: "" });
                      }}
                    >
                      Try to find all templates for all currencies?
                    </a>
                  </p>
                </>
              )}
            </>
          )}

          <FormActions
            showCloseButton
            showNextButton
            disabledNextButton={
              template === undefined || loadingItems || loading
            }
            refActions={refActions}
            onClickNext={next}
            onClickClose={close}
          />
        </div>
      )}
      {step === 2 && (
        <div>
          <TemplateEditor
            body={body}
            template={(typeof template === "number" && data?.[template]) || {}}
            payload={payload}
            setPayload={setPayload}
          />

          {!openResult && (
            <FormActions
              showBackButton
              showConfirmButton
              confirmButtonLabel="Send to Client"
              idConfirmButton="deposit-choose-template-send-to-client-btn"
              onClickBack={goBack}
              onClickConfirm={confirm}
              disabledConfirmButton={loading}
              loading={loading}
            />
          )}

          {openResult && (
            <FormActions
              showConfirmButton
              confirmButtonLabel="Information sent, close"
              onClickConfirm={close}
            />
          )}
        </div>
      )}
    </>
  );
});

const ChooseTemplate = ({ taskId, open, setOpen, task, fetchTask }) => {
  const ref = useRef<any>(false);

  const taskStateBooleans = getTaskStateBooleans(task);

  let body;

  if (taskStateBooleans.isMessage) {
    body = task?.body;
  } else {
    body = task?.body ? JSON.parse(task?.body) : {};
  }

  useEffect(() => {
    if (open && !ref.current) {
      ref.current = true;

      templates.fetchItems({
        currency: body.Currency,
        type: body.SwiftOrSepa,
      });
    }

    return () => {
      templates.items = [];
    };
  }, [open, body.Currency, body.SwiftOrSepa]);

  return (
    <Alert
      open={open}
      setOpen={setOpen}
      content={
        <Form
          setOpen={setOpen}
          taskId={taskId}
          body={body}
          task={task}
          fetchTask={fetchTask}
        />
      }
      size="md"
    />
  );
};

export default ChooseTemplate;
