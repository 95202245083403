import { Button, DialogActions } from "@material-ui/core";
import { ArrowUpward } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import numbro from "numbro";
import React, { useEffect, useMemo, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { formatToCurrencyString } from "../../../../../../helpers";
import { useEnter } from "../../../../../../hooks/useEnter";
import admin from "../../../../../../store/modules/admin";
import auth from "../../../../../../store/modules/auth";
import MigomCryptoSender from "../../../../../MigomCryptoSender";
import { Alert, AreYouSure } from "../../../../../System";
import MyAmount from "../../../../../System/FormComponents/MyAmount";
import MySimpleMenu from "../../../../../System/FormComponents/MySimpleMenu";
import ShowAndHide from "../../../../../System/FormComponents/ShowAndHide";
import LazyImage from "../../../../../System/LazyImage";
import {
  makeWithdrawFeeTypes,
  setWithdrawFee,
} from "../../../../Admin/SmartForms/Withdraw/tools";
import AdvancedOptionsPart2 from "../components/AdvancedOptionsPart2";
import Amount from "../components/Amount";
import Comment from "../components/Comment";
import CryptoAdvancedOptions from "../components/CryptoAdvancedOptions";
import FixedOurCost from "../components/FixedOurCost";
import GreenButton from "../components/GreenButton";
import InitFee from "../components/InitFee";
import Password from "../components/Password";
import Preview from "../components/Preview";
import SfoxDetail from "../components/SfoxDetail";
import ShowAdvancedOptions from "../components/ShowAdvancedOptions";
import Title from "../components/Title";
import TitleContent from "../components/TitleContent";

const WrapFeeType = ({ form, account }) => {
  const options = makeWithdrawFeeTypes(account);

  return (
    <MySimpleMenu
      {...form}
      name="feeType"
      renderOption={(option) => option.label}
      label="Fee type"
      options={options}
      fullWidth
    />
  );
};

const OldWithdrawCrypto = ({ open, setOpen, task }) => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const memo = task?.memo ? `| ${task?.memo}` : "";

  const defaultValues = useMemo(
    () => ({
      currencyId: task?.currencyId,
      amount: task?.amount,
      feeType: "",
      comment: `Withdraw ${task?.currencyId} to ${
        task?.toCryptoRecipient?.address || task?.toWalletAddress || "???"
      } ${memo}`,
      fixedOurCost: "",
      percentageOurCost: "",
      usePercentage: true,
      showAdvancedOptions: false,
      fixedFee: "",
      transactionTime: null,
      feeStructure: null,
      feeRule: null,
      isPendingTransaction: false,
      feeInsideAmount: true,
      allowNegativeBalance: false,
      password: "",
      useSfox: false,
      showPassword: false,

      useSepa: false,
    }),
    [
      memo,
      task?.amount,
      task?.currencyId,
      task?.toCryptoRecipient?.address,
      task?.toWalletAddress,
    ]
  );

  const [loading, setLoading] = useState(false);
  const [hideConfirmButtons, setHideConfirmButtons] = useState(false);
  const [previewData, setPreviewData] = useState<any>();
  const [step, setStep] = useState(1);
  const [openAreYouSure, setOpenAreYouSure] = useState(false);

  const form = useForm({
    defaultValues: defaultValues,
    shouldUnregister: false,
  });

  const { handleSubmit, setValue } = form;

  useEffect(() => {
    const defaultFeeTypes = makeWithdrawFeeTypes(task?.fromAccount);

    if (defaultFeeTypes?.[0]?.value && task?.fromAccount && open) {
      setTimeout(() => setValue("feeType", defaultFeeTypes[0].value), 0);
    }
  }, [setValue, open, task?.fromAccount]);

  const preview = async (values) => {
    setLoading(true);

    const payload: any = {
      isPending: values.isPendingTransaction,
      allowNegativeBalance: values.allowNegativeBalance,
      pendingTransactionId: task?.taskId,
      btcWalletAddress: task?.toCryptoRecipient?.address,
      description: `Withdraw ${values.amount} ${task?.currencyId} to account ${task?.fromAccount?.accountId}, ${values.comment}`,
      operations: [],
    };

    if (values.transactionTime) {
      payload.transactionTime = new Date(values.transactionTime).toISOString();
    }

    const a = numbro.unformat(values.amount) || 0;
    const p = numbro.unformat(values.percentageOurCost) || 0;
    const f = numbro.unformat(values.fixedOurCost) || 0;

    let ourCost = f;

    if (values.usePercentage) {
      ourCost = ourCost + (a / 100) * p;
    }

    const minus: any = {
      ourCost: ourCost,
      accountId: task?.fromAccount?.accountId,
      currencyId: task?.currencyId,
      amount: -a,
      comment: values.comment,
    };

    minus.fee = setWithdrawFee(values);

    payload.operations.push(minus);

    const [status, result] = await admin.preview(payload);

    if (status) {
      if (result?.cryptoWithdrawOperation) {
        setTimeout(() => setValue("useSfox", true), 0);
      }

      setPreviewData(result);
      setStep(2);
    }

    setLoading(false);
  };

  const confirm = async () => {
    setLoading(true);

    const payload = { ...previewData };

    if (!useSfox && payload?.cryptoWithdrawOperation) {
      delete payload.cryptoWithdrawOperation;
    }

    const result = await admin.confirm(previewData);

    if (result) {
      setHideConfirmButtons(true);

      enqueueSnackbar("Operation completed successfully", {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });

      if (!!auth?.profile && auth?.profile?.isAdmin) {
        history.goBack();
      } else {
        history.push("/app/tasks/inbox/crypto");
      }
    }

    setLoading(false);
  };

  const useSfox = useWatch({
    name: "useSfox",
    control: form.control,
  });

  const sfox = previewData?.cryptoWithdrawOperation || {};

  useEnter(() => {
    if (step === 1) {
      handleSubmit(preview)();
    }

    if (step === 2 && !openAreYouSure) {
      setOpenAreYouSure(true);
    }
  });

  return (
    <Alert
      open={open}
      setOpen={setOpen}
      size={step === 1 ? "sm" : "md"}
      content={
        <>
          <InitFee
            form={form}
            open={open}
            task={task}
            calculatorType="WithdrawCrypto"
          />

          <Title
            icon={
              <ArrowUpward style={{ color: "inherit", fontSize: "2.4rem" }} />
            }
            text={<TitleContent title="Withdraw" />}
          />

          <div className="h-4" />

          <ShowAndHide show={step === 1}>
            <Amount {...form} autoFocus />

            <div className="h-2.5" />

            <WrapFeeType form={form} account={task?.fromAccount} />

            <div className="h-2.5" />

            <Comment {...form} />

            <div className="h-2.5" />

            <MyAmount
              control={form.control}
              controlCurrency={form.control}
              name="fixedFee"
              label="Fixed Fee (the client pays us)"
              currencyName="currencyId"
            />

            <div className="h-2.5" />

            <FixedOurCost {...form} />

            <ShowAdvancedOptions {...form} />

            <CryptoAdvancedOptions {...form} />

            <AdvancedOptionsPart2 {...form} hideDetails />

            <div className="h-2.5" />

            <Password {...form} />

            <DialogActions>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setOpen(false)}
              >
                Close
              </Button>

              <GreenButton
                onClick={handleSubmit(preview)}
                label="Next"
                loading={loading}
              />
            </DialogActions>
          </ShowAndHide>

          <ShowAndHide show={step === 2}>
            <Preview
              data={previewData}
              form={form}
              setStep={setStep}
              confirm={() => setOpenAreYouSure(true)}
              hideConfirmButtons={hideConfirmButtons}
              loading={loading}
              setOpen={setOpen}
              task={task}
            />
          </ShowAndHide>

          {openAreYouSure && (
            <AreYouSure
              open={openAreYouSure && useSfox}
              customIcon={
                task?.currencyId === "MIGG" ? (
                  <MigomCryptoSender />
                ) : (
                  <LazyImage
                    src="/images/design/sfox_logo.png"
                    className="w-40"
                  />
                )
              }
              setOpen={setOpenAreYouSure}
              confirmCallback={confirm}
              loading={loading}
              disabledConfirmCallback={loading}
              confirmLabel="Make Payment"
              customText={
                <div className="mt-4">
                  <h2 className="mb-2">
                    Ready to pay{" "}
                    {formatToCurrencyString(
                      sfox?.cryptoAmount || 0,
                      sfox?.cryptoCurrencyId?.toUpperCase()
                    )}
                  </h2>

                  <SfoxDetail showDetail sfox={sfox} />
                </div>
              }
              hideCloseButton
            />
          )}

          {openAreYouSure && (
            <AreYouSure
              open={openAreYouSure && !useSfox}
              setOpen={setOpenAreYouSure}
              confirmCallback={confirm}
              loading={loading}
              disabledConfirmCallback={loading}
              confirmLabel="Make Payment"
              hideCloseButton
            />
          )}
        </>
      }
    />
  );
};

export default OldWithdrawCrypto;
