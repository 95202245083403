import { lazy } from "react";
import CardRequests from "../../components/Roles/CardAdmin/CardRequests";

const AllAccounts = lazy(
  () => import("../../components/Roles/CardAdmin/AllAccounts2")
);

const UserAccount = lazy(
  () => import("../../components/Roles/CardAdmin/AllAccounts/Account")
);

const GlobalCards = lazy(
  () => import("../../components/Roles/CardAdmin/GlobalCards")
);

const Card = lazy(() => import("../../components/Roles/CardAdmin/Card"));

const CardRequest = lazy(
  () => import("../../components/Roles/CardAdmin/CardRequest")
);

export const CardAdmin = [
  {
    path: "/app/card-admin/card-requests/:taskId",
    component: CardRequest,
  },
  {
    path: "/app/card-admin/clients/:userId/cards/:cardId",
    component: Card,
  },
  {
    path: "/app/card-admin/accounts/:accountId",
    component: UserAccount,
  },

  {
    path: "/app/card-admin/accounts",
    component: AllAccounts,
  },

  {
    path: "/app/card-admin/global-cards",
    component: GlobalCards,
  },
  {
    path: "/app/card-admin/card-requests",
    component: CardRequests,
  },
];
