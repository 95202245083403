import { MenuItem, TextField } from "@material-ui/core";
import {
  ArrowDownward,
  ArrowUpward,
  ChevronLeft,
  ChevronRight,
} from "@material-ui/icons";
import { clsx } from "clsx";
import React, { memo, useState } from "react";
import { is } from "../../helpers/is";

export default memo(
  ({
    columns = [],
    rows = [],
    onSort,
    defaultSort,
    onRowClick,
    loading,
    page,
    perPage,
    pages,
    onChangePage,
    onChangePerPage,
    rowClassName,
  }: any) => {
    const [config, setConfig] = useState<any>({
      sortBy: defaultSort?.sortBy,
      sortDirection: defaultSort?.sortDirection,
    });

    return (
      <>
        {loading ? (
          <div className="w-full h-1 rounded-md animate-pulse bg-main"></div>
        ) : (
          <div className="w-full h-1"></div>
        )}

        <div className="overflow-x-auto relative w-full pb-4 pr-2">
          <table
            className={clsx(
              loading && "animate-pulse",
              "border border-gray-100 w-full"
            )}
          >
            <thead>
              <tr>
                {columns.map((column, index) => {
                  return (
                    <th
                      onClick={() => {
                        if (column.disableSort) {
                          return;
                        }

                        if (column.id && column.id !== config.sortBy) {
                          setConfig((prevState) => {
                            const config = {
                              ...prevState,
                              sortBy: column.id,
                              sortDirection: "asc",
                            };

                            onSort(config);

                            return config;
                          });
                          return;
                        }

                        if (column.id && config.sortBy === column.id) {
                          if (config.sortDirection === "") {
                            setConfig((prevState) => {
                              const config = {
                                ...prevState,
                                sortDirection: "asc",
                              };

                              onSort(config);

                              return config;
                            });
                            return;
                          }
                          if (config.sortDirection === "asc") {
                            setConfig((prevState) => {
                              const config = {
                                ...prevState,
                                sortDirection: "desc",
                              };

                              onSort(config);

                              return config;
                            });
                            return;
                          }
                          if (config.sortDirection === "desc") {
                            setConfig((prevState) => {
                              const config = {
                                ...prevState,
                                sortDirection: undefined,
                                sortBy: undefined,
                              };

                              onSort(config);

                              return config;
                            });
                            return;
                          }
                        }

                        if (column.id && config.sortBy === "") {
                          setConfig((prevState) => {
                            const config = {
                              ...prevState,
                              sortBy: column.id,
                              sortDirection: "asc",
                            };

                            onSort(config);

                            return config;
                          });
                        }
                      }}
                      key={index}
                      className={clsx(
                        column.className,
                        column.disableSort
                          ? "cursor-default"
                          : "cursor-pointer",
                        "z-40 select-none sticky top-0 px-2 py-2 text-xs bg-gray-100 text-black uppercase font-normal"
                      )}
                    >
                      <div
                        className={clsx(
                          column.right
                            ? "justify-end text-right"
                            : "justify-start text-left",
                          column.nowrap && "whitespace-nowrap",
                          "flex items-start"
                        )}
                      >
                        {config.sortBy === column.id &&
                          config.sortDirection === "asc" && (
                            <ArrowUpward className="mr-1" fontSize="small" />
                          )}
                        {config.sortBy === column.id &&
                          config.sortDirection === "desc" && (
                            <ArrowDownward className="mr-1" fontSize="small" />
                          )}
                        <span>
                          {is(String, column.label)
                            ? column.label
                            : column.label()}
                        </span>
                      </div>
                    </th>
                  );
                })}
              </tr>
            </thead>

            <tbody>
              {rows.map((row, index) => {
                return (
                  <tr
                    key={index}
                    className={clsx(
                      rowClassName && rowClassName(row),
                      onRowClick && "cursor-pointer",
                      "hover:bg-gray-100"
                    )}
                    onClick={() => onRowClick && onRowClick(row, index)}
                  >
                    {columns?.map((column, index) => (
                      <td
                        key={index}
                        onClick={(e) => column?.onCell && column?.onCell(e)}
                        className={clsx(
                          column.className,
                          column.right && "text-right",
                          column.nowrap && "whitespace-nowrap",
                          "border-b border-r border-gray-100 px-2 py-2"
                        )}
                      >
                        {column.render(row)}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <div className="flex justify-end mt-2 pb-4">
          {perPage !== undefined && (
            <div className="mr-4">
              <TextField
                select
                value={perPage}
                label="Rows"
                onChange={(e) =>
                  onChangePerPage && onChangePerPage(e.target.value)
                }
              >
                {[
                  {
                    label: "100",
                    value: 100,
                  },
                  {
                    label: "50",
                    value: 50,
                  },
                  {
                    label: "20",
                    value: 20,
                  },
                ].map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          )}

          {page !== undefined && (
            <div className="flex flex-col items-center">
              <span className="mb-1">Total Pages: {pages || 0}</span>

              <div className="flex items-center">
                <div
                  className={clsx(
                    page <= 1 || page > pages
                      ? "text-gray-200 cursor-not-allowed"
                      : "text-black cursor-pointer",
                    "px-2 select-none"
                  )}
                  onClick={() => {
                    if (page === 1) {
                      return;
                    }

                    onChangePage && onChangePage(Number(page) - 1);
                  }}
                >
                  <ChevronLeft className="fill-current w-4" />
                </div>
                <input
                  type="number"
                  value={page}
                  min={1}
                  max={pages}
                  step={1}
                  className="w-14 border border-gray-300 rounded-md !m-0 text-center"
                  onChange={(e: any) =>
                    onChangePage && onChangePage(e.target.value)
                  }
                />
                <div
                  className={clsx(
                    page >= pages || page < 1
                      ? "text-gray-200 cursor-not-allowed"
                      : "text-black cursor-pointer",
                    "px-2 select-none"
                  )}
                  onClick={() => {
                    if (page === pages) {
                      return;
                    }

                    onChangePage && onChangePage(Number(page) + 1);
                  }}
                >
                  <ChevronRight className="fill-current w-4" />
                </div>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
);
