import { useAtom } from "jotai";
import React, { memo, useCallback, useMemo } from "react";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { formatDateSmart } from "../../../../helpers/formatDate";
import { useQuerySocketTasks } from "../../../../queries/useQuerySocketTasks";
import auth from "../../../../store/modules/auth";
import CopyAccountNumber from "../../../System/CopyAccountNumber";
import SimpleTable from "../../Admin/Reports/Features/components/SimpleTable";
import { globalParamsAtom } from "./atoms/globalParamsAtom";
import CurrenciesFilter from "./components/CurrenciesFilter";
import SearchFilter from "./components/SearchFilter";
import UnreadFilter from "./components/UnreadFilter";
import { useQueryCardRequests } from "./queries/useQueryCardRequests";
import { useQueryCardTypes } from "./queries/useQueryCardTypes";

export default memo(() => {
  const history = useHistory();
  const queryClient = useQueryClient();

  const [params, setParams] = useAtom(globalParamsAtom);
  const tableQuery = useQueryCardRequests(params, true);
  const cardTypes = useQueryCardTypes();

  const { data: listOfCardTypes } = cardTypes;

  const onRowClick = useCallback(
    (row) => {
      history.push(`/app/card-admin/card-requests/${row.taskId}`);
    },
    [history]
  );

  useQuerySocketTasks({
    onUpdated: (data: any) => {
      if (data?.taskId) {
        const queryParams = {
          ...params,
          lessThan: params.lessThan === "None" ? undefined : params.lessThan,
          moreThan: params.moreThan === "None" ? undefined : params.moreThan,
          currencies: params.currencies.join(","),
          taskTypes: params.taskTypes.join(","),
        };

        queryClient.setQueryData(
          ["useQueryCardRequests", queryParams],
          (old: any) => {
            if (!old?.data) {
              return;
            }

            if (
              params.search ||
              params.currencies?.length > 0 ||
              params.lessThan !== "None" ||
              params.moreThan !== "None"
            ) {
              return { ...old };
            }

            const findIndex = old.data.findIndex(
              (item) => item.taskId === data.taskId
            );

            if (findIndex > -1 && !old.data[findIndex].opened) {
              old.data[findIndex].opened = true;
            }

            const taskToOperator = data?.currentUserId === auth.profile?.userId;

            if (data.taskStateId !== "Pending" || !taskToOperator) {
              old.data = old.data.filter((item) => item.taskId !== data.taskId);

              return { ...old };
            } else if (findIndex > -1) {
              old.data[findIndex] = data;
              old.data = [...old.data];
              return { ...old };
            }

            if (
              params?.page === 1 &&
              params.taskTypes.includes(data.taskTypeId)
            ) {
              old.data.unshift(data);
              old.data = [...old.data];
            }

            return { ...old };
          }
        );
      }
    },
  });

  const columns = useMemo(() => {
    return [
      {
        label: "ID",
        id: "taskId",
        nowrap: true,
        render: (rowData) => (
          <span>
            {rowData?.taskId} <CopyAccountNumber number={rowData?.taskId} />
          </span>
        ),
      },
      {
        label: "Currency",
        id: "currencyId",
        nowrap: true,
        render: (rowData) => <span>{rowData?.currencyId}</span>,
      },
      {
        label: "Type",
        id: "paymentPurposeId",
        nowrap: true,
        render: (rowData) => {
          const findType = (listOfCardTypes || [])?.find((item) => {
            return item?.id === (rowData?.memo || rowData?.paymentPurposeId);
          });

          return <span>{findType?.name || ""}</span>;
        },
      },
      {
        label: "From",
        id: "firstUserName",
        nowrap: true,
        render: (rowData) => (
          <span className="text-ellipsis overflow-hidden whitespace-nowrap w-32">
            {rowData?.fromUserName}
          </span>
        ),
      },

      {
        label: "Date",
        nowrap: true,
        id: "ts",
        render: (rowData) => formatDateSmart(rowData?.ts),
      },
    ];
  }, [listOfCardTypes]);

  const rowClassName = useCallback((row) => {
    if (row.opened === undefined) {
      return "";
    }

    if (!row.opened) {
      return "font-bold";
    }

    return "";
  }, []);

  return (
    <div className="w-full">
      <div className="flex flex-wrap flex-row items-end w-full flex-1 mb-2">
        <SearchFilter params={params} setParams={setParams} />

        <UnreadFilter params={params} setParams={setParams} />

        <CurrenciesFilter params={params} setParams={setParams} />
      </div>

      <SimpleTable
        columns={columns}
        queryData={tableQuery}
        setParams={setParams}
        params={params}
        rowClassName={rowClassName}
        onRowClick={onRowClick}
      />
    </div>
  );
});
