import { useAtom } from "jotai";
import React, { memo, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import ui from "../../../../../../store/modules/ui";
import { makeDates } from "../../../AllClients/hooks/useFilter";
import CurrencyCell from "../components/CurrencyCell";
import FeatureBranchesFilter from "../components/FeatureBranchesFilter";
import FeatureDateFilter from "../components/FeatureDateFilter";
import FeatureIgnoreZeroValue from "../components/FeatureIgnoreZeroValue";
import FeatureLessThan from "../components/FeatureLessThan";
import FeatureMoreThan from "../components/FeatureMoreThan";
import FeatureRadioDataType from "../components/FeatureRadioDataType";
import FeatureSearchFilter from "../components/FeatureSearchFilter";
import FeatureTotalCurrency from "../components/FeatureTotalCurrency";
import SimpleTable from "../components/SimpleTable";
import TotalCell from "../components/TotalCell";
import { useAvailableCurrencies } from "../hooks/useAvailableCurrencies";
import { branchFiltersAtom } from "./atoms/branchFilters";
import { branchParamsAtom } from "./atoms/branchParams";
import { useQueryPNLBranch } from "./queries/useQueryPNLBranch";

export default memo(() => {
  const history = useHistory();

  const [enabled, setEnabled] = useState(false);

  const [params, setParams] = useAtom(branchParamsAtom);

  const tableQuery = useQueryPNLBranch(params, enabled);

  const [filters, setFilters] = useAtom(branchFiltersAtom);

  const availableCurrencies = useAvailableCurrencies(
    tableQuery?.data?.data,
    filters
  );

  useEffect(() => {
    setParams((prevState) => {
      return {
        ...prevState,
        showHidden: ui.dashboardPnlShowHidden ? true : false,
      };
    });

    setEnabled(true);
  }, [setParams]);

  const columns = useMemo(() => {
    return [
      {
        id: "branchName",
        label: "Branch Name",
        nowrap: true,
        render: (rowData) => {
          const isTotal = rowData?.branchName?.includes("Total");
          return (
            <a
              className={isTotal ? "font-bold" : "font-normal"}
              onClick={() => {
                const { from, to } = makeDates({ dateType: filters.dateType });

                history.push("/app/admin/reports/pnl/full-details", {
                  branchId: rowData?.branchId,
                  from: from,
                  to: to,
                  branchName: rowData?.branchName,
                });
              }}
            >
              {rowData?.branchName}
            </a>
          );
        },
      },
      ...availableCurrencies.map((c) => ({
        id:
          filters.dataType === "+/-"
            ? c.value
            : c.value + "-" + filters.dataType,
        label: c.value,
        nowrap: true,
        right: true,
        render: (rowData) => {
          const isTotal = rowData?.branchName?.includes("Total");
          return (
            <a
              className={isTotal ? "font-bold" : "font-normal"}
              onClick={() => {
                const { from, to } = makeDates({ dateType: filters.dateType });

                history.push("/app/admin/reports/pnl/full-details", {
                  currencyId: c.value,
                  branchId: rowData?.branchId,
                  branchName: rowData?.branchName,
                  from: from,
                  to: to,
                });
              }}
            >
              <CurrencyCell
                rowTotalKey="branchName"
                rowData={rowData}
                c={c}
                filters={filters}
                params={params}
              />
            </a>
          );
        },
      })),
      {
        id:
          filters.dataType === "+/-"
            ? "total"
            : "total" + "-" + filters.dataType,
        label: "Total",
        nowrap: true,
        right: true,
        className: "bg-gray-100",
        render: (rowData) => {
          return (
            <a
              onClick={() => {
                const { from, to } = makeDates({ dateType: filters.dateType });

                history.push("/app/admin/reports/pnl/full-details", {
                  branchId: rowData?.branchId,
                  from: from,
                  to: to,
                  branchName: rowData?.branchName,
                });
              }}
            >
              <TotalCell rowData={rowData} params={params} filters={filters} />
            </a>
          );
        },
      },
    ];
  }, [availableCurrencies, filters, params, history]);

  return (
    <div className="w-full">
      <div className="flex flex-wrap flex-row items-end w-full flex-1 mb-2">
        <FeatureSearchFilter params={params} setParams={setParams} />
        <FeatureBranchesFilter params={params} setParams={setParams} />
        <FeatureDateFilter
          filters={filters}
          params={params}
          setParams={setParams}
          setFilters={setFilters}
        />
        <FeatureRadioDataType
          filters={filters}
          setFilters={setFilters}
          setParams={setParams}
        />
        <FeatureTotalCurrency params={params} setParams={setParams} />
        <FeatureLessThan params={params} setParams={setParams} />
        <FeatureMoreThan params={params} setParams={setParams} />
        <FeatureIgnoreZeroValue params={params} setParams={setParams} />
      </div>

      <SimpleTable
        columns={columns}
        queryData={tableQuery}
        setParams={setParams}
        params={params}
        rowClassName={(row) => {
          const isTotalRow = row?.branchName?.includes(`Total`);

          if (isTotalRow) {
            return "bg-gray-100";
          }
        }}
      />
    </div>
  );
});
