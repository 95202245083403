import { MenuItem, TextField } from "@material-ui/core";
import React, { memo } from "react";
import { useController } from "react-hook-form";
import ErrorMessage from "./ErrorMessage";

export default memo(
  ({
    control,
    name,
    rules,
    disabled,
    label,
    fullWidth,
    options,
    required,
    autoFocus,
  }: any) => {
    const controller = useController({
      name: name,
      control: control,
      rules: {
        required: required ? `Field '${label}' is required` : false,
        ...(rules || {}),
      },
    });

    const mapOptions = options.map((item) => (
      <MenuItem key={item.value} value={item.value}>
        {item.label}
      </MenuItem>
    ));

    return (
      <>
        <TextField
          name={controller?.field?.name}
          onChange={controller?.field?.onChange}
          onBlur={controller?.field?.onBlur}
          value={controller?.field?.value || ""}
          inputRef={controller?.field?.ref}
          InputLabelProps={{
            shrink: true,
          }}
          label={label}
          disabled={disabled}
          fullWidth={fullWidth}
          autoFocus={autoFocus}
          SelectProps={{
            displayEmpty: true,
          }}
          required={required}
          select
        >
          {mapOptions}
        </TextField>

        <ErrorMessage controller={controller} />
      </>
    );
  }
);
