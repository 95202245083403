import { FormHelperText } from "@material-ui/core";
import { view } from "@risingstack/react-easy-state";
import React from "react";
import { formatToCurrencyString } from "../../../../../../helpers";
import auth from "../../../../../../store/modules/auth";
import MySimpleMenu from "../../../../../System/FormComponents/MySimpleMenu";
import { useGetAccounts } from "../hooks/useGetAccounts";

const AutocompleteFromAccount = view(({ form, currentAccount }) => {
  const { options, toAccount } = useGetAccounts({
    userId: currentAccount?.userId,
    form,
  });

  const makeOptionString = (option) => {
    return option?.currencyId
      ? `${option?.currencyId || ""} ${formatToCurrencyString(
          option?.availableBalance,
          option?.currencyId
        )} `
      : "";
  };

  return (
    <div className="w-full">
      <MySimpleMenu
        {...form}
        name="fromAccountId"
        label="Account"
        disabled={!!auth.profile && !auth.profile?.isAdmin}
        onClick={(option) => {
          setTimeout(
            () => form.setValue("sellCurrencyId", option.currencyId),
            0
          );
        }}
        rules={{ required: true }}
        fullWidth
        renderOption={makeOptionString}
        options={options
          .filter((item) => item.currencyId !== toAccount?.currencyId)
          .map((item) => ({ ...item, value: item.accountId }))}
      />

      {form?.formState?.errors?.fromAccount?.type === "required" && (
        <FormHelperText error>Field is required</FormHelperText>
      )}
    </div>
  );
});

export default AutocompleteFromAccount;
