import { TextField } from "@material-ui/core";
import { clsx } from "clsx";
import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useController } from "react-hook-form";

const MyDate = ({
  control,
  name,
  label,
  helperText,
  rules,
  disabled,
  width,
  id,
}: any) => {
  const { field } = useController({
    name: name,
    control: control,
    rules: rules,
  });

  const { onChange, value, ref } = field;

  return (
    <div className={clsx("relative", width ? width : "")}>
      {label && <span>{label}</span>}

      <DatePicker
        id={id}
        customInput={<TextField inputRef={ref} />}
        name={name}
        selected={value}
        // format="MM/dd/yyyy"
        onChange={onChange}
        disabled={disabled}
        portalId="app"
        // className="!border-b-2 !max-w-full !text-[#282828] !text-[1rem] !py-[.2rem] !border-gray-200 !outline-none focus:!border-main"
        popperClassName="!z-[9999]"
      />

      {helperText && <span className="text-[.75rem]">{helperText}</span>}
    </div>
  );
};

export default MyDate;
