import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { StylesProvider } from "@material-ui/styles";
import { SnackbarProvider } from "notistack";
import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";

import { QueryClient, QueryClientProvider } from "react-query";
import { Router } from "react-router-dom";

import Root from "./components/Root";
import LoadingScript from "./components/System/LoadingScript";
import "./global.css";
import { history } from "./historyInstance";

const app = document.getElementById("app") as Element;

const queryClient = new QueryClient();

console.log("version: 1.0.4");

createRoot(app).render(
  <Router history={history}>
    <QueryClientProvider client={queryClient}>
      <StylesProvider>
        <SnackbarProvider
          maxSnack={3}
          classes={{
            containerRoot: "!pointer-events-auto",
          }}
        >
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Suspense fallback={<LoadingScript />}>
              <Root />
            </Suspense>
          </MuiPickersUtilsProvider>
        </SnackbarProvider>
      </StylesProvider>
    </QueryClientProvider>
  </Router>
);
