import { endOfDay, format, formatDistance, startOfDay } from "date-fns";

export const formatDateToMMMyyyy = (date?: string): string => {
  if (!date) {
    return "";
  }
  return format(new Date(date), "MMM yyyy");
};

export const formatDateToMMddyyyySeparatorSlash = (date?: string): string => {
  if (!date) {
    return "";
  }
  return format(new Date(date), "MM/dd/yyyy");
};

export const formatDateToMMddyyyyHHmm = (date?: string): string => {
  if (!date) {
    return "";
  }
  return format(new Date(date), "MM/dd/yyyy HH:mm");
};

export const formatDateToMmDdYyyyHhMmSs = (date: string): string => {
  if (!date) {
    return "";
  }

  return format(new Date(date), "MMM dd, yyyy HH:mm:ss");
};

export const formatDateToMmDdYyyy = (date?: string): string => {
  if (!date) {
    return "";
  }
  return format(new Date(date), "MMM dd, yyyy");
};

export const formatDateToAgo = (date: string): string => {
  if (!date) {
    return "";
  }

  const nowDateLocal = new Date();

  const nowDateInUTC = new Date(
    nowDateLocal.getUTCFullYear(),
    nowDateLocal.getUTCMonth(),
    nowDateLocal.getUTCDate(),
    nowDateLocal.getUTCHours(),
    nowDateLocal.getUTCMinutes(),
    nowDateLocal.getUTCSeconds(),
    nowDateLocal.getUTCMilliseconds()
  );

  return `${formatDistance(nowDateInUTC, new Date(new Date(date)), {
    includeSeconds: true,
  })}`;
};

export const formatDateForTask = (seconds?: number): string => {
  if (!seconds) {
    return "";
  }

  const d = Math.floor(seconds / (3600 * 24));
  const h = Math.floor((seconds % (3600 * 24)) / 3600);
  const m = Math.floor((seconds % 3600) / 60);

  const dDisplay = d > 0 ? d + (d === 1 ? " day, " : " days, ") : "";
  const hDisplay = h > 0 ? h + (h === 1 ? " hour, " : " hours, ") : "";
  const mDisplay = m > 0 ? m + (m === 1 ? " minute " : " minutes ") : "";

  return dDisplay + hDisplay + mDisplay;
};

export const formatDateSmart = (date: string): string => {
  if (!date) {
    return "";
  }

  const from = startOfDay(new Date());
  const to = endOfDay(new Date());

  const d = new Date(date);

  if (d > from && d < to) {
    return format(d, "HH:mm");
  }

  return format(d, "d MMM");
};

export const formatDateHours = (date: string): string => {
  if (!date) {
    return "";
  }

  const d = new Date(date);

  return format(d, "HH:mm");
};
