import { clsx } from "clsx";
import React from "react";

const Box = ({
  children,
  header,
  className,
  hideBottomLine,
}: {
  children?: React.ReactNode;
  header: string;
  className?: string;
  hideBottomLine?: boolean;
}) => {
  return (
    <>
      <div className={clsx("w-full mr-4 mb-3.5", className)}>
        <h3 className="mb-2">{header}</h3>

        {children}
      </div>

      {!hideBottomLine && <div className="bg-gray-200 w-full h-[1px]" />}

      <div className="h-3.5" />
    </>
  );
};

export default Box;
