import { view } from "@risingstack/react-easy-state";
import React from "react";
import { formatDateToMMddyyyySeparatorSlash } from "../../../../../../helpers";
import { checkDate } from "../../../../../../helpers/checkDate";
import ITask from "../../../../../../interfaces/task";
import Field from "../../../../../System/FormComponents/Field";
import ShowAndHide from "../../../../../System/FormComponents/ShowAndHide";
import Block from "../../../../Client/Overview/AccountsBlocks/Account/OrderCard/components/Block";
import {
  optionsEmploymentStatus,
  optionsEmploymentStatusOld,
} from "../../../../Client/Overview/AccountsBlocks/Account/OrderCard/tools/collections";
import { subTypeTypes } from "./../../../../Admin/Client/CreateAccount/tools/index";

export default view(({ task }: { task?: ITask }) => {
  let body;

  try {
    body = task?.body ? JSON.parse(task?.body) : {};
  } catch {
    body = task?.body;
  }

  console.log(body);

  const findEmploymentStatus = optionsEmploymentStatus.find(
    (item) => item.value === body?.employmentStatus
  );

  const findEmploymentStatusOld = optionsEmploymentStatusOld.find(
    (item) => item.value === body?.employmentStatusOld
  );

  const selectedSubType = subTypeTypes.find(
    (item) => item.value === body?.type
  );

  return (
    <>
      <div className="w-full mb-4">
        <Block label="Main card application details">
          <>
            <Field label="Sub type" value={selectedSubType?.label} />

            <Field label="The cardholders title" value={body.title} />

            <Field label="First Name" value={body?.firstName} />

            <Field label="Middle Name" value={body?.middleName} />

            <Field label="Surname" value={body?.surname} />

            <Field label="Name on Card" value={body?.nameOnCard} />

            <Field
              label="Country of Residence"
              value={body?.countryOfResidence?.name}
            />

            <Field label="Nationality" value={body?.nationality} />

            <Field label="Marital Status" value={body?.maritalStatus} />

            {checkDate(body?.dateOfBirth) && (
              <Field
                label="Date of Birth"
                value={formatDateToMMddyyyySeparatorSlash(body.dateOfBirth)}
              />
            )}

            <Field label="Place of Birth" value={body?.placeOfBirth} />

            <Field label="Passport/ID Card Number" value={body?.idNumber} />

            {checkDate(body?.expireDate) && (
              <Field
                label="Date of Birth"
                value={formatDateToMMddyyyySeparatorSlash(body.expireDate)}
              />
            )}

            <Field
              label="Country of Issuance"
              value={body?.countryOfIssuance?.name}
            />
          </>
        </Block>
      </div>

      <div className="w-full mb-4">
        <Block label="Contact details">
          <Field
            label="Residential Address* (EU/EEA)"
            value={body?.residentialAddress}
          />

          <Field label="Address Line 2" value={body?.addressLine2} />

          <Field label="Town/City" value={body?.townCity} />

          <Field label="Postcode" value={body?.postcode} />

          <Field label="Country" value={body?.country?.name} />

          <Field label="Contact Address" value={body?.contactAddress} />

          <Field
            label="Contact Address Line 2"
            value={body?.contactAddressLine2}
          />

          <Field label="Contact Town/City" value={body?.contactTownCity} />

          <Field label="Contact Postcode" value={body?.contactPostcode} />

          <Field label="Contact Country" value={body?.contactCountry?.name} />

          <Field label="Address Delivered" value={body?.addressDelivered} />

          <Field label="Telephone Number" value={body?.telephoneNumber} />

          <Field label="Mobile Number" value={body?.mobilePhone} />

          <Field label="Email Address" value={body?.email} />
        </Block>
      </div>

      <div className="w-full mb-4">
        <Block label="Employment details">
          <>
            <Field
              label="Employment Status"
              value={findEmploymentStatus?.label}
            />

            <ShowAndHide
              show={body?.employmentStatus === "employmentStatusOther"}
            >
              <Field
                custom={
                  <div>
                    <span className="mr-1">Employment Status Other:</span>

                    <span className="max-w-[150px] break-words">
                      {body?.employmentStatusOther}
                    </span>
                  </div>
                }
              />
            </ShowAndHide>

            <Field label="Position Held" value={body?.positionHeld} />

            <Field label="Company Name" value={body?.companyName} />

            <Field label="Nature of Business" value={body?.natureOfBusiness} />

            <Field label="Company Website" value={body?.companyWebsite} />

            <Field
              label="Country of Company Registration"
              value={body?.countryOfCompanyRegistration?.name}
            />

            <Field
              label="Country of Operations"
              value={body?.countryOfOperations?.name}
            />

            <Field
              label="Employment Status Old"
              value={findEmploymentStatusOld?.label}
            />

            <ShowAndHide
              show={body?.employmentStatusOld === "employmentStatusOther"}
            >
              <Field
                custom={
                  <div>
                    <span className="mr-1">
                      Employment Status Old Other (description):
                    </span>

                    <span className="max-w-[150px] break-words">
                      {body?.employmentStatusOldOther}
                    </span>
                  </div>
                }
              />
            </ShowAndHide>

            <Field label="Old Position Held" value={body?.positionHeldOld} />

            <Field label="Old Company Name" value={body?.companyNameOld} />

            <Field
              label="Old  Nature of Business "
              value={body?.natureOfBusinessOld}
            />

            <Field
              label="Old Company Website"
              value={body?.companyWebsiteOld}
            />

            <Field
              label="Old Country of Company Registration"
              value={body?.countryOfCompanyRegistrationOld?.name}
            />

            <Field
              label="Old Country of Operations"
              value={body?.countryOfOperationsOld?.name}
            />
          </>
        </Block>
      </div>

      <div className="w-full mb-4">
        <Block label="Financial Details">
          <Field
            label="Annual Income Amount"
            value={body?.annualIncomeAmount}
          />

          <Field label="Other Income" value={body?.otherIncome} />

          <Field
            label=""
            custom={
              <div>
                <span className="mr-1">Source of Wealth:</span>

                <span className="max-w-[150px] break-words">
                  {body?.sourceOfWealth}
                </span>
              </div>
            }
          />

          <Field
            label="Anticipated Monthly Spend up to 100,000"
            value={body?.anticipatedUpTo100000 ? "Yes" : "No"}
          />

          <Field
            label="Anticipated Monthly Spend up to 250,000"
            value={body?.anticipatedUpTo250000 ? "Yes" : "No"}
          />

          <Field
            label="Anticipated Monthly Spend Above 250,000"
            value={body?.anticipatedAbove250000 ? "Yes" : "No"}
          />

          <Field label="Bank Name" value={body?.bankName} />

          <Field label="Bank Country" value={body?.bankCountry?.name} />
        </Block>
      </div>

      <div className="w-full mb-4">
        <Block label="Documents">
          <Field label="Identification type" value={body.identificationType} />

          <Field label="ID Number" value={body.idNumber} />
        </Block>
      </div>
    </>
  );
});
