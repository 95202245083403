import { useMemo } from "react";
import { allCurrencies2 } from "../../../../../../helpers/formatToCurrencyString";

export const useAvailableCurrencies = (rows, filters) => {
  return useMemo(() => {
    const arr: any = [];

    const order = [
      "EUR",
      "USDT",
      "USD",
      "BTC",
      "GBP",
      "USDC",
      "AED",
      "ETH",
      "RUB",
      "CAD",
      "CHF",
      "HKD",
      "TRY",
      "JPY",
    ];

    const p1 = allCurrencies2.filter((c) => order.includes(c.value));
    const p2 = allCurrencies2.filter((c) => !order.includes(c.value));

    p1.sort((a, b) => {
      return order.indexOf(a.value) - order.indexOf(b.value);
    });

    const currencies = [...p1, ...p2].filter((c) => c.value !== "NEXM");

    currencies.forEach((c) => {
      const availableCurrency = (rows || [])?.some((r) => {
        const d = r?.currencies?.[c.value.toLowerCase()];

        if (d && filters.dataType === "Sum" && d?.total) {
          return true;
        }

        if (d && filters.dataType === "Sum ||" && d?.profit && d?.loss) {
          return true;
        }

        if (d && filters.dataType === "+/-" && (d?.profit || d?.loss)) {
          return true;
        }

        if (d && filters.dataType === "Count" && d?.count) {
          return true;
        }

        if (d && filters.dataType === "Average" && d?.average) {
          return true;
        }

        return false;
      });

      if (availableCurrency) {
        arr.push(c);
      }
    });

    return arr;
  }, [rows, filters.dataType]);
};
