import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React from "react";
import { useController } from "react-hook-form";

const getOptionLabel = (option) => {
  return option;
};

const MyAutocomplete = ({
  control,
  name,
  options,
  label,
  rules,
  disabled,
  getOptionSelected,
  filterSelectedOptions,
  id,
  customGetOptionLabel,
  multiple,
  eventHandler,
  disableSearch,
  disablePortal = true,
  onChangeCallback,
  className,
  style,
}: any) => {
  const {
    field: { ref, onChange: fieldOnChange, ...field },
  } = useController({
    name: name,
    control: control,
    rules: rules,
  });

  const onChangeAutocomplete = (e, value) => {
    e.stopPropagation();
    fieldOnChange && fieldOnChange(value);
    onChangeCallback && onChangeCallback(value);
    eventHandler && eventHandler();
  };

  return (
    <Autocomplete
      {...field}
      disablePortal={disablePortal}
      options={options}
      style={style}
      className={className}
      renderInput={(props: any) => {
        return (
          <TextField
            id={id}
            {...props}
            inputRef={ref}
            InputLabelProps={{
              shrink: true,
            }}
            autoComplete="off"
            inputProps={{
              ...props.inputProps,
              onChange: disableSearch ? (e) => e.preventDefault() : null,
              onKeyDown: disableSearch ? (e) => e.preventDefault() : null,
              id: id ? id : props.inputProps.id,
            }}
            fullWidth
            label={label}
          />
        );
      }}
      getOptionLabel={customGetOptionLabel || getOptionLabel}
      getOptionSelected={getOptionSelected}
      filterSelectedOptions={filterSelectedOptions}
      onChange={onChangeAutocomplete}
      disableClearable
      fullWidth
      onInputChange={(e, value, reason) => {
        if (e) {
          e.stopPropagation();
        }
        if (reason === "clear") {
          fieldOnChange && fieldOnChange([]);
          onChangeCallback && onChangeCallback(null);
        }
      }}
      disabled={disabled}
      multiple={multiple}
      onClose={(e) => e.stopPropagation()}
    />
  );
};

export default MyAutocomplete;
