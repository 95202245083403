import qs from "query-string";
import { history } from "../historyInstance";
import accounts from "../store/modules/accounts";
import auth from "../store/modules/auth";
import coins from "../store/modules/coins";
import exchange from "../store/modules/exchange";
import ui from "../store/modules/ui";

export const redirects = async (location: any) => {
  sessionStorage.removeItem("autoLogoutSuccess");

  ui.openAutoLogoutMessage = false;

  const params = qs.parseUrl(window.location.href).query;

  const checkParams = Object.keys(params).length > 0;

  const state = history.location.state;

  if (params?.redirectUrl) {
    const url = qs.stringifyUrl({
      url: params.redirectUrl as string,
      query: { ...params, redirectUrl: undefined },
    });

    history.push(url, state);

    return;
  }

  if (location.pathname.includes("/service/pay/sign-in") && checkParams) {
    const url = qs.stringifyUrl({
      url: `/service/pay`,
      query: params,
    });
    history.push(url, state);
  }

  const {
    onBoardStatus,
    clientType,
    isClerk,
    isOperator,
    isTreasurer,
    isController,
    isSupportAgent,
    isNewClient,
    isAnalyst,
    isAdmin,
    isRegulator,
    isSecureMessages,
    isNewsAdmin,
    isTeller,
    riskLevelId,
    isAppVersionUpdater,
    isCardAdmin,
  } = auth.profile || {};

  /* Staff */

  if (isNewsAdmin) {
    history.push("/app/news", state);
  } else if (isTeller) {
    history.push("/app/teller/verify-phone", state);
  } else if (isAdmin || isRegulator || isSupportAgent) {
    history.push("/app/admin/clients?page=1&userState=active", state);
  } else if (isCardAdmin) {
    history.push("/app/card-admin/global-cards", state);
  } else if (isSecureMessages) {
    history.push(
      "/app/admin/secure-messages/inbox?sortBy=ts&sortDirection=desc",
      state
    );
  } else if (isAppVersionUpdater) {
    history.push("/app/version-updater", state);
  } else if (isAnalyst) {
    history.push("/app/analyst/all-operations-2?dateType=Last 7 Days", state);
  } else if (isController) {
    history.push(
      "/app/clients?onBoardStatus=AwaitingOfController&showOnboardStat=true&sortBy=controllerReceivedTs&sortDirection=asc",
      state
    );
  } else if (isClerk) {
    history.push(
      "/app/clients?onBoardStatus=ReturnedToClerk,None,AwaitingOfClerk&userState=active&showOnboardStat=true&sortBy=registrationTs&sortDirection=desc",
      state
    );
  } else if (isOperator || isTreasurer) {
    history.push("/app/tasks/inbox/wire-transfers", state);
  } else {
    /* Client */
    if (coins?.goldData?.currency && onBoardStatus === "Completed") {
      await accounts.fetchAccounts();
      const goldAccount = accounts.items.find(
        (item) => item.currencyId === "MIGG"
      );

      if (goldAccount) {
        const state = JSON.stringify({
          direction: "to",
          value: { ...goldAccount },
        });

        exchange.buyAmount = coins?.goldData.amount;
        exchange.cursor = "buy";

        history.push("/app/exchange", JSON.parse(state));
      } else {
        history.push("/app/open-account");
      }
    } else if (clientType === "Disabled" || clientType === "NotServicingYet") {
      history.push("/application/message", state);
    } else if (onBoardStatus === "Completed") {
      history.push("/app/overview", state);
    } else if (onBoardStatus === "AwaitingOfController") {
      history.push("/application/wait", state);
    } else if (onBoardStatus === "Rejected") {
      history.push("/not-successful", state);
    } else if (
      onBoardStatus === "None" ||
      onBoardStatus === "AwaitingClerk" ||
      onBoardStatus === "ReturnedToClerk"
    ) {
      if (riskLevelId === "GoldOnly") {
        history.push("/short-application", state);
      } else {
        if (clientType === "VIP") {
          history.push("/application/wait", state);
        } else {
          if (isNewClient) {
            history.push("/application", state);
          } else {
            history.push("/app/wait-for-corporate", state);
          }
        }
      }
    }
  }
};
