const ENDPOINTS = {
  auth: {
    auth: `${import.meta.env.VITE_API}/v1/account/auth`,
    changePhone: `${
      import.meta.env.VITE_API
    }/v1/account/phone/change/verify-code/send`,
    sumSumSendVerifyCode: `${
      import.meta.env.VITE_API
    }/v1/account/phone/verify-code/send`,
    confirmDevice: `${import.meta.env.VITE_API}/v1/account/device/confirm`,
    confirmEmail: `${import.meta.env.VITE_ACCOUNT}/v1/account/email/confirm`,
    checkVerifyCodeEmail: `${
      import.meta.env.VITE_ACCOUNT
    }/v1/account/email/verify-code`,
    sendVerifyCodeEmail: `${
      import.meta.env.VITE_ACCOUNT
    }/v1/account/email/verify-code/send`,
    disableGoogleAuthentication: `${
      import.meta.env.VITE_API
    }/v1/account/2fa/authenticator/disable`,
    disableTwoFactor: `${import.meta.env.VITE_API}/v1/account/2fa/disable`,
    enableGoogleAuthentication: `${
      import.meta.env.VITE_API
    }/v1/account/2fa/authenticator/enable`,
    enableTwoFactor: `${import.meta.env.VITE_API}/v1/account/2fa/enable`,
    forgotPassword: `${import.meta.env.VITE_API}/v1/account/password/forgot`,
    generateGoogleAuthentication: `${
      import.meta.env.VITE_API
    }/v1/account/2fa/authenticator/generate`,
    getAvatar: `${
      import.meta.env.VITE_API
    }/v1/account/profile/avatar/{avatarFileId}/thumbnail`,
    sendTwoFactorCode: `${
      import.meta.env.VITE_API
    }/v1/account/2fa/code/{provider}/send`,
    login: `${import.meta.env.VITE_API}/v2/account/login`,
    passwordChange: `${import.meta.env.VITE_API}/v1/account/password/change`,
    registerCustomer: `${
      import.meta.env.VITE_API
    }/v1/account/register/customer`,
    resetPassword: `${import.meta.env.VITE_API}/v1/account/password/reset`,
    uploadAvatar: `${
      import.meta.env.VITE_API
    }/v1/account/profile/avatar/upload`,
    verifyPhone: `${
      import.meta.env.VITE_API
    }/v1/account/phone/change/verify-code`,
    sumSubVerifyCode: `${
      import.meta.env.VITE_API
    }/v1/account/phone/verify-code`,
    checkAccessToTheSection: `${
      import.meta.env.VITE_API
    }/v1/account/2fa/verify`,
    reSendConfirmationEmail: `${
      import.meta.env.VITE_ACCOUNT
    }/v2/account/email/confirmation/send`,
    merchantWebhook: `${import.meta.env.VITE_API}/v1/profile/merchant-webhook`,
    acceptTerms: `${import.meta.env.VITE_API}/v1/account/terms-of-use/accept`,
    signUp: `${import.meta.env.VITE_API}/v2/account/register`,
    getApp: `${import.meta.env.VITE_API}/v2/account/get-app`,
    onBoardType: `${import.meta.env.VITE_API}/v2/onboard/remember-my-type`,
    application: `${import.meta.env.VITE_API}/v2/onboard/application`,
    completeApplication: `${import.meta.env.VITE_API}/v2/onboard/completed`,
    fetchIplocator: `${import.meta.env.VITE_API}/v1/iplocator`,
    validatePhone: `${import.meta.env.VITE_API}/v1/check-phone`,
    validateBtcWallet: `${import.meta.env.VITE_API}/v1/validate-btc/{btc}`,
    authByUserId: `${import.meta.env.VITE_API}/v1/admin/account/{userId}/login`,
    fetchApplicationAddress: `${import.meta.env.VITE_API}/v1/onboard/address`,
    changeApplicationAddress: `${import.meta.env.VITE_API}/v1/onboard/address`,
  },
  statements: {
    item: `${import.meta.env.VITE_API}/v2/accounts/{accountId}/statements/pdf`,
    items: `${import.meta.env.VITE_API}/v2/accounts/{accountId}/statements`,
  },
  common: {
    items: `${import.meta.env.VITE_API}/v1/common`,
    findCountry: `${import.meta.env.VITE_API}/v1/common/find_country`,
    countries: `${import.meta.env.VITE_API}/v1/common/countries`,
  },
  customers: {
    items: `${import.meta.env.VITE_API}/v1/customers`,
    itemsUser: `${import.meta.env.VITE_API}/v1/admin/customers/{userId}`,
    fetchUserPromoCodes: `${
      import.meta.env.VITE_API
    }/v1/admin/customers/{userId}/promocodes`,
    fetchCustomer: `${import.meta.env.VITE_API}/v1/customers/{userId}`,
    fetchUserBranch: `${
      import.meta.env.VITE_API
    }/v1/admin/customers/{userId}/my-parent`,
    addUserInBranch: `${import.meta.env.VITE_API}/v1/admin/customers/{userId}`,
  },
  notifications: {
    approveAddress: `${
      import.meta.env.VITE_API
    }/v1/kyc/{userId}/address/approve`,
    byUser: `${import.meta.env.VITE_API}/v1/users/{userId}/notifications`,
    deleteNotification: `${
      import.meta.env.VITE_API
    }/v1/notifications/{notificationId}`,
    fetchNotificationForAdmin: `${
      import.meta.env.VITE_API
    }/v1/admin/notifications/{notificationId}`,

    fetchAttachment: `${
      import.meta.env.VITE_API
    }/v1/notifications/{notificationId}/files/{attachmentId}`,
    items: `${import.meta.env.VITE_API}/v1/notifications`,
    printNotificationUser: `${
      import.meta.env.VITE_API
    }/v3/admin/users/{userId}/notifications/{notificationId}/pdf`,
    printNotification: `${
      import.meta.env.VITE_API
    }/v3/notifications/{notificationId}/pdf`,
    reject: `${
      import.meta.env.VITE_API
    }/v1/notifications/{notificationId}/reject/answer`,

    rejectAddress: `${import.meta.env.VITE_API}/v1/kyc/{userId}/address/reject`,
    rejectOperation: `${import.meta.env.VITE_API}/v1/admin/operations/reject`,
    sendEmailToUser: `${
      import.meta.env.VITE_API
    }/v1/notifications/{notificationId}/messages/send`,
    createNotification: `${
      import.meta.env.VITE_API
    }/v1/notifications/{notificationId}/{answer}/{userIdTo}`,
    getImageForAdmin: `${
      import.meta.env.VITE_API
    }/v1/admin/notifications/{notificationId}/files/{attachmentId}`,
    pushNotification: `${
      import.meta.env.VITE_API
    }/v1/development/push/send/user/{userId}/data`,
    upload: `${import.meta.env.VITE_API}/v1/notifications/upload`,
    sendPush: `${
      import.meta.env.VITE_API
    }/v1/development/push/send/user/{userId}`,
    deleteWireSendNotification: `${
      import.meta.env.VITE_API
    }/v1/operations/{notificationId}`,
    approveSendWireTreasure: `${
      import.meta.env.VITE_API
    }/v1/admin/operations/execute`,
  },
  gold: {
    deliveryPreview: `${
      import.meta.env.VITE_API
    }/v1/operations/gold-coin/buy-and-delivery-preview`,
    deliveryConfirm: `${
      import.meta.env.VITE_API
    }/v1/operations/gold-coin/buy-and-delivery`,

    custodyPreview: `${
      import.meta.env.VITE_API
    }/v1/operations/gold-coin/buy-and-custody-preview`,
    custodyConfirm: `${
      import.meta.env.VITE_API
    }/v1/operations/gold-coin/buy-and-custody`,

    fromCustodyToDeliveryPreview: `${
      import.meta.env.VITE_API
    }/v1/operations/gold-coin/from-custody-to-delivery-preview`,
    fromCustodyToDeliveryConfirm: `${
      import.meta.env.VITE_API
    }/v1/operations/gold-coin/from-custody-to-delivery`,

    operatorFormPreview: `${
      import.meta.env.VITE_API
    }/v1/admin/operations/preview-existing`,
  },
  teller: {
    fetchClients: `${import.meta.env.VITE_DMCC_API}/v1/dmcc/users`,
    fetchCryptoRecipients: `${
      import.meta.env.VITE_DMCC_API
    }/v1/dmcc/users/{userId}/crypto-recipients`,
    sendVerifyCode: `${
      import.meta.env.VITE_API
    }/v1/users/phone/verify-code/send`,
    checkVerifyCode: `${import.meta.env.VITE_API}/v1/users/phone/verify-code`,
  },
  accounts: {
    deposit: `${import.meta.env.VITE_API}/v1/operations/deposit`,
    fetchTransferFrom: `${
      import.meta.env.VITE_API
    }/v1/accounts/can-transfer-from`,
    getAccountId: `${import.meta.env.VITE_API}/v2/operations/get-account-id`,
    items: `${import.meta.env.VITE_API}/v1/accounts`,
    item: `${import.meta.env.VITE_API}/v1/accounts/{accountId}`,
    loadByCard: `${import.meta.env.VITE_API}/v1/operations/top-up`,
    previewLoadByCard: `${
      import.meta.env.VITE_API
    }/v1/operations/top-up-preview`,
    previewTransfer: `${
      import.meta.env.VITE_API
    }/v1/operations/transfer-preview`,

    transfer: `${import.meta.env.VITE_API}/v1/operations/transfer`,
    newTransfer: `${import.meta.env.VITE_API}/v2/operations/transfer`,

    previewWireSend: `${
      import.meta.env.VITE_API
    }/v1/operations/wire-send-preview`,
    fetchMaxCryptoWithdrawAmount: `${
      import.meta.env.VITE_API
    }/v1/operations/crypto-withdraw-max/{accountId}`,
    fetchMaxWireSend: `${
      import.meta.env.VITE_API
    }/v1/operations/withdraw-max/{accountId}`,
    fetchMaxCryptoWithdrawAmountForAdmin: `${
      import.meta.env.VITE_API
    }/v2/admin/operations/crypto-withdraw-max/{accountId}`,
    fetchMaxExchangeAmount: `${
      import.meta.env.VITE_API
    }/v2/operations/exchange-max/{accountId}`,
    wireSend: `${import.meta.env.VITE_API}/v1/operations/wire-send`,
    newWireSend: `${import.meta.env.VITE_API}/v2/operations/wire-send`,

    previewDeposit: `${import.meta.env.VITE_API}/v1/operations/deposit-preview`,
    request: `${import.meta.env.VITE_API}/v1/operations/request`,

    previewCryptoWire: `${
      import.meta.env.VITE_API
    }/v1/operations/crypto-withdraw-preview`,
    cryptoWireSend: `${import.meta.env.VITE_API}/v1/operations/crypto-withdraw`,
    closeSaving: `${import.meta.env.VITE_API}/v1/accounts/close-saving-prepare`,

    payMigom: `${import.meta.env.VITE_API}/v1/operations/migom-pay`,

    depositOffer: `${import.meta.env.VITE_API}/v1/accounts/deposit`,
    cryptoLoan: `${import.meta.env.VITE_API}/v1/accounts/crypto-loan`,

    getFormula: `${
      import.meta.env.VITE_API
    }/v1/accounts/{accountId}/formulas/{titleId}`,

    fetchLastOperations: `${
      import.meta.env.VITE_API
    }/v1/accounts/last-operations`,
    validIban: `${import.meta.env.VITE_API}/v1/valid-iban`,
    validSwift: `${
      import.meta.env.VITE_API
    }/v2/banks/swift-validate/{swiftCode}`,
    fetchBankAddressBySwift: `${import.meta.env.VITE_API}/v2/banks/{swiftCode}`,
    fetchBankAddressByIBAN: `${import.meta.env.VITE_API}/v2/iban/{iban}`,
    fetchDetailSavingAccount: `${
      import.meta.env.VITE_API
    }/v1/accounts/saving-account-details`,
    fetchRate: `${import.meta.env.VITE_API}/v1/admin/rate`,

    fetchUserDataByPhone: `${import.meta.env.VITE_API}/v1/user/by-phone`,
  },
  exchange: {
    exchangePreview: `${
      import.meta.env.VITE_API
    }/v1/operations/exchange-preview`,
    exchange: `${import.meta.env.VITE_API}/v1/operations/exchange`,
    exchangePreviewByAdmin: `${
      import.meta.env.VITE_API
    }/v1/admin/operations/exchange-preview`,
    exchangeByAdmin: `${import.meta.env.VITE_API}/v1/admin/operations/confirm`,
    exchangePreviewByAdminBalance: `${
      import.meta.env.VITE_API
    }/v1/admin/operations/gc-exchange-preview`,
    exchangeByAdminBalance: `${
      import.meta.env.VITE_API
    }/v1/admin/operations/gc-exchange`,

    exchangeAutoPossible: `${
      import.meta.env.VITE_API
    }/v1/operations/auto-exchange-possible`,
    exchangeRate: `${import.meta.env.VITE_API}/v1/operations/exchange-rate`,
  },
  agents: {
    fetchAgents: `${import.meta.env.VITE_API}/v1/agents`,
    fetchAgentsUser: `${import.meta.env.VITE_API}/v1/agents/{userId}/clients`,
    setAgentForUser: `${import.meta.env.VITE_API}/v1/agents/set-user-agent`,
  },
  admin: {
    fetchPnlByBrunch: `${import.meta.env.VITE_API}/v3/reports/pnl-by-branch`,
    accounts: `${import.meta.env.VITE_API}/v1/admin/accounts`,
    approveStatement: `${
      import.meta.env.VITE_API
    }/v2/admin/accounts/{accountId}/statements/approve`,
    closeAccount: `${
      import.meta.env.VITE_API
    }/v1/admin/accounts/{accountId}/close`,
    confirm: `${import.meta.env.VITE_API}/v1/admin/operations/confirm`,
    deleteStatement: `${
      import.meta.env.VITE_API
    }/v2/admin/accounts/{accountId}/statements/delete`,

    fetchStatements: `${
      import.meta.env.VITE_API
    }/v2/admin/accounts/{accountId}/statements`,
    fetchUserAccount: `${
      import.meta.env.VITE_API
    }/v1/admin/accounts/{accountId}`,
    fetchUserAccountTransactions: `${
      import.meta.env.VITE_API
    }/v1/admin/accounts/{accountId}/operations`,
    fetchUserAccounts: `${import.meta.env.VITE_API}/v1/admin/user-accounts`,
    generateStatement: `${
      import.meta.env.VITE_API
    }/v2/admin/accounts/{accountId}/statements/generate`,
    openStatement: `${
      import.meta.env.VITE_API
    }/v2/admin/accounts/{accountId}/statements/pdf`,
    operation: `${import.meta.env.VITE_API}/v1/admin/operations/{operationId}`,
    patchAccount: `${import.meta.env.VITE_API}/v1/admin/accounts/{accountId}`,
    patchOperation: `${
      import.meta.env.VITE_API
    }/v1/admin/operations/{operationId}`,
    preview: `${import.meta.env.VITE_API}/v1/admin/operations/preview`,
    setCardStatus: `${
      import.meta.env.VITE_API
    }/v1/users/{userId}/profile/set-card-status`,
    sumBalances: `${import.meta.env.VITE_API}/v2/users/accounts/sums`,
    suspendAccount: `${
      import.meta.env.VITE_API
    }/v1/admin/accounts/{accountId}/suspend`,
    openAccount: `${
      import.meta.env.VITE_API
    }/v1/admin/accounts/{accountId}/open`,
    plaidAccountsUserByAdmin: `${
      import.meta.env.VITE_API
    }/v1/admin/users/{userId}/plaid/accounts`,
    fetchDebitCardImageUser: `${
      import.meta.env.VITE_API
    }/v1/admin/accounts/{accountId}/image`,
    uploadUserAvatar: `${
      import.meta.env.VITE_API
    }/v1/admin/users/{userId}/avatar/upload`,
    deleteUserAvatar: `${
      import.meta.env.VITE_API
    }/v1/admin/users/{userId}/avatar`,
    fetchAllOperations: `${
      import.meta.env.VITE_API
    }/v1/admin/accounts/operations`,
    fetchFutureInterestPayment: `${
      import.meta.env.VITE_INTEREST
    }/v1/admin/future-interest`,
    previewDeleteOperation: `${
      import.meta.env.VITE_API
    }/v1/admin/operations/preview`,
    confirmDeleteOperation: `${
      import.meta.env.VITE_API
    }/v1/admin/operations/confirm`,
    fetchPendingFeeReportsGroupByUser: `${
      import.meta.env.VITE_API
    }/v1/reports/pending-user-fee`,
    fetchUsersBalances: `${import.meta.env.VITE_API}/v2/reports/balances`,
    fetchDOR: `${
      import.meta.env.VITE_API
    }/v1/reports/distribution-of-recidents`,
    fetchDOR2: `${
      import.meta.env.VITE_API
    }/v2/reports/distribution-of-residents`,
    // fetchDORTotals: `${import.meta.env.VITE_API}/v2/reports/distribution-of-total`,
    fetchSD: `${import.meta.env.VITE_API}/v1/reports/size-distribution`,
    fetchTH: `${import.meta.env.VITE_API}/v1/reports/top-holders`,
    fetchCountApprovedTasks: `${
      import.meta.env.VITE_API
    }/v1/reports/approved-tasks-count`,
    fetchCountUsers: `${import.meta.env.VITE_API}/v1/admin/users/count`,
    countOperations: `${import.meta.env.VITE_API}/v1/admin/operations/count`,

    fetchTransPayments: `${import.meta.env.VITE_API}/v1/admin/trans-payments`,
    fetchSepaPayments: `${import.meta.env.VITE_API}/v1/admin/sepa/payments`,
    fetchTransOperations: `${
      import.meta.env.VITE_TRANSACTIVE
    }/v2/admin/trans/operations`,
    fetchSepaOperations: `${
      import.meta.env.VITE_TRANSACTIVE
    }/v2/admin/sepa/operations`,
    sendConfirmEmail: `${
      import.meta.env.VITE_API
    }/v1/users/{userId}/sendEmailConfirm`,
    sendWelcomeEmail: `${import.meta.env.VITE_API}/v1/users/{userId}/welcome`,
    sendEmailToUserAboutNotActive: `${
      import.meta.env.VITE_API
    }/v1/users/{userId}/send-email-about-suspending-not-active-accounts`,
    autoCreateIban: `${
      import.meta.env.VITE_API
    }/v1/admin/accounts/{accountId}/create-iban`,
    closeIban: `${
      import.meta.env.VITE_API
    }/v1/admin/accounts/{accountId}/create-internal`,
    createDebitCard: `${import.meta.env.VITE_API}/v1/admin/accounts/debit-card`,
    fetchOpenSignedAccountForm: `${
      import.meta.env.VITE_API
    }/v2/admin/files-by-sign-id/{signedContractId}`,
    patchApplicationFlags: `${
      import.meta.env.VITE_API
    }/v2/admin/onboard/application/{userId}`,
    fetchCompaniesUbo: `${
      import.meta.env.VITE_API
    }/v1/admin/users/find-companies-ubo`,

    fetchCryptoTransactions: `${
      import.meta.env.VITE_CRYPTO
    }/v2/admin/crypto/transactions`,
    fetchCryptoOperations: `${
      import.meta.env.VITE_CRYPTO
    }/v2/admin/crypto/operations`,

    fetchOperationsCards: `${
      import.meta.env.VITE_API
    }/v1/external-operations/cards`,

    activateVirtualCard: `${
      import.meta.env.VITE_PREPAID
    }/v1/admin/cards/{accountId}/activate`,

    ruleAccount: `${import.meta.env.VITE_API}/v1/admin/fee/rule-account`,
    fetchRoles: `${import.meta.env.VITE_API}/v1/roles`,

    canSendSepa: `${import.meta.env.VITE_API}/v1/can-send-sepa`,
    canSendSepaToRecipient: `${
      import.meta.env.VITE_API
    }/v1/can-send-sepa-to-recipient`,
    registerNewClient: `${
      import.meta.env.VITE_API
    }/v1/account/register/new-user`,

    fetchPnlGraphics: `${import.meta.env.VITE_API}/v1/reports/pnl-graphic`,
    sumSubInit: `${
      import.meta.env.VITE_API
    }/v2/admin/identity/initiate/{userId}`,
    remindTerms: `${
      import.meta.env.VITE_API
    }/v2/admin/onboard/application/{userId}/send-message-complete-reg-vip`,
    transferToIndividual: `${
      import.meta.env.VITE_API
    }/v2/admin/onboard/application/{userId}/set-no-vip`,

    onboard: `${import.meta.env.VITE_API}/v1/statistic/onboard2`,

    fetchTransactBalances: `${
      import.meta.env.VITE_API
    }/v1/admin/balance/transact`,

    fetchSepaBalances: `${import.meta.env.VITE_API}/v1/admin/sepa/balance`,

    fetchDayAdminIncOperations: `${
      import.meta.env.VITE_API
    }/v1/admin/balance/inc-operations`,
    fetchDayAdminTransOperations: `${
      import.meta.env.VITE_API
    }/v1/admin/balance/trans-operations`,

    fetchDayAdminDays: `${import.meta.env.VITE_API}/v1/admin/days`,
    fetchDayAdminDaysDay: `${import.meta.env.VITE_API}/v1/admin/days/{day}`,

    wireRecipientsBlocked: `${
      import.meta.env.VITE_API
    }/v1/admin/wire-recipients-blocked`,
    wireRecipientBlock: `${
      import.meta.env.VITE_API
    }/v1/admin/wire-recipients-blocked/{keyword}`,
    fetchCountryByIso: `${
      import.meta.env.VITE_API
    }/v2/admin/onboard/contry-by-iso/{countryIso}`,
    fetchStatusIncService: `${
      import.meta.env.VITE_INC
    }/v1/admin/launcher/inc/status`,
    startIncService: `${import.meta.env.VITE_INC}/v1/admin/launcher/inc/start`,
    fetchStatusLauncher: `${import.meta.env.VITE_COINS}/v1/launcher/status`,
    startLaunch: `${import.meta.env.VITE_COINS}/v1/launcher/start`,

    fetchTasksHistory: `${
      import.meta.env.VITE_API
    }/v1/admin/tasks/{taskId}/history`,
    fetchStates: `${import.meta.env.VITE_API}/v1/admin/tasks/states`,

    fetchVaults: `${import.meta.env.VITE_API}/v1/admin/account-vaults`,

    fetchConnectedUsers: `${
      import.meta.env.VITE_SEEKER
    }/v1/admin/users/{userId}/related`,

    fetchCryptoTransactionDetail: `${
      import.meta.env.VITE_CRYPTO
    }/v2/admin/crypto/transactions/{id}`,

    fetchLoginHistory: `${import.meta.env.VITE_API}/v1/admin/users/history`,
    fetchAllDevices: `${import.meta.env.VITE_API}/v1/admin/users/devices`,

    fetchBrokenUsers: `${
      import.meta.env.VITE_API
    }/v2/admin/onboard/users-with-errors`,
    fetchErrorsUser: `${
      import.meta.env.VITE_API
    }/v2/admin/onboard/users/{userId}/errors`,

    previewExisting: `${
      import.meta.env.VITE_API
    }/v1/admin/operations/preview-existing`,

    fetchEmployees: `${import.meta.env.VITE_API}/v2/admin/employees`,

    inactiveUser: `${
      import.meta.env.VITE_API
    }/v2/admin/users/{userId}/inactive`,
    suspendUser: `${import.meta.env.VITE_API}/v2/admin/users/{userId}/suspend`,
    banUser: `${import.meta.env.VITE_API}/v2/admin/users/{userId}/ban`,
    activeUser: `${import.meta.env.VITE_API}/v2/admin/users/{userId}/active`,
    previewPassToRobot: `${
      import.meta.env.VITE_API
    }/v1/admin/operations/mass-send-to-robot-preview`,
    confirmPassToRobot: `${
      import.meta.env.VITE_API
    }/v1/admin/operations/mass-send-to-robot-confirm`,
    previewMassApprove: `${
      import.meta.env.VITE_API
    }/v1/admin/operations/mass-preview`,
    confirmMassApprove: `${
      import.meta.env.VITE_API
    }/v1/admin/operations/mass-confirm`,

    fetchVolumeForPeriod: `${
      import.meta.env.VITE_API
    }/v1/reports/turnover-for-period`,

    closeSaving: `${
      import.meta.env.VITE_API
    }/v1/admin/accounts/close-saving-account`,
    fetchDetailSavingAccountClient: `${
      import.meta.env.VITE_API
    }/v1/admin/accounts/saving-account-details`,

    massSetUserState: `${
      import.meta.env.VITE_API
    }/v1/admin/set-users-status/{userState}`,

    getFormulaForAccount: `${
      import.meta.env.VITE_API
    }/v1/admin/accounts/{accountId}/formulas/{titleId}`,

    fetchNumberOfApprovedTasks: `${
      import.meta.env.VITE_API
    }/v1/reports/approved-tasks-count`,

    fetchCurrentStatementUserAccount: `${
      import.meta.env.VITE_PDF
    }/v1/admin/accounts/{accountId}/statements-interim/pdf`,

    fetchClientEvents: `${import.meta.env.VITE_API}/v1/statistic/events`,
    fetchOperationsCountPerDay: `${
      import.meta.env.VITE_API
    }/v1/reports/operations-count-per-day`,
    fetchStatisticEvents: `${import.meta.env.VITE_API}/v1/statistic/events`,
  },
  transactions: {
    fetchMyAccountTransaction: `${
      import.meta.env.VITE_API
    }/v1/operations/{operationId}`,
    fetchMyAccountTransactions: `${
      import.meta.env.VITE_API
    }/v1/accounts/{accountId}/operations`,
    fetchMyAccountTransactionsCsv: `${
      import.meta.env.VITE_API
    }/v1/accounts/{accountId}/operations-csv`,
    fetchUserAccountTransactionsCsv: `${
      import.meta.env.VITE_API
    }/v1/admin/accounts/{accountId}/operations-csv`,
    itemsByUserId: `${
      import.meta.env.VITE_API
    }/v1/wallet/history/byUser/{userId}`,
    deleteOperation: `${import.meta.env.VITE_API}/v1/operations/{operationId}`,
    generateStatement: `${
      import.meta.env.VITE_API
    }/v2/admin/accounts/{accountId}/statements/generate`,
  },
  recipients: {
    items: `${import.meta.env.VITE_API}/v5/wire-recipients`,
    fetchWireRecipientsShort: `${
      import.meta.env.VITE_API
    }/v5/wire-short-recipients`,
    createItem: `${import.meta.env.VITE_API}/v5/wire-recipients`,
    item: `${import.meta.env.VITE_API}/v5/wire-recipients/{wire-recipient-id}`,
    editWireRecipient: `${
      import.meta.env.VITE_API
    }/v5/wire-recipients/{wire-recipient-id}`,
    fetchWireRecipient: `${
      import.meta.env.VITE_API
    }/v5/wire-recipients/{wire-recipient-id}`,
    fetchAllWireRecipients: `${
      import.meta.env.VITE_API
    }/v2/admin/wire-recipients`,
    fetchWireRecipientDetail: `${
      import.meta.env.VITE_API
    }/v2/admin/users/{userId}/wire-recipients/{wireRecipientId}`,
    editWireRecipientAdmin: `${
      import.meta.env.VITE_API
    }/v2/admin/users/{userId}/wire-recipients/{wireRecipientId}`,
    deleteWireRecipient: `${
      import.meta.env.VITE_API
    }/v2/admin/users/{userId}/wire-recipients/{wireRecipientId}`,
    createWireRecipientAdmin: `${
      import.meta.env.VITE_API
    }/v2/admin/users/{userId}/wire-recipients`,
    fetchWireRecipientsUserAdmin: `${
      import.meta.env.VITE_API
    }/v2/admin/users/{userId}/wire-recipients`,
  },
  verify: {
    getSumsubAccessToken: `${
      import.meta.env.VITE_API
    }/v2/onboard/anonymous-puritoken`,
    initIdentity: `${import.meta.env.VITE_API}/v1/kyc/identity/initiate`,
    sendAddress: `${import.meta.env.VITE_API}/v1/user/request-address-change`,
    initIdentitySumSub: `${import.meta.env.VITE_API}/v2/kyc/identity/initiate`,
  },
  contacts: {
    items: `${import.meta.env.VITE_API}/v2/contacts`,
    item: `${import.meta.env.VITE_API}/v2/contacts/{contactId}`,
    contactsUser: `${
      import.meta.env.VITE_API
    }/v1/admin/users/{userId}/contacts`,
    contactUser: `${
      import.meta.env.VITE_API
    }/v1/admin/users/{userId}/contacts/{contactId}`,
    createUserContact: `${
      import.meta.env.VITE_API
    }/v2/admin/users/{userId}/contacts`,
  },
  fees: {
    feeTree: `${import.meta.env.VITE_API}/v2/fee/tree`,
    formulas: `${
      import.meta.env.VITE_API
    }/v2/fee/structures/{structureId}/formulas`,
    patchFormula: `${
      import.meta.env.VITE_API
    }/v2/fee/structures/{structureId}/formulas/{titleId}`,
    fetchStructuresWithPagination: `${
      import.meta.env.VITE_API
    }/v3/fee/structures`,
    removeStructure: `${
      import.meta.env.VITE_API
    }/v2/fee/structure/{structureId}`,
    addStructure: `${import.meta.env.VITE_API}/v2/fee/structure`,
    addFormula: `${
      import.meta.env.VITE_API
    }/v2/fee/structures/{structureId}/formulas/{titleId}`,
    editStructure: `${import.meta.env.VITE_API}/v2/fee/structure/{structureId}`,
    deleteFormula: `${
      import.meta.env.VITE_API
    }/v2/fee/structures/{structureId}/formulas/{titleId}`,
    fetchTitles: `${import.meta.env.VITE_API}/v2/fee/titles`,
    fetchPromoCodes: `${import.meta.env.VITE_API}/v3/fee/promocodes`,
    validatePromoCode: `${import.meta.env.VITE_API}/v2/fee/promocodes/check`,
    addClientInStructure: `${
      import.meta.env.VITE_API
    }/v2/fee/structures/{structureId}/client`,
    removeClientOutStructure: `${
      import.meta.env.VITE_API
    }/v2/fee/structures/{structureId}/client`,
    fetchStructure: `${
      import.meta.env.VITE_API
    }/v2/fee/structures/{structureId}`,
    fetchStructureChildren: `${
      import.meta.env.VITE_API
    }/v2/fee/structures/{structureId}/children`,
    fetchAllFormulas: `${import.meta.env.VITE_API}/v2/fee/formulas`,
    fetchResolveStructures: `${
      import.meta.env.VITE_API
    }/v2/fee/structures/{structureId}/resolve-formulas`,
    getFeeForSmartForm: `${import.meta.env.VITE_API}/v1/admin/fee/get-our-cost`,
  },
  feeRules: {
    fetchFeeRules: `${import.meta.env.VITE_API}/v1/admin/fee/rules`,
    fetchFeeRule: `${import.meta.env.VITE_API}/v1/admin/fee/rules/{ruleId}`,
    emulate: `${import.meta.env.VITE_API}/v1/admin/fee/rules/{ruleId}/emulate`,
  },
  users: {
    changePassword: `${
      import.meta.env.VITE_API
    }/v1/users/{userId}/password/change`,
    confirmEmail: `${import.meta.env.VITE_API}/v1/users/{userId}/confirmEmail`,
    item: `${import.meta.env.VITE_API}/v1/users/{userId}`,
    items: `${import.meta.env.VITE_API}/v1/users`,
    registerStaff: `${import.meta.env.VITE_API}/v1/users/register-staff`,
    deleteUser: `${import.meta.env.VITE_API}/v1/users/{userId}`,
    fetchRolesUser: `${import.meta.env.VITE_API}/v1/users/{userId}/roles`,
    remindReg: `${import.meta.env.VITE_API}/v1/users/{userId}/remind-reg`,
    fetchMessagesRemind: `${
      import.meta.env.VITE_API
    }/v1/users/get-messages-remind`,
  },
  blocks: {
    getDate: `${import.meta.env.VITE_BLOCKS}/v1/term-of-use`,
    fetchSections: `${import.meta.env.VITE_BLOCKS}/v2/sections`,
    fetchBlocksBySection: `${
      import.meta.env.VITE_BLOCKS
    }/v2/blocks/{sectionId}`,
    block: `${import.meta.env.VITE_BLOCKS}/v2/block/{id}`,
    createBlock: `${import.meta.env.VITE_BLOCKS}/v2/block`,
  },
  corporate: {
    approveClient: `${
      import.meta.env.VITE_ONBOARD
    }/v1/onboard/clients/{userId}/approve`,
    rejectClient: `${
      import.meta.env.VITE_ONBOARD
    }/v1/onboard/clients/{userId}/reject`,
    fetchClientDetail: `${
      import.meta.env.VITE_ONBOARD
    }/v1/onboard/clients/{userId}/detail`,

    approveDocument: `${
      import.meta.env.VITE_ONBOARD
    }/v1/onboard/clients/{userId}/documents/{documentType}/approve`,
    rejectDocument: `${
      import.meta.env.VITE_ONBOARD
    }/v1/onboard/clients/{userId}/documents/{documentType}/reject`,
    fetchQuest: `${
      import.meta.env.VITE_API
    }/v2/admin/onboard/questionnaire/{fileId}`,
    downloadDocument: `${
      import.meta.env.VITE_ONBOARD
    }/v1/onboard/documents/{documentId}/download`,
    uploadDocument: `${
      import.meta.env.VITE_ONBOARD
    }/v1/onboard/documents/{documentType}/upload`,

    onboardDetail: `${import.meta.env.VITE_ONBOARD}/v1/onboard/detail`,

    uploadUserDocument: `${
      import.meta.env.VITE_ONBOARD
    }/v1/onboard/clients/{userId}/documents/{documentType}/upload`,
    downloadUserDocument: `${
      import.meta.env.VITE_ONBOARD
    }/v1/onboard/clients/{userId}/documents/{documentId}/download`,

    fetchNewOnboardUserFiles: `${
      import.meta.env.VITE_API
    }/v2/admin/onboard/files/{userId}`,

    uploadCorporateDocument: `${
      import.meta.env.VITE_API
    }/v2/admin/onboard/files/{userId}`,
    replaceCorporateDocument: `${
      import.meta.env.VITE_API
    }/v2/admin/onboard/files-change/{userId}`,

    approveClientByController: `${
      import.meta.env.VITE_API
    }/v2/admin/onboard/application/{userId}/complete`,
    rejectClientByController: `${
      import.meta.env.VITE_API
    }/v2/admin/onboard/application/{userId}/reject`,

    checkUserErrors: `${
      import.meta.env.VITE_API
    }/v2/admin/onboard/errors/{userId}`,

    passToClerk: `${
      import.meta.env.VITE_API
    }/v2/admin/onboard/application/{userId}/pass-to-clerk`,
    passToController: `${
      import.meta.env.VITE_API
    }/v2/admin/onboard/application/{userId}/pass-to-controller`,
    createAccount: `${
      import.meta.env.VITE_API
    }/v1/admin/onboard/application/{userId}/create-account`,
  },
  chartData: {
    line: `${import.meta.env.VITE_API}/v1/charts/stock`,
  },
  plaid: {
    link: `${import.meta.env.VITE_API}/v1/plaid/accounts/link`,
    fetchAccountsForLink: `${import.meta.env.VITE_API}/v1/plaid/bank-accounts`,
    fetchAccounts: `${import.meta.env.VITE_API}/v1/plaid/accounts`,
    deleteAccount: `${import.meta.env.VITE_API}/v1/plaid/accounts/{accountId}`,
    deposit: `${import.meta.env.VITE_API}/v1/plaid/deposit`,
    depositPreview: `${import.meta.env.VITE_API}/v1/plaid/deposit-preview`,
  },
  invoices: {
    saveInvoice: `${import.meta.env.VITE_API}/v1/invoices/{invoiceId}`,
    createInvoice: `${import.meta.env.VITE_API}/v1/invoices`,
    createItem: `${import.meta.env.VITE_API}/v1/saleitems`,
    createTax: `${import.meta.env.VITE_API}/v1/taxes`,
    deleteItem: `${import.meta.env.VITE_API}/v1/saleitems/{itemId}`,
    patchItem: `${import.meta.env.VITE_API}/v1/saleitems/{itemId}`,
    deleteTax: `${import.meta.env.VITE_API}/v1/taxes/{taxId}`,
    fetchInvoice: `${import.meta.env.VITE_API}/v1/invoices/{invoiceId}`,
    fetchInvoices: `${import.meta.env.VITE_API}/v1/invoices`,
    fetchItems: `${import.meta.env.VITE_API}/v1/saleitems`,
    fetchTaxes: `${import.meta.env.VITE_API}/v1/taxes`,
    deleteInvoice: `${import.meta.env.VITE_API}/v1/invoices/{invoiceId}`,
    patchTax: `${import.meta.env.VITE_API}/v1/taxes/{taxId}`,
    generateInvoiceNumber: `${
      import.meta.env.VITE_API
    }/v1/invoices/generate-invoice-number`,
    pay: `${import.meta.env.VITE_API}/v1/invoices/{invoiceId}/record_payment`,
    printInvoice: `${import.meta.env.VITE_API}/v1/invoices/{invoiceId}/pdf`,
    archivedInvoice: `${
      import.meta.env.VITE_API
    }/v1/invoices/{invoiceId}/archive`,
    activeInvoice: `${import.meta.env.VITE_API}/v1/invoices/{invoiceId}/active`,
    saveToTemplates: `${import.meta.env.VITE_API}/v1/saletemplates`,
    fetchTemplates: `${import.meta.env.VITE_API}/v1/saletemplates`,
    fetchTemplate: `${import.meta.env.VITE_API}/v1/saletemplates/{templateId}`,
    fetchInvoicePublic: `${
      import.meta.env.VITE_API
    }/v1/invoices/{invoiceId}/public`,
    sendInvoice: `${import.meta.env.VITE_API}/v1/invoices/{invoiceId}/send`,
    remind: `${import.meta.env.VITE_API}/v1/invoices/remind/{invoiceId}`,
    shareLink: `${import.meta.env.VITE_API}/v1/invoices/{invoiceId}/share_link`,
  },
  prepaidGate: {
    issueCardByController: `${
      import.meta.env.VITE_API
    }/v1/debit-cards/issue-card`,
    getCardInfo: `${
      import.meta.env.VITE_PREPAID
    }/v1/admin/cards/{sanOrPan}/info`,
  },
  transactive: {
    accounts: `${import.meta.env.VITE_API}/v1/admin/trans-accounts`,
    preview: `${
      import.meta.env.VITE_API
    }/v1/admin/trans-payments/preview-transfer`,
    confirm: `${import.meta.env.VITE_API}/v1/admin/trans-payments/confirm`,
  },
  sepa: {
    accounts: `${import.meta.env.VITE_API}/v1/admin/sepa/accounts`,
    preview: `${
      import.meta.env.VITE_API
    }/v1/admin/sepa/payments/preview-transfer`,
    confirm: `${import.meta.env.VITE_API}/v1/admin/sepa-payments/confirm`,
    sweepPreview: `${
      import.meta.env.VITE_API
    }/v1/admin/sepa/payments/sweep-preview`,
  },
  files: {
    items: `${import.meta.env.VITE_API}/v2/admin/files`,
    item: `${import.meta.env.VITE_API}/v2/admin/files/{fileId}`,
    patchUserFile: `${import.meta.env.VITE_API}/v2/admin/files/{fileId}`,
    newPatchUserFile: `${
      import.meta.env.VITE_API
    }/v2/admin/files/{fileId}/attributes`,
    upload: `${import.meta.env.VITE_API}/v2/admin/onboard/files/{userId}`,
    miniImage: `${import.meta.env.VITE_API}/v2/admin/files/{fileId}/thumbnail`,
    itemsClient: `${import.meta.env.VITE_API}/v2/files`,
    miniImageClient: `${import.meta.env.VITE_API}/v2/files/{fileId}/thumbnail`,
    itemClient: `${import.meta.env.VITE_API}/v2/files/{fileId}`,
  },
  crypto: {
    confirm: `${import.meta.env.VITE_API}/v1/admin/operations/crypto-trade`,
    itemUser: `${
      import.meta.env.VITE_API
    }/v1/admin/users/{userId}/crypto-recipients/{id}`,
    itemsUser: `${
      import.meta.env.VITE_API
    }/v1/admin/users/{userId}/crypto-recipients`,
    createItemUser: `${
      import.meta.env.VITE_API
    }/v2/admin/users/{userId}/crypto-recipients`,
    item: `${import.meta.env.VITE_API}/v1/crypto-recipients/{id}`,
    items: `${import.meta.env.VITE_API}/v1/crypto-recipients`,
    createItem: `${import.meta.env.VITE_API}/v2/crypto-recipients`,
    createItemNew: `${import.meta.env.VITE_API}/v3/crypto-recipients`,
  },
  tasks: {
    fetchTask: `${import.meta.env.VITE_API}/v1/admin/tasks/{taskId}/details`,
    fetchTaskClient: `${import.meta.env.VITE_API}/v1/tasks/{taskId}/details`,
    inbox: `${import.meta.env.VITE_API}/v1/admin/tasks/inbox`,
    inboxClient: `${import.meta.env.VITE_API}/v1/tasks/inbox`,
    inboxCountClient: `${import.meta.env.VITE_API}/v1/tasks/inbox-count`,
    activity: `${import.meta.env.VITE_API}/v1/admin/tasks/activity`,
    fetchActivityClientByAdmin: `${
      import.meta.env.VITE_API
    }/v1/admin/users/{userId}/tasks/activity`,
    activityClient: `${import.meta.env.VITE_API}/v1/tasks/activity`,
    rejectTask: `${import.meta.env.VITE_API}/v1/admin/tasks/{taskId}/reject`,
    approveTask: `${import.meta.env.VITE_API}/v1/admin/tasks/{taskId}/approve`,
    proofOfPayment: `${
      import.meta.env.VITE_PDF
    }/v1/admin/users/{userId}/tasks/{taskId}/pdf`,
    proofOfPaymentClient: `${import.meta.env.VITE_PDF}/v1/tasks/{taskId}/pdf`,
    sendWireInfo: `${
      import.meta.env.VITE_API
    }/v1/admin/tasks/{taskId}/send-wire-info`,
    inquire: `${import.meta.env.VITE_API}/v1/tasks/{taskId}/inquire`,

    rejectTaskClient: `${import.meta.env.VITE_API}/v1/tasks/{taskId}/reject`,
    cancelTaskClient: `${import.meta.env.VITE_API}/v1/tasks/{taskId}/cancel`,
    closeTaskClient: `${import.meta.env.VITE_API}/v1/tasks/{taskId}/close`,

    makeUnread: `${
      import.meta.env.VITE_API
    }/v1/admin/tasks/set-opened/{eventId}/unread`,

    fetchCountInboxAdmin: `${
      import.meta.env.VITE_API
    }/v1/admin/tasks/inbox-counts`,

    iSentMoney: `${import.meta.env.VITE_API}/v2/operations/deposit-inform`,
  },
  templates: {
    depositMessages: `${import.meta.env.VITE_API}/v1/admin/deposit-messages`,
    depositMessage: `${
      import.meta.env.VITE_API
    }/v1/admin/deposit-messages/{templateId}`,
  },
  balanceAdmin: {
    fetchBalances: `${import.meta.env.VITE_API}/v1/admin/fiat/balance`,
    fetchCryptoBalances: `${import.meta.env.VITE_API}/v1/admin/crypto/balance`,
    fetchHistory: `${import.meta.env.VITE_API}/v1/admin/fiat/history`,
  },
  stats: {
    subClients: `${import.meta.env.VITE_API}/v1/staistic/clients/users`,
    mainClients: `${import.meta.env.VITE_API}/v1/staistic/clients`,
  },
  costAdmin: {
    fetchCostAdmin: `${import.meta.env.VITE_API}/v1/reports/costs`,
    fetchCostRecipients: `${import.meta.env.VITE_API}/v1/cost-recipients`,
  },
  pnlAdmin: {
    fetchPnlAdmin: `${import.meta.env.VITE_API}/v1/reports/pnl`,
    fetchPnlAdminByDate: `${import.meta.env.VITE_API}/v1/reports/pnl-by-date`,
    fetchPnlAdminByFee: `${import.meta.env.VITE_API}/v1/reports/pnl-by-fee`,
    fetchPnlAdminByClient: `${
      import.meta.env.VITE_API
    }/v1/reports/pnl-by-client`,

    fetchPnlAdminByClientNew: `${
      import.meta.env.VITE_API
    }/v2/reports/pnl-by-client`,
    fetchPnlAdminByFeeNew: `${import.meta.env.VITE_API}/v2/reports/pnl-by-fee`,
    fetchPnlAdminByDateNew: `${
      import.meta.env.VITE_API
    }/v2/reports/pnl-by-date`,
    fetchPnlAdminByDateGroupNew: `${
      import.meta.env.VITE_API
    }/v2/reports/pnl-by-date-group`,
    fetchPnlAdminByBrunch: `${
      import.meta.env.VITE_API
    }/v1/reports/pnl-by-brunch`,
    fetchPnlAdminTotal: `${import.meta.env.VITE_API}/v1/reports/pnl-total`,
    fetchTbByMonth: `${import.meta.env.VITE_API}/v1/reports/tb-by-month`,
    rates: `${import.meta.env.VITE_API}/v1/admin/rates`,
    fetchQuotes: `${import.meta.env.VITE_OLD_CRYPTO}/v1/quotes`,
  },
  prepaid: {
    activate: `${import.meta.env.VITE_PREPAID}/v2/cards/{accountId}/activate`,
    cvv: `${import.meta.env.VITE_PREPAID}/v1/cards/{accountId}/cvv`,
    requestCard: `${import.meta.env.VITE_PREPAID}/v1/cards/request-card`,
    requestCardAdmin: `${import.meta.env.VITE_PREPAID}/v1/cards/issue`,
  },
  smartForms: {
    confirm: `${import.meta.env.VITE_API}/v1/branchadmin/operations/confirm`,
    sweepPreview: `${
      import.meta.env.VITE_API
    }/v1/admin/trans-payments/sweep-preview`,
    sweepConfirm: `${import.meta.env.VITE_API}/v1/admin/trans-payments/confirm`,
  },
  branches: {
    fetchItems: `${import.meta.env.VITE_API}/v1/admin/parents`,
    addItem: `${import.meta.env.VITE_API}/v1/admin/parents`,
    editItem: `${import.meta.env.VITE_API}/v1/admin/parents/{parentUserId}`,
    deleteItem: `${import.meta.env.VITE_API}/v1/admin/parents/{parentUserId}`,
    fetchBranch: `${import.meta.env.VITE_API}/v1/admin/parents/{parentUserId}`,
  },
  stocks: {
    fetchStocksByAccountId: `${
      import.meta.env.VITE_API
    }/v1/accounts/{accountId}/stocks`,
    searchSymbols: `${import.meta.env.VITE_API}/v1/stocks/symbols`,
  },
  devices: {
    items: `${import.meta.env.VITE_API}/v1/devices`,
    item: `${import.meta.env.VITE_API}/v1/devices/{deviceId}`,
    history: `${import.meta.env.VITE_API}/v1/devices/{deviceId}/history`,
    itemsUser: `${import.meta.env.VITE_API}/v1/admin/users/{userId}/devices`,
    historyUser: `${
      import.meta.env.VITE_API
    }/v1/admin/users/{userId}/devices/{deviceId}/history`,
    itemUser: `${
      import.meta.env.VITE_API
    }/v1/admin/users/{userId}/devices/{deviceId}`,
  },
  employees: {
    fetchEmployees: `${
      import.meta.env.VITE_API
    }/v1/admin/users/{userId}/employees`,
    addEmployees: `${
      import.meta.env.VITE_API
    }/v1/admin/users/{userId}/employees/many`,
    deleteEmployee: `${
      import.meta.env.VITE_API
    }/v1/admin/users/{userId}/employees/{employeeId}`,
  },
  offers: {
    fetchItems: `${import.meta.env.VITE_API}/v1/offers`,
    fetchItem: `${import.meta.env.VITE_API}/v1/offers/{offerId}`,
    createItem: `${import.meta.env.VITE_API}/v1/admin/offers`,
    updateItem: `${import.meta.env.VITE_API}/v1/admin/offers/{offerId}`,
    deleteItem: `${import.meta.env.VITE_API}/v1/admin/offers/{offerId}`,
  },

  messagesAdmin: {
    messages: `${import.meta.env.VITE_API}/v1/admin/messages`,
    message: `${import.meta.env.VITE_API}/v1/admin/messages/{messageId}`,
  },

  variables: {
    items: `${import.meta.env.VITE_API}/v1/admin/variables`,
  },

  overrides: {
    items: `${import.meta.env.VITE_API}/v1/admin/variables/overrides`,
  },
  signed: {
    signOpenDepositAccount: `${
      import.meta.env.VITE_API
    }/v1/sign-document/open-deposit-account`,
    signCryptoLoan: `${import.meta.env.VITE_API}/v1/sign-document/crypto-loan`,
    signOpenAgreement: `${import.meta.env.VITE_API}/v1/sign-document/agreement`,
    signToPending: `${
      import.meta.env.VITE_API
    }/v2/onboard/started-sign-account-opening-form`,
  },
  alerts: {
    fetchItems: `${import.meta.env.VITE_API}/v1/alerts/{accountId}`,
    patchItem: `${import.meta.env.VITE_API}/v1/alerts/{accountId}/{messageId}`,
    fetchItemsAdmin: `${import.meta.env.VITE_API}/v1/admin/alerts/{accountId}`,
    patchItemAdmin: `${
      import.meta.env.VITE_API
    }/v1/admin/alerts/{accountId}/{messageId}`,
  },
  documentTemplates: {
    items: `${import.meta.env.VITE_API}/v1/document-templates`,
  },
  news: {
    fetchNewsItem: `${import.meta.env.VITE_API}/v1/news/{newsId}`,
    createNews: `${import.meta.env.VITE_API}/v1/admin/news`,
    adminItem: `${import.meta.env.VITE_API}/v1/admin/news/{newsId}`,
  },
  fxAccounts: {
    fetchFxAccounts: `${import.meta.env.VITE_API}/v1/admin/fx-accounts`,
    transferPreview: `${
      import.meta.env.VITE_API
    }/v1/admin/operations/fx-preview`,
  },

  secureMessages: {
    fetchSent: `${import.meta.env.VITE_API}/v1/secure-messages/sent`,
    fetchInbox: `${import.meta.env.VITE_API}/v1/secure-messages/inbox`,
    fetchOffers: `${import.meta.env.VITE_API}/v1/secure-messages/offers`,
    fetchMessage: `${import.meta.env.VITE_API}/v1/secure-messages/message/{id}`,
    fetchMessagesThread: `${
      import.meta.env.VITE_API
    }/v1/secure-messages/message/{id}/thread`,
    reply: `${import.meta.env.VITE_API}/v1/secure-messages/message/{id}/reply`,
    createMessage: `${import.meta.env.VITE_API}/v1/secure-messages/message`,
    readMessage: `${import.meta.env.VITE_API}/v1/secure-messages/opened/{id}`,
    deleteThread: `${import.meta.env.VITE_API}/v1/secure-messages/message/{id}`,

    fetchSentAdmin: `${import.meta.env.VITE_API}/v1/admin/secure-messages/sent`,
    fetchInboxAdmin: `${
      import.meta.env.VITE_API
    }/v1/admin/secure-messages/inbox`,
    fetchOffersAdmin: `${
      import.meta.env.VITE_API
    }/v1/admin/secure-messages/offers`,
    fetchMessageAdmin: `${
      import.meta.env.VITE_API
    }/v1/admin/secure-messages/message/{id}`,
    fetchMessagesThreadAdmin: `${
      import.meta.env.VITE_API
    }/v1/admin/secure-messages/message/{id}/thread`,
    replyAdmin: `${
      import.meta.env.VITE_API
    }/v1/admin/secure-messages/message/{id}/reply`,
    createMessageAdmin: `${
      import.meta.env.VITE_API
    }/v1/admin/secure-messages/message`,
    readMessageAdmin: `${
      import.meta.env.VITE_API
    }/v1/admin/secure-messages/opened/{id}`,
    deleteThreadAdmin: `${
      import.meta.env.VITE_API
    }/v1/admin/secure-messages/message/{id}`,
  },

  globalCards: {
    fetchCards: `${import.meta.env.VITE_CARDS}/v2/admin/cards`,
    fetchCard: `${import.meta.env.VITE_CARDS}/v2/admin/cards/{cardId}`,
    fetchCardDetails: `${
      import.meta.env.VITE_CARDS
    }/v2/admin/cards/{cardId}/details`,
    updateCardDetails: `${
      import.meta.env.VITE_CARDS
    }/v2/admin/cards/{cardId}/details`,
    fetchCardPin: `${
      import.meta.env.VITE_CARDS
    }/v2/admin/cards/{cardId}/get-card-pin`,
    fetchStatus: `${import.meta.env.VITE_CARDS}/v2/admin/cards/{cardId}/status`,
    fetchCardholderInfo: `${
      import.meta.env.VITE_CARDS
    }/v2/admin/cards/{cardId}/cardholder-info`,
    fetchTransactions: `${
      import.meta.env.VITE_CARDS
    }/v2/admin/cards/{cardId}/transactions`,
    fetchShipmentTrackingNo: `${
      import.meta.env.VITE_CARDS
    }/v2/admin/cards/{cardId}/get-shipment-tracking-no`,
    fetchShipmentTrackingNoByClient: `${
      import.meta.env.VITE_CARDS
    }/v2/cards/{cardId}/get-shipment-tracking-no`,
    fetchLimits: `${
      import.meta.env.VITE_CARDS
    }/v1/admin/cards/{cardId}/payment-limits`,
    fetchKycStatus: `${
      import.meta.env.VITE_CARDS
    }/v2/admin/cards/{cardId}/get-kyc-status`,

    unlock: `${import.meta.env.VITE_CARDS}/v2/admin/cards/{cardId}/unlock`,
    lock: `${import.meta.env.VITE_CARDS}/v2/admin/cards/{cardId}/lock`,

    frozen: `${import.meta.env.VITE_CARDS}/v2/cards/{cardId}/lock`,
    defrost: `${import.meta.env.VITE_CARDS}/v2/cards/{cardId}/unlock`,

    suspend: `${import.meta.env.VITE_CARDS}/v2/admin/cards/{cardId}/suspend`,

    activateVirtual: `${
      import.meta.env.VITE_CARDS
    }/v2/admin/cards/{cardId}/activate-virtual`,
    activatePlasticAdmin: `${
      import.meta.env.VITE_CARDS
    }/v2/admin/cards/{cardNumber}/activate-plastic`,
    convertToPlastic: `${
      import.meta.env.VITE_CARDS
    }/v2/admin/cards/{cardId}/convert-to-plastic`,

    addEx: `${import.meta.env.VITE_CARDS}/v2/admin/cards/{cardNumber}`,

    getSimpleImage: `${
      import.meta.env.VITE_CARDS
    }/v2/admin/cards/{cardId}/get-simple-image`,
    ocr: `${import.meta.env.VITE_CARDS}/v2/admin/cards/{cardId}/ocr`,

    fetchCardsByClient: `${import.meta.env.VITE_CARDS}/v2/cards`,

    issueCardByAccount: `${import.meta.env.VITE_CARDS}/v2/admin/cards`,
    activatePlastic: `${
      import.meta.env.VITE_CARDS
    }/v2/cards/{cardNumber}/activate-plastic`,

    getCardPin: `${import.meta.env.VITE_CARDS}/v2/cards/{cardId}/pin`,
    reportLostOrStolen: `${
      import.meta.env.VITE_CARDS
    }/v2/cards/{cardId}/report-lost-or-stolen`,
    fetchCardDetailsByClient: `${
      import.meta.env.VITE_CARDS
    }/v2/cards/{cardId}/details`,
    reIssue: `${import.meta.env.VITE_CARDS}/v2/cards/{cardId}/ask-re-issue`,
  },
  coins: {
    fetchCurrentCost: `${
      import.meta.env.VITE_API
    }/v1/admin/gold/get-coin-price`,
    changeCurrentCost: `${
      import.meta.env.VITE_API
    }/v1/admin/gold/set-coin-price`,

    fetchRateByCurrency: `${
      import.meta.env.VITE_API
    }/v1/gold/coin-price/{currencyId}`,
  },
  incoreBalances: {
    fetchItems: `${import.meta.env.VITE_API}/admin/v1/inc-balances`,
  },
  bookKeeping: {
    fetchLedgers: `${import.meta.env.VITE_API}/v1/admin/books/ledgers`,
    fetchTranCodes: `${import.meta.env.VITE_API}/v1/admin/books/tran-codes`,
    fetchJournals: `${import.meta.env.VITE_API}/v1/admin/books/journals`,
    fetchCurrentBalances: `${
      import.meta.env.VITE_API
    }/v1/admin/balance/current`,
  },
};

export default ENDPOINTS;
