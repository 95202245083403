import { view } from "@risingstack/react-easy-state";
import React from "react";
import { useBlocks } from "../../../../hooks/useBlocks";
import blocks from "../../../../store/modules/blocks";

export default view(({ button }: any) => {
  const { sections } = blocks;
  const getBlock: any = useBlocks({
    sections,
    sectionName: "gold-coins",
  });

  const block1 = getBlock("lorem-ipsum-dolor-sit-amet-c");
  const block2 = getBlock("lorem-ipsum-dolor-sit-amet-c_1");
  const block3 = getBlock("lorem-ipsum-dolor-sit-amet-c_2");
  const block4 = getBlock("ready-to-buy-more-than-50-coi");

  return (
    <>
      <div className="flex mb-14">
        <div className="flex-1 flex justify-center overflow-hidden rounded-lg h-[300px]">
          <img src={block1?.imageUrl} className="object-cover w-full" />
        </div>

        <div className="flex-1 pl-10">
          <h2 className="text-[1.6rem] leading-tight">{block1?.title}</h2>

          <div dangerouslySetInnerHTML={{ __html: block1?.text || "" }} />

          <div className=""></div>

          {button}
        </div>
      </div>

      <div className="flex mb-14">
        <div className="flex-1 pr-10">
          <h2 className="text-[1.6rem] leading-tight">{block2?.title}</h2>

          <div dangerouslySetInnerHTML={{ __html: block2?.text || "" }} />

          {button}
        </div>

        <div className="flex-1 flex justify-center overflow-hidden rounded-lg h-[300px]">
          <img src={block2?.imageUrl} className="object-cover w-full" />
        </div>
      </div>

      <div className="flex mb-14">
        <div className="flex-1 flex justify-center overflow-hidden rounded-lg h-[300px]">
          <img src={block3?.imageUrl} className="object-cover w-full" />
        </div>

        <div className="flex-1 pl-10">
          <h2 className="text-[1.6rem] leading-tight">{block3?.title}</h2>
          <div dangerouslySetInnerHTML={{ __html: block3?.text || "" }} />

          {button}
        </div>
      </div>

      <div className="flex ">
        <div
          dangerouslySetInnerHTML={{ __html: block4?.text || "" }}
          className="text-[1.6rem] leading-tight text-center w-full"
        />
      </div>
    </>
  );
});
