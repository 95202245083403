export const subTypeTypes = [
  {
    label: "Migom Visa®",
    value: "C1",
  },
  {
    label: "Migom Visa® Infinite",
    value: "C2",
  },
  {
    label: "Migom Visa® Elite Infinite",
    value: "C3",
  },
];
