import { lazy } from "react";
import Events from "../../components/Roles/Admin/Events";
import FeatureBranch from "../../components/Roles/Admin/Reports/Features/FeatureBranch";
import FeatureClient from "../../components/Roles/Admin/Reports/Features/FeatureClient";
import FeatureDate from "../../components/Roles/Admin/Reports/Features/FeatureDate";
import FeatureFee from "../../components/Roles/Admin/Reports/Features/FeatureFee";
import FeatureFullDetails from "../../components/Roles/Admin/Reports/Features/FeatureFullDetails";
import FeatureGroupDate from "../../components/Roles/Admin/Reports/Features/FeatureGroupDate";
import FeatureVolumeByFee from "../../components/Roles/Admin/Reports/Features/FeatureVolumeByFee";
import FeatureVolumeForPeriod from "../../components/Roles/Admin/Reports/Features/FeatureVolumeForPeriod";
import FeatureVolumePerClient from "../../components/Roles/Admin/Reports/Features/FeatureVolumePerClient";
import { AdminSecureMessages } from "../AdminSecureMessages";

const AdminArea = lazy(() => import("../../components/Roles/Admin"));

const UserStatements = lazy(() => import("../../components/UserStatements"));

const AllAccounts2 = lazy(
  () => import("../../components/Roles/Admin/AllAccounts2")
);

const TransactiveAccounts = lazy(
  () => import("../../components/Roles/Admin/Transactive/TransactiveAccounts")
);

const SepaAccounts = lazy(
  () => import("../../components/Roles/Admin/Sepa/TransactiveAccounts")
);

const UserAccount = lazy(
  () => import("../../components/Roles/Admin/AllAccounts/Account")
);

const CreateAccount = lazy(
  () => import("../../components/Roles/Admin/Client/CreateAccount")
);

const Templates = lazy(
  () => import("../../components/Roles/Admin/Settings/Templates")
);

const Template = lazy(
  () => import("../../components/Roles/Admin/Settings/Templates/Template")
);

const CreateTemplate = lazy(
  () => import("../../components/Roles/Admin/Settings/Templates/CreateTemplate")
);

const FeeEditorStructures = lazy(
  () => import("../../components/Roles/Admin/FeeEditor")
);

const AdminClients = lazy(
  () => import("../../components/Roles/Admin/AllClients")
);

const AdminStaff = lazy(() => import("../../components/Roles/Admin/AllStaffs"));

const AdminDashboard = lazy(
  () => import("../../components/Roles/Admin/Dashboard")
);

const AdminReports = lazy(() => import("../../components/Roles/Admin/Reports"));

const Deposits = lazy(
  () => import("../../components/Roles/Admin/Dashboard/Deposits")
);

const Home = lazy(() => import("../../components/Roles/Admin/Dashboard/Home"));

const NewPnlByDate = lazy(
  () => import("../../components/Roles/Admin/Reports/NewPnl/Date")
);

const NewPnlByBranch = lazy(
  () => import("../../components/Roles/Admin/Reports/NewPnl/ByBranch")
);

const NewPnlByClient = lazy(
  () => import("../../components/Roles/Admin/Reports/NewPnl/Client")
);

const NewBranchPnlByClient = lazy(
  () => import("../../components/Roles/Admin/Reports/NewPnl/Branch/Client")
);

const NewPnlByFee = lazy(
  () => import("../../components/Roles/Admin/Reports/NewPnl/Fee")
);

const NewBranchPnlByFee = lazy(
  () => import("../../components/Roles/Admin/Reports/NewPnl/Branch/Fee")
);

const NewPnlGraphics = lazy(
  () => import("../../components/Roles/Admin/Reports/NewPnl/PnlGraphics")
);

const CurrenciesPairs = lazy(
  () => import("../../components/Roles/Admin/Dashboard/CurrenciesPairs")
);

const ByCountry = lazy(
  () => import("../../components/Roles/Admin/Dashboard/ByCountry")
);

const ByCountry2 = lazy(
  () => import("../../components/Roles/Admin/Dashboard/ByCountry2")
);

const BySize = lazy(
  () => import("../../components/Roles/Admin/Dashboard/BySize")
);

const Largest = lazy(
  () => import("../../components/Roles/Admin/Dashboard/Largest")
);

const ExpectedFee = lazy(
  () => import("../../components/Roles/Admin/Dashboard/ExpectedFee")
);

const BalanceToDate = lazy(
  () => import("../../components/Roles/Admin/Dashboard/BalanceToDate")
);

const SystemAccounts = lazy(
  () => import("../../components/Roles/Admin/Dashboard/SystemAccounts")
);

const AllOperations = lazy(
  () => import("../../components/Roles/Admin/Dashboard/AllOperations")
);

const InterestPaymentArea = lazy(
  () => import("../../components/Roles/Admin/Dashboard/InterestPayment")
);
const LastInterestPayment = lazy(
  () => import("../../components/Roles/Admin/Dashboard/InterestPayment/Last")
);

const FutureInterestPayment = lazy(
  () => import("../../components/Roles/Admin/Dashboard/InterestPayment/Future")
);

const AllOperations2 = lazy(
  () => import("../../components/Roles/Admin/Dashboard/AllOperations2")
);

const Agents = lazy(
  () => import("../../components/Roles/Admin/Dashboard/ReportAgents")
);

const CountUsers = lazy(
  () => import("../../components/Roles/Admin/Dashboard/CountUsers")
);

const CountOperationsPerDay = lazy(
  () => import("../../components/Roles/Admin/Dashboard/CountOperationsPerDay")
);

const CountOperations = lazy(
  () => import("../../components/Roles/Admin/Dashboard/CountOperations")
);

const Onboarding = lazy(
  () => import("../../components/Roles/Admin/Dashboard/Onboarding")
);

const AdminPayments = lazy(
  () => import("../../components/Roles/Admin/Transactive/Payments")
);

const SepaAdminPayments = lazy(
  () => import("../../components/Roles/Admin/Sepa/Payments")
);

const AdminPayments2 = lazy(
  () => import("../../components/Roles/Admin/Transactive/Payments2")
);

const SepaAdminPayments2 = lazy(
  () => import("../../components/Roles/Admin/Sepa/Payments2")
);

const Transactive = lazy(
  () => import("../../components/Roles/Admin/Transactive")
);

const Sepa = lazy(() => import("../../components/Roles/Admin/Sepa"));

const Files = lazy(() => import("../../components/Roles/Admin/Files"));

const ClientAdminView = lazy(
  () => import("../../components/Roles/Admin/Client")
);

const ClientAccountsAdminView2 = lazy(
  () => import("../../components/Roles/Admin/Client/Accounts2")
);

const ClientRelatedAdminView = lazy(
  () => import("../../components/Roles/Admin/Client/Related")
);

const ClientDetailAdminView = lazy(
  () => import("../../components/Roles/Admin/Client/Detail")
);

const ClientDebitCardAdminView = lazy(
  () => import("../../components/Roles/Admin/Client/DebitCard")
);

const ClientFiles = lazy(
  () => import("../../components/Roles/Admin/Client/Files")
);

const ClientFilesAdminView = lazy(
  () => import("../../components/Roles/Admin/Client/OnBoard")
);

const ClientPlaidAccountsAdminView = lazy(
  () => import("../../components/Roles/Admin/Client/PlaidAccounts")
);

const ClientExtraAdminView = lazy(
  () => import("../../components/Roles/Admin/Client/Extra")
);

const ClientRolesAdminView = lazy(
  () => import("../../components/Roles/Admin/Client/Roles")
);

const ClientEventsAdminView = lazy(
  () => import("../../components/Roles/Admin/Client/Events")
);

const ClientBranchDetailAreaAdminView = lazy(
  () => import("../../components/Roles/Admin/Client/BranchDetails")
);

const ClientWireRecipients = lazy(
  () => import("../../components/Roles/Admin/Client/Recipients/WireRecipients")
);

const ClientWireRecipientsList = lazy(
  () =>
    import("../../components/Roles/Admin/Client/Recipients/WireRecipients/List")
);

const ClientWireRecipientsEdit = lazy(
  () =>
    import("../../components/Roles/All/WireRecipients/WireRecipientDetail/Edit")
);

const ClientWireRecipientsCreate = lazy(
  () =>
    import(
      "../../components/Roles/Admin/Client/Recipients/WireRecipients/Create"
    )
);

const ClientWireRecipientsDetail = lazy(
  () =>
    import(
      "../../components/Roles/Admin/Client/Recipients/WireRecipients/Detail"
    )
);

const ClientRecipients = lazy(
  () => import("../../components/Roles/Admin/Client/Recipients")
);

const ClientMigomRecipients = lazy(
  () => import("../../components/Roles/Admin/Client/Recipients/MigomRecipients")
);

const ClientWireActivity = lazy(
  () => import("../../components/Roles/Admin/Client/Recipients/WireActivity")
);

const ClientCryptoActivity = lazy(
  () => import("../../components/Roles/Admin/Client/Recipients/CryptoActivity")
);

const ClientCryptoRecipients = lazy(
  () =>
    import("../../components/Roles/Admin/Client/Recipients/CryptoRecipients")
);

const ClientAlerts = lazy(
  () => import("../../components/Roles/Admin/Client/Alerts")
);

const ClientRisk = lazy(
  () => import("../../components/Roles/Admin/Client/Risk")
);

const FeeEditorFormulas = lazy(
  () => import("../../components/Roles/Admin/FeeEditor/Formulas")
);

const FeeEditorTreeStructures = lazy(
  () => import("../../components/Roles/Admin/FeeEditor/TreeStructures")
);

const FeeEditorTableStructures = lazy(
  () => import("../../components/Roles/Admin/FeeEditor/StructuresTable")
);

const BalanceArea = lazy(() => import("../../components/Roles/Admin/Balance"));

const GC = lazy(
  () => import("../../components/Roles/Admin/Balance/Balances/GC")
);

const SFOX = lazy(
  () => import("../../components/Roles/Admin/Balance/Balances/SFOX")
);

const BalancesHistory = lazy(
  () => import("../../components/Roles/Admin/Balance/History")
);

const Days = lazy(() => import("../../components/Roles/Admin/Days"));

const DaysDay = lazy(() => import("../../components/Roles/Admin/Days/DayData"));

const DaysDayTable1 = lazy(
  () => import("../../components/Roles/Admin/Days/DayData/Table1")
);

const DaysDayTable2 = lazy(
  () => import("../../components/Roles/Admin/Days/DayData/Table2")
);

const DaysDayTable3 = lazy(
  () => import("../../components/Roles/Admin/Days/DayData/Table3")
);

const DaysDayTable3Detail = lazy(
  () =>
    import("../../components/Roles/Admin/Days/DayData/Table3/DetailCurrency")
);

const Stats = lazy(() => import("../../components/Roles/Admin/Stats"));

const StatsMainClients = lazy(
  () => import("../../components/Roles/Admin/Stats/MainClients")
);

const StatsSubClients = lazy(
  () => import("../../components/Roles/Admin/Stats/SubClients")
);

const PnlDetailPage = lazy(
  () =>
    import("../../components/Roles/Admin/Reports/TrialBalance/PnlDetailPage")
);

const PnlDetailMain = lazy(
  () => import("../../components/Roles/Admin/Reports/NewPnl/Main")
);

const TrialBalance = lazy(
  () => import("../../components/Roles/Admin/Reports/TrialBalance")
);

const Cost = lazy(() => import("../../components/Roles/Admin/Dashboard/Cost"));

const Branches = lazy(() => import("../../components/Roles/Admin/Branches"));

const DetailBranches = lazy(
  () => import("../../components/Roles/Admin/Branches/DetailBranch")
);

const DetailBranchesInfo = lazy(
  () => import("../../components/Roles/Admin/Branches/DetailBranch/Detail")
);

const DetailBranchesClients = lazy(
  () => import("../../components/Roles/Admin/Branches/DetailBranch/Clients")
);

const DetailBranchesStructures = lazy(
  () => import("../../components/Roles/Admin/Branches/DetailBranch/Structures")
);

const DetailBranchesAccounts = lazy(
  () => import("../../components/Roles/Admin/Branches/DetailBranch/Accounts")
);

const FeeRules = lazy(
  () => import("../../components/Roles/Admin/FeeEditor/FeeRules")
);

const FeeRule = lazy(
  () => import("../../components/Roles/Admin/FeeEditor/FeeRules/FeeRule")
);

const DashboardAccounts = lazy(
  () => import("../../components/Roles/Admin/Dashboard/Accounts")
);

const DashboardBrokenUsers = lazy(
  () => import("../../components/Roles/Admin/Dashboard/BrokenUsers")
);

const DashboardCountApprovedTasks = lazy(
  () => import("../../components/Roles/Admin/Dashboard/CountApprovedTasks")
);

const ActionsWithBranch = lazy(
  () => import("../../components/Roles/Admin/Branches/DetailBranch/Actions")
);

const ClientEmployees = lazy(
  () => import("../../components/Roles/Admin/Client/Employees")
);

const ClientAddEmployees = lazy(
  () => import("../../components/Roles/Admin/Client/Employees/AddEmployees")
);

const TableFormulas = lazy(
  () => import("../../components/Roles/Admin/FeeEditor/TableFormulas")
);

const CryptoAdmin = lazy(() => import("../../components/Roles/Admin/Crypto"));

const IncoreAdmin = lazy(() => import("../../components/Roles/Admin/Incore"));

const CryptoAdminTransactions = lazy(
  () => import("../../components/Roles/Admin/Crypto/Transactions")
);

const CryptoAdminOperations = lazy(
  () => import("../../components/Roles/Admin/Crypto/Operations")
);

const Offers = lazy(
  () => import("../../components/Roles/Admin/Settings/Offers")
);

const Messages = lazy(
  () => import("../../components/Roles/Admin/Settings/Messages")
);

const Message = lazy(
  () => import("../../components/Roles/Admin/Settings/Messages/Message")
);

const CreateMessage = lazy(
  () => import("../../components/Roles/Admin/Settings/Messages/CreateMessage")
);

const Variables = lazy(
  () => import("../../components/Roles/Admin/Settings/Variables")
);

const Variable = lazy(
  () => import("../../components/Roles/Admin/Settings/Variables/Variable")
);

const Overrides = lazy(
  () => import("../../components/Roles/Admin/Settings/Overrides")
);

const Override = lazy(
  () => import("../../components/Roles/Admin/Settings/Overrides/Override")
);

const CreateOverride = lazy(
  () => import("../../components/Roles/Admin/Settings/Overrides/CreateOverride")
);

const Settings = lazy(() => import("../../components/Roles/Admin/Settings"));

const DocumentTemplates = lazy(
  () => import("../../components/Roles/Admin/Settings/DocumentTemplates")
);

const OperationsCards = lazy(
  () => import("../../components/Roles/Admin/OperationsCards")
);

const UncompletedUsers = lazy(
  () => import("../../components/Roles/Admin/Settings/UncompletedUsers")
);

const FxAccounts = lazy(
  () => import("../../components/Roles/Admin/FxAccounts")
);

const CreateNews = lazy(
  () => import("../../components/Roles/Admin/Settings/News/CreateNews")
);

const NewsDetail = lazy(
  () => import("../../components/Roles/Admin/Settings/News/NewsDetail")
);

const News = lazy(() => import("../../components/Roles/Admin/Settings/News"));

const GlobalCards = lazy(
  () => import("../../components/Roles/Admin/GlobalCards")
);

const ClientCard = lazy(
  () => import("../../components/Roles/Admin/Client/Cards/Card")
);

const ClientCards = lazy(
  () => import("../../components/Roles/Admin/Client/Cards")
);

const Coins = lazy(() => import("../../components/Roles/Admin/Coins"));

const BlocksSettings = lazy(
  () => import("../../components/Roles/Admin/Settings/Blocks")
);

const BlocksDetailSettings = lazy(
  () => import("../../components/Roles/Admin/Settings/Blocks/Block")
);

const BlocksCreateSettings = lazy(
  () => import("../../components/Roles/Admin/Settings/Blocks/CreateBlock")
);

const Marketing = lazy(() => import("../../components/Roles/Admin/Marketing"));

const IncoreBalances = lazy(
  () => import("../../components/Roles/Admin/Balance/IncoreBalances")
);

const BookKeeping = lazy(
  () => import("../../components/Roles/Admin/BookKeeping")
);

const Ledgers = lazy(
  () => import("../../components/Roles/Admin/BookKeeping/Ledgers")
);

const TranCodes = lazy(
  () => import("../../components/Roles/Admin/BookKeeping/TranCodes")
);

const Journals = lazy(
  () => import("../../components/Roles/Admin/BookKeeping/Journals")
);

const ServicesStatus = lazy(
  () => import("../../components/Roles/Admin/ServicesStatus")
);

const GlobalLoginHistory = lazy(
  () => import("../../components/Roles/Admin/Devices/LoginHistory")
);

const GlobalDevices = lazy(
  () => import("../../components/Roles/Admin/Devices")
);

const GlobalListDevices = lazy(
  () => import("../../components/Roles/Admin/Devices/AllDevices")
);

const GlobalListEvents = lazy(
  () => import("../../components/Roles/Admin/Devices/Events")
);

const GlobalRecipientsMain = lazy(
  () => import("../../components/Roles/Admin/Recipients")
);

const GlobalRecipientsList = lazy(
  () => import("../../components/Roles/Admin/Recipients/List")
);

const GlobalRecipientsDetail = lazy(
  () => import("../../components/Roles/Admin/Recipients/Detail")
);

const GlobalRecipientsDetailEdit = lazy(
  () => import("../../components/Roles/Admin/Recipients/Detail/Edit")
);

const Employees = lazy(() => import("../../components/Roles/Admin/Employees"));

const GlobalRecipientsBlocked = lazy(
  () => import("../../components/Roles/Admin/Recipients/Blocked")
);

const Example = lazy(() => import("../../components/App/Example"));

const VolumeForPeriod = lazy(
  () => import("../../components/Roles/Admin/Reports/VolumeForPeriod")
);

const PnlByBrunch = lazy(
  () => import("../../components/Roles/Admin/Reports/PnlByBrunch")
);

const PnlByClient = lazy(
  () => import("../../components/Roles/Admin/Reports/PnlByClient")
);

const PnlByDate = lazy(
  () => import("../../components/Roles/Admin/Reports/PnlByDate")
);

const PnlByFee = lazy(
  () => import("../../components/Roles/Admin/Reports/PnlByFee")
);

const PnlByDateGroup = lazy(
  () => import("../../components/Roles/Admin/Reports/PnlByDateGroup")
);

const NumberOfApprovedTasks = lazy(
  () => import("../../components/Roles/Admin/Dashboard/NumberOfApprovedTasks")
);

export const Admin = [
  {
    path: "/app/admin",
    component: AdminArea,

    routes: [
      ...AdminSecureMessages,
      {
        path: "/app/admin/global-cards",
        component: GlobalCards,
      },
      {
        path: "/app/admin/fx",
        component: FxAccounts,
      },
      {
        path: "/app/admin/recipients",
        component: GlobalRecipientsMain,
        routes: [
          {
            path: "/app/admin/recipients/:userId/recipient/:recipientId/edit",
            component: GlobalRecipientsDetailEdit,
          },
          {
            path: "/app/admin/recipients/:userId/recipient/:recipientId",
            component: GlobalRecipientsDetail,
          },
          {
            path: "/app/admin/recipients/blocked",
            component: GlobalRecipientsBlocked,
          },
          {
            path: "/app/admin/recipients",
            component: GlobalRecipientsList,
          },
        ],
      },
      {
        path: "/app/admin/services-status",
        component: ServicesStatus,
      },
      {
        path: "/app/admin/book-keeping",
        component: BookKeeping,
        routes: [
          {
            path: "/app/admin/book-keeping/tran-codes",
            component: TranCodes,
          },
          {
            path: "/app/admin/book-keeping/ledgers",
            component: Ledgers,
          },
          {
            path: "/app/admin/book-keeping/journals",
            component: Journals,
          },
        ],
      },

      {
        path: "/app/admin/devices",
        component: GlobalDevices,
        routes: [
          {
            path: "/app/admin/devices/events",
            component: GlobalListEvents,
          },
          {
            path: "/app/admin/devices/history",
            component: GlobalLoginHistory,
          },
          {
            path: "/app/admin/devices",
            component: GlobalListDevices,
          },
        ],
      },

      {
        path: "/app/admin/marketing",
        component: Marketing,
      },

      {
        path: "/app/admin/operations-cards",
        component: OperationsCards,
      },
      {
        path: "/app/admin/events",
        component: Events,
      },
      {
        path: "/app/admin/coins",
        component: Coins,
      },

      {
        path: "/app/admin/branches/:userId",
        component: DetailBranches,

        routes: [
          {
            path: "/app/admin/branches/:userId/clients",
            component: DetailBranchesClients,
          },
          {
            path: "/app/admin/branches/:userId/structures",
            component: DetailBranchesStructures,
          },
          {
            path: "/app/admin/branches/:userId/actions",
            component: ActionsWithBranch,
          },
          {
            path: "/app/admin/branches/:userId/accounts",
            component: DetailBranchesAccounts,
          },
          {
            path: "/app/admin/branches/:userId/detail",
            component: DetailBranchesInfo,
          },
          {
            path: "/app/admin/branches/:userId/pnl-date",
            component: NewPnlByDate,
          },
          {
            path: "/app/admin/branches/:userId/pnl-client",
            component: NewBranchPnlByClient,
          },
          {
            path: "/app/admin/branches/:userId/pnl-fee",
            component: NewBranchPnlByFee,
          },
          {
            path: "/app/admin/branches/:userId/v2/pnl-date",
            component: FeatureDate,
          },
          {
            path: "/app/admin/branches/:userId/v2/pnl-client",
            component: FeatureClient,
          },
          {
            path: "/app/admin/branches/:userId/v2/pnl-fee",
            component: FeatureFee,
          },
          {
            path: "/app/admin/branches/:userId/trial-balance/:year",
            component: TrialBalance,
          },
        ],
      },
      {
        path: "/app/admin/branches",
        component: Branches,
      },
      {
        path: "/app/admin/employees",
        component: Employees,
      },
      {
        path: "/app/admin/settings",
        component: Settings,

        routes: [
          {
            path: "/app/admin/settings/uncompleted-users",
            component: UncompletedUsers,
          },
          {
            path: "/app/admin/settings/document-templates",
            component: DocumentTemplates,
          },
          {
            path: "/app/admin/settings/messages/create-message",
            component: CreateMessage,
          },
          {
            path: "/app/admin/settings/messages/:messageId",
            component: Message,
          },

          {
            path: "/app/admin/settings/variables/:variableId",
            component: Variable,
          },

          {
            path: "/app/admin/settings/overrides/create-override",
            component: CreateOverride,
          },
          {
            path: "/app/admin/settings/overrides/:variableId",
            component: Override,
          },
          {
            path: "/app/admin/settings/blocks/create-block",
            component: BlocksCreateSettings,
          },
          {
            path: "/app/admin/settings/templates/create-template",
            component: CreateTemplate,
          },
          {
            path: "/app/admin/settings/news/create-news",
            component: CreateNews,
          },
          {
            path: "/app/admin/settings/blocks/:id",
            component: BlocksDetailSettings,
          },
          {
            path: "/app/admin/settings/templates/:templateId",
            component: Template,
          },
          {
            path: "/app/admin/settings/news/:newsId",
            component: NewsDetail,
          },
          {
            path: "/app/admin/settings/blocks",
            component: BlocksSettings,
          },
          {
            path: "/app/admin/settings/offers",
            component: Offers,
          },

          {
            path: "/app/admin/settings/variables",
            component: Variables,
          },
          {
            path: "/app/admin/settings/overrides",
            component: Overrides,
          },
          {
            path: "/app/admin/settings/messages",
            component: Messages,
          },
          {
            path: "/app/admin/settings/templates",
            component: Templates,
          },
          {
            path: "/app/admin/settings/news",
            component: News,
          },
        ],
      },
      {
        path: "/app/admin/dashboard",
        component: AdminDashboard,

        routes: [
          {
            path: "/app/admin/dashboard/accounts",
            component: DashboardAccounts,
          },
          {
            path: "/app/admin/dashboard/broken-users",
            component: DashboardBrokenUsers,
          },
          {
            path: "/app/admin/dashboard/count-approved-tasks",
            component: DashboardCountApprovedTasks,
          },
          // FEATURES
          {
            path: "/app/admin/dashboard/pnl/full-details",
            component: FeatureFullDetails,
          },
          // FEATURES
          {
            path: "/app/admin/dashboard/pnl/main",
            component: PnlDetailMain,
          },
          {
            path: "/app/admin/dashboard/pnl/detail",
            component: PnlDetailPage,
          },
          {
            path: "/app/admin/dashboard/cost",
            component: Cost,
          },
          {
            path: "/app/admin/dashboard/trial-balance/:year",
            component: TrialBalance,
          },
          {
            path: "/app/admin/dashboard/home",
            component: Home,
          },
          {
            path: "/app/admin/dashboard/deposits",
            component: Deposits,
          },
          {
            path: "/app/admin/dashboard/pnl-date",
            component: NewPnlByDate,
          },
          {
            path: "/app/admin/dashboard/volume-for-period",
            component: VolumeForPeriod,
          },

          // FEATURES

          {
            path: "/app/admin/dashboard/v3/pnl-by-branch",
            component: FeatureBranch,
          },

          {
            path: "/app/admin/dashboard/v3/pnl-by-client",
            component: FeatureClient,
          },
          {
            path: "/app/admin/dashboard/v3/pnl-by-date",
            component: FeatureDate,
          },
          {
            path: "/app/admin/dashboard/v3/pnl-by-fee",
            component: FeatureFee,
          },
          {
            path: "/app/admin/dashboard/v3/pnl-by-group-date",
            component: FeatureGroupDate,
          },
          {
            path: "/app/admin/dashboard/v3/volume-per-client",
            component: FeatureVolumePerClient,
          },
          {
            path: "/app/admin/dashboard/v3/volume-by-fee",
            component: FeatureVolumeByFee,
          },
          {
            path: "/app/admin/dashboard/v3/volume-for-period",
            component: FeatureVolumeForPeriod,
          },
          // FEATURES

          {
            path: "/app/admin/dashboard/pnl-by-branch",
            component: PnlByBrunch,
          },

          {
            path: "/app/admin/dashboard/pnl-by-client",
            component: PnlByClient,
          },
          {
            path: "/app/admin/dashboard/pnl-by-date",
            component: PnlByDate,
          },
          {
            path: "/app/admin/dashboard/pnl-by-fee",
            component: PnlByFee,
          },
          {
            path: "/app/admin/dashboard/pnl-by-date-group",
            component: PnlByDateGroup,
          },
          {
            path: "/app/admin/dashboard/pnl-branch",
            component: NewPnlByBranch,
          },
          {
            path: "/app/admin/dashboard/pnl-client",
            component: NewPnlByClient,
          },
          {
            path: "/app/admin/dashboard/pnl-fee",
            component: NewPnlByFee,
          },
          {
            path: "/app/admin/dashboard/pnl-graphics",
            component: NewPnlGraphics,
          },
          {
            path: "/app/admin/dashboard/currencies-pairs",
            component: CurrenciesPairs,
          },
          {
            path: "/app/admin/dashboard/by-country1",
            component: ByCountry,
          },
          {
            path: "/app/admin/dashboard/by-country2",
            component: ByCountry2,
          },
          {
            path: "/app/admin/dashboard/by-size",
            component: BySize,
          },
          {
            path: "/app/admin/dashboard/20-largest",
            component: Largest,
          },
          {
            path: "/app/admin/dashboard/expected-fee",
            component: ExpectedFee,
          },
          {
            path: "/app/admin/dashboard/balance-to-date",
            component: BalanceToDate,
          },
          {
            path: "/app/admin/dashboard/system-accounts",
            component: SystemAccounts,
          },
          {
            path: "/app/admin/dashboard/all-operations",
            component: AllOperations,
          },
          {
            path: "/app/admin/dashboard/number-of-approved-tasks",
            component: NumberOfApprovedTasks,
          },
          {
            path: "/app/admin/dashboard/interest-payment",
            component: InterestPaymentArea,
            routes: [
              {
                path: "/app/admin/dashboard/interest-payment/future",
                component: FutureInterestPayment,
              },
              {
                path: "/app/admin/dashboard/interest-payment/last",
                component: LastInterestPayment,
              },
            ],
          },

          {
            path: "/app/admin/dashboard/all-operations-2",
            component: AllOperations2,
          },

          {
            path: "/app/admin/dashboard/agents",
            component: Agents,
          },
          {
            path: "/app/admin/dashboard/count-users",
            component: CountUsers,
          },
          {
            path: "/app/admin/dashboard/count-operations-per-day",
            component: CountOperationsPerDay,
          },
          {
            path: "/app/admin/dashboard/count-operations",
            component: CountOperations,
          },
          {
            path: "/app/admin/dashboard/onboarding",
            component: Onboarding,
          },
        ],
      },
      {
        path: "/app/admin/reports",
        component: AdminReports,

        routes: [
          {
            path: "/app/admin/reports/pnl-graphics",
            component: NewPnlGraphics,
          },
          // FEATURES
          {
            path: "/app/admin/reports/pnl/full-details",
            component: FeatureFullDetails,
          },
          // FEATURES
          {
            path: "/app/admin/reports/pnl/main",
            component: PnlDetailMain,
          },
          {
            path: "/app/admin/reports/pnl/detail",
            component: PnlDetailPage,
          },

          {
            path: "/app/admin/reports/trial-balance/:year",
            component: TrialBalance,
          },

          {
            path: "/app/admin/reports/volume-for-period",
            component: VolumeForPeriod,
          },

          // FEATURES

          {
            path: "/app/admin/reports/v3/pnl-by-branch",
            component: FeatureBranch,
          },

          {
            path: "/app/admin/reports/v3/pnl-by-client",
            component: FeatureClient,
          },
          {
            path: "/app/admin/reports/v3/pnl-by-date",
            component: FeatureDate,
          },
          {
            path: "/app/admin/reports/v3/pnl-by-fee",
            component: FeatureFee,
          },
          {
            path: "/app/admin/reports/v3/pnl-by-group-date",
            component: FeatureGroupDate,
          },
          {
            path: "/app/admin/reports/v3/volume-per-client",
            component: FeatureVolumePerClient,
          },
          {
            path: "/app/admin/reports/v3/volume-by-fee",
            component: FeatureVolumeByFee,
          },
          {
            path: "/app/admin/reports/v3/volume-for-period",
            component: FeatureVolumeForPeriod,
          },
          // FEATURES

          {
            path: "/app/admin/reports/pnl-by-branch",
            component: PnlByBrunch,
          },

          {
            path: "/app/admin/reports/pnl-by-client",
            component: PnlByClient,
          },
          {
            path: "/app/admin/reports/pnl-by-date",
            component: PnlByDate,
          },
          {
            path: "/app/admin/reports/pnl-by-fee",
            component: PnlByFee,
          },
          {
            path: "/app/admin/reports/pnl-by-date-group",
            component: PnlByDateGroup,
          },
          {
            path: "/app/admin/reports/pnl-branch",
            component: NewPnlByBranch,
          },
          {
            path: "/app/admin/reports/pnl-client",
            component: NewPnlByClient,
          },
          {
            path: "/app/admin/reports/pnl-fee",
            component: NewPnlByFee,
          },
          {
            path: "/app/admin/reports/pnl-graphics",
            component: NewPnlGraphics,
          },
          {
            path: "/app/admin/reports/pnl-date",
            component: NewPnlByDate,
          },
          {
            path: "/app/admin/reports/volume-for-period",
            component: VolumeForPeriod,
          },
        ],
      },
      {
        path: "/app/admin/days",
        component: Days,
        routes: [
          {
            path: "/app/admin/days",
            component: Example,
            exact: true,
          },
          {
            path: "/app/admin/days/:dayId/table3/:currencyId",
            component: DaysDayTable3Detail,
          },
          {
            path: "/app/admin/days/:dayId",
            component: DaysDay,
            routes: [
              {
                path: "/app/admin/days/:dayId/table1",
                component: DaysDayTable1,
              },
              {
                path: "/app/admin/days/:dayId/table2",
                component: DaysDayTable2,
              },
              {
                path: "/app/admin/days/:dayId/table3",
                component: DaysDayTable3,
              },
            ],
          },
        ],
      },

      {
        path: "/app/admin/sepa",
        component: Sepa,

        routes: [
          {
            path: "/app/admin/sepa/payments/2",
            component: SepaAdminPayments2,
          },
          {
            path: "/app/admin/sepa/vaults/:accountId",
            component: UserAccount,
          },
          {
            path: "/app/admin/sepa/payments",
            component: SepaAdminPayments,
          },
          {
            path: "/app/admin/sepa/accounts",
            component: SepaAccounts,
          },
        ],
      },

      {
        path: "/app/admin/transactive",
        component: Transactive,

        routes: [
          {
            path: "/app/admin/transactive/payments/2",
            component: AdminPayments2,
          },
          {
            path: "/app/admin/transactive/vaults/:accountId",
            component: UserAccount,
          },
          {
            path: "/app/admin/transactive/payments",
            component: AdminPayments,
          },
          {
            path: "/app/admin/transactive/accounts",
            component: TransactiveAccounts,
          },
        ],
      },

      {
        path: "/app/admin/balance",
        component: BalanceArea,

        routes: [
          {
            path: "/app/admin/balance/history",
            component: BalancesHistory,
          },
          {
            path: "/app/admin/balance/gc",
            component: GC,
          },
          {
            path: "/app/admin/balance/sfox",
            component: SFOX,
          },
          {
            path: "/app/admin/balance/incore",
            component: IncoreBalances,
          },
        ],
      },

      {
        path: "/app/admin/stats",
        component: Stats,

        routes: [
          {
            path: "/app/admin/stats/:id",
            component: StatsSubClients,
          },
          {
            path: "/app/admin/stats",
            component: StatsMainClients,
          },
        ],
      },

      {
        path: "/app/admin/files",
        component: Files,
      },
      {
        path: "/app/admin/crypto",
        component: CryptoAdmin,

        routes: [
          {
            path: "/app/admin/crypto/vaults/:accountId",
            component: UserAccount,
          },
          {
            path: "/app/admin/crypto/operations",
            component: CryptoAdminOperations,
          },
          {
            path: "/app/admin/crypto",
            component: CryptoAdminTransactions,
          },
        ],
      },
      {
        path: "/app/admin/incore",
        component: IncoreAdmin,

        routes: [
          {
            path: "/app/admin/incore",
            exact: true,
            component: Example,
          },
          {
            path: "/app/admin/incore/vaults/:accountId",
            component: UserAccount,
          },
        ],
      },

      {
        path: "/app/admin/accounts/:accountId/statements",
        component: UserStatements,
      },
      {
        path: "/app/admin/accounts/:accountId",
        component: UserAccount,
      },

      {
        path: "/app/admin/fees",
        component: FeeEditorStructures,

        routes: [
          {
            path: "/app/admin/fees/structures/:structureId/formulas",
            component: FeeEditorFormulas,
          },
          {
            path: "/app/admin/fees/rules/:ruleId",
            component: FeeRule,
          },
          {
            path: "/app/admin/fees/table-formulas",
            component: TableFormulas,
          },
          {
            path: "/app/admin/fees/structures",
            component: FeeEditorTableStructures,
          },
          {
            path: "/app/admin/fees/rules",
            component: FeeRules,
          },
          {
            path: "/app/admin/fees",
            component: FeeEditorTreeStructures,
          },
        ],
      },

      {
        path: "/app/admin/new-create-account/:userId",
        component: CreateAccount,
      },

      {
        path: "/app/admin/staff",
        component: AdminStaff,
      },

      {
        path: "/app/admin/clients/:userId",
        component: ClientAdminView,

        routes: [
          {
            path: "/app/admin/clients/:userId",
            component: Example,
            exact: true,
          },
          {
            path: "/app/admin/clients/:userId/cards/:cardId",
            component: ClientCard,
          },
          {
            path: "/app/admin/clients/:userId/cards",
            component: ClientCards,
          },
          {
            path: "/app/admin/clients/:userId/accounts",
            component: ClientAccountsAdminView2,
          },
          {
            path: "/app/admin/clients/:userId/related",
            component: ClientRelatedAdminView,
          },
          {
            path: "/app/admin/clients/:userId/detail",
            component: ClientDetailAdminView,
          },
          {
            path: "/app/admin/clients/:userId/issue-card",
            component: ClientDebitCardAdminView,
          },
          {
            path: "/app/admin/clients/:userId/files",
            component: ClientFiles,
          },
          {
            path: "/app/admin/clients/:userId/onboard",
            component: ClientFilesAdminView,
          },
          {
            path: "/app/admin/clients/:userId/employees/add",
            component: ClientAddEmployees,
          },
          {
            path: "/app/admin/clients/:userId/employees",
            component: ClientEmployees,
          },
          {
            path: "/app/admin/clients/:userId/alerts",
            component: ClientAlerts,
          },
          {
            path: "/app/admin/clients/:userId/risk",
            component: ClientRisk,
          },
          {
            path: "/app/admin/clients/:userId/branch-details",
            component: ClientBranchDetailAreaAdminView,

            routes: [
              {
                path: "/app/admin/clients/:userId/branch-details/clients",
                component: DetailBranchesClients,
              },
              {
                path: "/app/admin/clients/:userId/branch-details/structures",
                component: DetailBranchesStructures,
              },
              {
                path: "/app/admin/clients/:userId/branch-details/actions",
                component: ActionsWithBranch,
              },
              {
                path: "/app/admin/clients/:userId/branch-details/accounts",
                component: DetailBranchesAccounts,
              },
              {
                path: "/app/admin/clients/:userId/branch-details/detail",
                component: DetailBranchesInfo,
              },
              {
                path: "/app/admin/clients/:userId/branch-details/pnl-date",
                component: NewPnlByDate,
              },

              {
                path: "/app/admin/clients/:userId/branch-details/pnl-client",
                component: NewBranchPnlByClient,
              },
              {
                path: "/app/admin/clients/:userId/branch-details/pnl-fee",
                component: NewBranchPnlByFee,
              },
              {
                path: "/app/admin/clients/:userId/branch-details/trial-balance/:year",
                component: TrialBalance,
              },
            ],
          },
          {
            path: "/app/admin/clients/:userId/recipients",
            component: ClientRecipients,

            routes: [
              {
                path: "/app/admin/clients/:userId/recipients/crypto-activity",
                component: ClientCryptoActivity,
              },
              {
                path: "/app/admin/clients/:userId/recipients/wire-activity",
                component: ClientWireActivity,
              },
              {
                path: "/app/admin/clients/:userId/recipients/contacts",
                component: ClientMigomRecipients,
              },
              {
                path: "/app/admin/clients/:userId/recipients/crypto-recipients",
                component: ClientCryptoRecipients,
              },

              {
                path: "/app/admin/clients/:userId/recipients",
                component: ClientWireRecipients,

                routes: [
                  {
                    path: "/app/admin/clients/:userId/recipients/wire/create",
                    component: ClientWireRecipientsCreate,
                  },
                  {
                    path: "/app/admin/clients/:userId/recipients/wire/:id/edit",
                    component: ClientWireRecipientsEdit,
                  },
                  {
                    path: "/app/admin/clients/:userId/recipients/wire/:id",
                    component: ClientWireRecipientsDetail,
                  },
                  {
                    path: "/app/admin/clients/:userId/recipients",
                    component: ClientWireRecipientsList,
                  },
                ],
              },
            ],
          },

          {
            path: "/app/admin/clients/:userId/plaid-accounts",
            component: ClientPlaidAccountsAdminView,
          },
          {
            path: "/app/admin/clients/:userId/extra",
            component: ClientExtraAdminView,
          },
          {
            path: "/app/admin/clients/:userId/roles",
            component: ClientRolesAdminView,
          },
          {
            path: "/app/admin/clients/:userId/events",
            component: ClientEventsAdminView,
          },
          {
            path: "/app/admin/clients/:userId/devices",
            component: GlobalDevices,
            routes: [
              {
                path: "/app/admin/clients/:userId/devices/history",
                component: GlobalLoginHistory,
              },
              {
                path: "/app/admin/clients/:userId/devices",
                component: GlobalListDevices,
              },
            ],
          },
        ],
      },
      {
        path: "/app/admin/clients",
        component: AdminClients,
      },

      {
        path: "/app/admin/accounts",
        component: AllAccounts2,
      },
    ],
  },
];
