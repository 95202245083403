import { lazy } from "react";

const BranchAdminClientAdminView = lazy(
  () => import("../../components/Roles/BranchAdmin/Clients/Client")
);

const BranchAdminClientAccountsAdminView2 = lazy(
  () => import("../../components/Roles/BranchAdmin/Clients/Client/Accounts2")
);

const BranchAdminClientDetailAdminView = lazy(
  () => import("../../components/Roles/BranchAdmin/Clients/Client/Detail")
);

const BranchAdminClientAlertsAdminView = lazy(
  () => import("../../components/Roles/BranchAdmin/Clients/Client/Alerts")
);

const BranchAdminClientFiles = lazy(
  () => import("../../components/Roles/BranchAdmin/Clients/Client/Files")
);

const BranchAdminClientFilesAdminView = lazy(
  () => import("../../components/Roles/BranchAdmin/Clients/Client/OnBoard")
);

const BranchAdminClientPlaidAccountsAdminView = lazy(
  () =>
    import("../../components/Roles/BranchAdmin/Clients/Client/PlaidAccounts")
);

const BranchAdminClientWireRecipients = lazy(
  () =>
    import(
      "../../components/Roles/BranchAdmin/Clients/Client/Recipients/WireRecipients"
    )
);

const BranchAdminClientWireRecipientsList = lazy(
  () =>
    import(
      "../../components/Roles/BranchAdmin/Clients/Client/Recipients/WireRecipients/List"
    )
);

const BranchAdminClientWireRecipientsCreate = lazy(
  () =>
    import(
      "../../components/Roles/BranchAdmin/Clients/Client/Recipients/WireRecipients/Create"
    )
);

const BranchAdminClientWireRecipientsDetail = lazy(
  () =>
    import(
      "../../components/Roles/BranchAdmin/Clients/Client/Recipients/WireRecipients/Detail"
    )
);
const BranchAdminClientWireRecipientsEdit = lazy(
  () =>
    import("../../components/Roles/All/WireRecipients/WireRecipientDetail/Edit")
);

const BranchAdminClientRecipients = lazy(
  () => import("../../components/Roles/BranchAdmin/Clients/Client/Recipients")
);

const BranchAdminClientMigomRecipients = lazy(
  () =>
    import(
      "../../components/Roles/BranchAdmin/Clients/Client/Recipients/MigomRecipients"
    )
);

const BranchAdminClientWireActivity = lazy(
  () => import("../../components/Roles/Admin/Client/Recipients/WireActivity")
);

const BranchAdminClientCryptoActivity = lazy(
  () => import("../../components/Roles/Admin/Client/Recipients/CryptoActivity")
);

const BranchAdminClientCryptoRecipients = lazy(
  () =>
    import(
      "../../components/Roles/BranchAdmin/Clients/Client/Recipients/CryptoRecipients"
    )
);

const BranchAdminClients = lazy(
  () => import("../../components/Roles/BranchAdmin/Clients")
);

const UserAccount = lazy(
  () => import("../../components/Roles/Admin/AllAccounts/Account")
);

const BranchAdminAccounts = lazy(
  () => import("../../components/Roles/BranchAdmin/Accounts")
);

const BranchAdminNewAccounts = lazy(
  () => import("../../components/Roles/BranchAdmin/NewAccounts")
);

const BranchAdminCreateAccount = lazy(
  () =>
    import("../../components/Roles/BranchAdmin/Clients/Client/CreateAccount")
);

const ClientDevices = lazy(
  () => import("../../components/Roles/All/Profile/Devices")
);

const ClientDevicesHistory = lazy(
  () => import("../../components/Roles/All/Profile/Devices/HistoryDevices")
);

const TableFormulas = lazy(
  () => import("../../components/Roles/Admin/FeeEditor/TableFormulas")
);

const FeeEditorStructures = lazy(
  () => import("../../components/Roles/Admin/FeeEditor")
);

const FeeEditorFormulas = lazy(
  () => import("../../components/Roles/Admin/FeeEditor/Formulas")
);

const FeeEditorTreeStructures = lazy(
  () => import("../../components/Roles/Admin/FeeEditor/TreeStructures")
);

const FeeEditorTableStructures = lazy(
  () => import("../../components/Roles/Admin/FeeEditor/StructuresTable")
);

export const BranchAdmin = [
  {
    path: "/app/branch-admin/clients/:userId",
    component: BranchAdminClientAdminView,

    routes: [
      {
        path: "/app/branch-admin/clients/:userId/plaid-accounts",
        component: BranchAdminClientPlaidAccountsAdminView,
      },
      {
        path: "/app/branch-admin/clients/:userId/accounts",
        component: BranchAdminClientAccountsAdminView2,
      },
      {
        path: "/app/branch-admin/clients/:userId/detail",
        component: BranchAdminClientDetailAdminView,
      },
      {
        path: "/app/branch-admin/clients/:userId/alerts",
        component: BranchAdminClientAlertsAdminView,
      },

      {
        path: "/app/branch-admin/clients/:userId/files",
        component: BranchAdminClientFiles,
      },
      {
        path: "/app/branch-admin/clients/:userId/onboard",
        component: BranchAdminClientFilesAdminView,
      },

      {
        path: "/app/branch-admin/clients/:userId/devices/history/:deviceId",
        component: ClientDevicesHistory,
      },

      {
        path: "/app/branch-admin/clients/:userId/devices",
        component: ClientDevices,
      },

      {
        path: "/app/branch-admin/clients/:userId/recipients",
        component: BranchAdminClientRecipients,

        routes: [
          {
            path: "/app/branch-admin/clients/:userId/recipients/wire-activity",
            component: BranchAdminClientWireActivity,
          },
          {
            path: "/app/branch-admin/clients/:userId/recipients/crypto-activity",
            component: BranchAdminClientCryptoActivity,
          },
          {
            path: "/app/branch-admin/clients/:userId/recipients/contacts",
            component: BranchAdminClientMigomRecipients,
          },
          {
            path: "/app/branch-admin/clients/:userId/recipients/crypto-recipients",
            component: BranchAdminClientCryptoRecipients,
          },

          {
            path: "/app/branch-admin/clients/:userId/recipients",
            component: BranchAdminClientWireRecipients,

            routes: [
              {
                path: "/app/branch-admin/clients/:userId/recipients/wire/create",
                component: BranchAdminClientWireRecipientsCreate,
              },
              {
                path: "/app/branch-admin/clients/:userId/recipients/wire/:id/edit",
                component: BranchAdminClientWireRecipientsEdit,
              },
              {
                path: "/app/branch-admin/clients/:userId/recipients/wire/:id",
                component: BranchAdminClientWireRecipientsDetail,
              },
              {
                path: "/app/branch-admin/clients/:userId/recipients",
                component: BranchAdminClientWireRecipientsList,
              },
            ],
          },
        ],
      },
    ],
  },

  {
    path: "/app/branch-admin/new-create-account/:userId",
    component: BranchAdminCreateAccount,
  },

  {
    path: "/app/branch-admin/clients",
    component: BranchAdminClients,
  },
  {
    path: "/app/branch-admin/accounts/:accountId",
    component: UserAccount,
  },
  {
    path: "/app/branch-admin/accounts",
    component: BranchAdminAccounts,
  },
  {
    path: "/app/branch-admin/accounts-v2",
    component: BranchAdminNewAccounts,
  },
  {
    path: "/app/branch-admin/fee-editor",
    component: FeeEditorStructures,

    routes: [
      {
        path: "/app/branch-admin/fee-editor/structures/:structureId/formulas",
        component: FeeEditorFormulas,
      },
      {
        path: "/app/branch-admin/fee-editor/table-formulas",
        component: TableFormulas,
      },
      {
        path: "/app/branch-admin/fee-editor/structures",
        component: FeeEditorTableStructures,
      },
      {
        path: "/app/branch-admin/fee-editor",
        component: FeeEditorTreeStructures,
      },
    ],
  },
];
