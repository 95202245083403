import { lazy } from "react";
const Faqs = lazy(() => import("../../components/Faqs"));

const Main = lazy(() => import("../../components/Landing"));

const DebitCards = lazy(() => import("../../components/Landing/DebitCards"));

const Securities = lazy(() => import("../../components/Landing/Securities"));

const InvestorRelations = lazy(
  () => import("../../components/Landing/InvestorRelations")
);

const MigomApp = lazy(() => import("../../components/Landing/MigomApp"));

const MigomBank = lazy(() => import("../../components/Landing/MigomBank"));

const Overview = lazy(() => import("../../components/Landing/Overview"));

const PrivacyPolicy = lazy(
  () => import("../../components/Landing/PrivacyPolicy")
);

const TermsAndConditions = lazy(
  () => import("../../components/Landing/TermsAndConditions")
);

const AmlPolicy = lazy(() => import("../../components/Landing/AmlPolicy"));

const DepositOffer = lazy(() => import("../../components/Offers/Deposit"));
const CryptoLoansOffer = lazy(
  () => import("../../components/Offers/CryptoLoans")
);
const GoldCoins = lazy(() => import("../../components/Offers/GoldCoins"));

const VerifyStaticPage = lazy(
  () => import("../../components/SumSubStaticPage")
);

const QrCodeOffer = lazy(() => import("../../components/Offers/QrCode"));

const News = lazy(() => import("../../components/Landing/News"));

const NewsItem = lazy(() => import("../../components/Landing/News/NewsItem"));

const CustomerService = lazy(
  () => import("../../components/Roles/All/Profile/CustomerService")
);

const LearnMore = lazy(
  () => import("../../components/Roles/All/Profile/LearnMore")
);

export const Landing = [
  {
    path: "/",
    component: Main,

    routes: [
      {
        path: "/",

        exact: true,
        component: MigomBank,
      },
      {
        path: "/faqs",

        component: Faqs,
      },
      {
        path: "/news/:newsId",

        component: NewsItem,
      },
      {
        path: "/news",

        component: News,
      },
      {
        path: "/qr-code",

        component: QrCodeOffer,
      },
      {
        path: "/savings-account",

        component: DepositOffer,
      },
      {
        path: "/crypto-loans",

        component: CryptoLoansOffer,
      },
      {
        path: "/gold-coins",
        component: GoldCoins,
      },
      {
        path: "/migom-app",
        component: MigomApp,
      },
      {
        path: "/verify-static-page",

        component: VerifyStaticPage,
      },
      {
        path: "/debit-cards",
        component: DebitCards,
      },
      {
        path: "/securities",
        component: Securities,
      },
      {
        path: "/investor-relations",
        component: InvestorRelations,
      },
      {
        path: "/e-money",
        component: Overview,
      },
      {
        path: "/terms-and-conditions",
        component: TermsAndConditions,
      },
      {
        path: "/aml-policy",
        component: AmlPolicy,
      },
      {
        path: "/privacy-policy",
        component: PrivacyPolicy,
      },
      {
        path: "/contact-us",
        component: CustomerService,
      },
      {
        path: "/learn-more",
        component: LearnMore,
      },
    ],
  },
];
