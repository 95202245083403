import { lazy } from "react";
import CheckOrder from "../components/CheckOrder";
import Quest from "../components/Quest";
import OrderDelivery from "../components/Roles/Client/OrderDelivery";
import GoldCoinsCollections from "../components/Roles/Client/Overview/GoldCoinsCollections";
import ReadMoreGoldCoins from "../components/Roles/Client/Overview/ReadMoreGoldCoins";
import { Admin } from "./Admin/index";
import { Analyst } from "./Analyst/index";
import { Application } from "./Application/index";
import { Auth } from "./Auth/index";
import { BranchAdmin } from "./BranchAdmin/index";
import { CardAdmin } from "./CardAdmin";
import { ClientSecureMessages } from "./ClientSecureMessages/index";
import { HighBalance } from "./HighBalance/index";
import { Invoices } from "./Invoices/index";
import { Landing } from "./Landing/index";
import { NewTasksStaff } from "./NewTasksStaff";
import { OfferCreateAccount } from "./OfferCreateAccount/index";
import { Pay } from "./Pay/index";
import { PayService } from "./PayService/index";
import { Profile } from "./Profile/index";
import { Recipients } from "./Recipients/index";
import { ShortApplication } from "./ShortApplication/index";
import { Tasks } from "./Tasks/index";

const App = lazy(() => import("../components/App"));

const PotentialClients = lazy(
  () => import("../components/Roles/Staff/PotentialClients")
);

const WaitForCorporate = lazy(() => import("../components/WaitForCorporate"));
const NotFound = lazy(() => import("../components/NotFound"));

const Statements = lazy(() => import("../components/Statements"));

const FeesAccount = lazy(
  () => import("../components/Roles/Client/AccountFormulas")
);

const PotentialClient = lazy(
  () => import("../components/Roles/Staff/PotentialClient")
);

const PotentialClientOnboard = lazy(
  () => import("../components/Roles/All/UserDocuments")
);

const ReleaseOffer = lazy(() => import("../components/Offers/Release"));

const GetApp = lazy(() => import("../components/GetMobileApp"));

const SubSum = lazy(() => import("../components/Onboard/Application/Sumsub"));

const OverviewClient = lazy(
  () => import("../components/Roles/Client/Overview")
);

const OverviewAccount = lazy(
  () => import("../components/Roles/Client/Overview/AccountsBlocks/Account")
);

const OverviewIssueCardArea = lazy(
  () =>
    import(
      "../components/Roles/Client/Overview/AccountsBlocks/Account/OrderCard"
    )
);

const OverviewIssueCardSelectType = lazy(
  () =>
    import(
      "../components/Roles/Client/Overview/AccountsBlocks/Account/OrderCard/DebitCardType"
    )
);

const OrderCardForm = lazy(
  () =>
    import(
      "../components/Roles/Client/Overview/AccountsBlocks/Account/OrderCard/Form"
    )
);

const OrderCardPreview = lazy(
  () =>
    import(
      "../components/Roles/Client/Overview/AccountsBlocks/Account/OrderCard/Preview"
    )
);

const OrderCardChooseFillForm = lazy(
  () =>
    import(
      "../components/Roles/Client/Overview/AccountsBlocks/Account/OrderCard/ChooseTypeFillForm"
    )
);

const OrderCardManual = lazy(
  () =>
    import(
      "../components/Roles/Client/Overview/AccountsBlocks/Account/OrderCard/Manual"
    )
);

const OpenAccount = lazy(
  () => import("../components/Roles/Client/OpenAccount")
);

const QrCode = lazy(() => import("../components/Roles/Client/QrCode"));
const CreateQrCode = lazy(
  () => import("../components/Roles/Client/QrCode/CreateQrCode")
);
const ListQrCode = lazy(() => import("../components/Roles/Client/QrCode/List"));

const CheckEmailPage = lazy(() => import("../components/Auth/CheckEmailPage"));

const InvoicePrint = lazy(
  () => import("../components/Roles/Client/Invoices/InvoicePrint")
);

const InvoicePreview = lazy(
  () => import("../components/Roles/Client/Invoices/Invoice")
);

const SumsubPageForMobile = lazy(
  () => import("../components/SumsubPageForMobile")
);

const Submitted = lazy(
  () => import("../components/SumsubPageForMobile/Submitted")
);

const SumsubPublic = lazy(() => import("../components/SumsubPublic"));

const NotSuccessful = lazy(() => import("../components/NotSuccessful"));

const SecureEntrance = lazy(() => import("../components/SecureEntrance"));

const sumSubForClerk = lazy(
  () => import("../components/Onboard/Application/Sumsub")
);

const PotentialClientOnboardNew = lazy(
  () =>
    import("../components/Roles/Admin/Client/OnBoard/components/SumsubFiles")
);

const PotentialClientOnboardOld = lazy(
  () => import("../components/Roles/All/UserDocuments/Table")
);

const PotentialClientOnboardCurrencies = lazy(
  () => import("../components/Roles/All/UserDocuments/CurrenciesToOpenAccounts")
);

const PotentialClientOnboardAccounts = lazy(
  () => import("../components/Roles/All/UserDocuments/Accounts")
);

const PotentialClientOnboardDetail = lazy(
  () => import("../components/Roles/All/UserDocuments/UserInfo")
);

const AppVersionUpdater = lazy(
  () => import("../components/Roles/AppVersionUpdater")
);

const CryptoLoans = lazy(() => import("../components/Offers/CryptoLoans"));

const CryptoLoansStep2 = lazy(
  () => import("../components/Offers/CryptoLoans/Step2")
);

const News = lazy(() => import("../components/Roles/Admin/Settings/News"));
const CreateNews = lazy(
  () => import("../components/Roles/Admin/Settings/News/CreateNews")
);

const NewsDetail = lazy(
  () => import("../components/Roles/Admin/Settings/News/NewsDetail")
);

const Example = lazy(() => import("../components/App/Example"));

const Test = lazy(() => import("../components/Test"));

const IWantBuyCryptoAssetsStep1 = lazy(
  () => import("../components/IWantBuyCryptoAssets/Step1")
);

const IWantBuyCryptoAssetsStep5 = lazy(
  () => import("../components/IWantBuyCryptoAssets/Step5")
);

const Schema = [
  {
    path: "/quest",
    component: Quest,
  },
  {
    path: "/i-want-to-buy-gold-crypto-assets/5",
    component: IWantBuyCryptoAssetsStep5,
  },
  {
    path: "/i-want-to-buy-gold-crypto-assets/1",
    component: IWantBuyCryptoAssetsStep1,
  },

  {
    path: "/test",

    component: Test,
  },
  {
    path: "/404",

    component: NotFound,
  },
  {
    path: "/secure-entrance/:token",
    component: SecureEntrance,
  },
  {
    path: "/not-successful",
    component: NotSuccessful,
  },
  {
    path: "/public-identity-verify",
    component: SumsubPublic,
  },
  {
    path: "/submitted",
    component: Submitted,
  },

  {
    path: "/identity-verify",
    component: SumsubPageForMobile,
  },
  {
    path: "/verify-identity",
    component: SubSum,
  },
  {
    path: "/check-email",
    component: CheckEmailPage,
  },
  {
    path: "/pay/:invoiceId",
    component: InvoicePreview,
  },
  {
    path: "/invoice/:invoiceId",
    component: InvoicePreview,
  },
  {
    path: "/invoices/:invoiceId/print",
    component: InvoicePrint,
  },
  ...Application,
  ...ShortApplication,
  {
    path: "/app",
    component: App,
    routes: [
      {
        path: "/app/read-more-gold-coins",
        component: ReadMoreGoldCoins,
      },
      {
        path: "/app/gold-coins-collections",

        component: GoldCoinsCollections,
      },
      {
        path: "/app/check-order",

        component: CheckOrder,
      },
      {
        path: "/app",
        component: Example,
        exact: true,
      },

      ...Admin,
      ...CardAdmin,
      ...Invoices,
      ...Pay,
      ...HighBalance,
      ...Recipients,
      ...BranchAdmin,
      ...NewTasksStaff,
      ...ClientSecureMessages,
      ...Analyst,
      ...Tasks,
      {
        path: "/app/version-updater",
        component: AppVersionUpdater,
      },

      {
        path: "/app/news/create-news",
        component: CreateNews,
      },
      {
        path: "/app/news/:newsId",
        component: NewsDetail,
      },
      {
        path: "/app/news",
        component: News,
      },
      {
        path: "/app/crypto-loans/step2",
        component: CryptoLoansStep2,
      },
      {
        path: "/app/crypto-loans",
        component: CryptoLoans,
      },
      {
        path: "/app/wait-for-corporate",
        component: WaitForCorporate,
      },
      {
        path: "/app/open-account",
        component: OpenAccount,
      },

      {
        path: "/app/release",
        component: ReleaseOffer,
        routes: OfferCreateAccount,
      },

      {
        path: "/app/qr-code",
        component: QrCode,
        routes: [
          {
            path: "/app/qr-code/create",
            component: CreateQrCode,
          },
          {
            path: "/app/qr-code",
            component: ListQrCode,
          },
        ],
      },
      {
        path: "/app/order-a-card",
        component: OverviewIssueCardArea,
        routes: [
          {
            path: "/app/order-a-card/choose-fill-form",
            component: OrderCardChooseFillForm,
          },
          {
            path: "/app/order-a-card/:type/preview",
            component: OrderCardPreview,
          },
          {
            path: "/app/order-a-card/:type/manual",
            component: OrderCardManual,
          },

          {
            path: "/app/order-a-card/:type",
            component: OrderCardForm,
          },

          {
            path: "/app/order-a-card",
            component: OverviewIssueCardSelectType,
          },
        ],
      },
      {
        path: "/app/overview/account/:accountId",
        component: OverviewAccount,
      },

      {
        path: "/app/order-delivery",
        component: OrderDelivery,
      },
      {
        path: "/app/overview",
        component: OverviewClient,
      },

      {
        path: "/app/statements/:accountId",
        component: Statements,
      },
      {
        path: "/app/fees/:accountId",
        component: FeesAccount,
      },
      {
        path: "/app/clients/:userId",
        component: PotentialClient,
        routes: [
          {
            path: "/app/clients/:userId/onboard",
            component: PotentialClientOnboard,
            routes: [
              {
                path: "/app/clients/:userId/onboard/detail",
                component: PotentialClientOnboardDetail,
              },
              {
                path: "/app/clients/:userId/onboard/accounts",
                component: PotentialClientOnboardAccounts,
              },
              {
                path: "/app/clients/:userId/onboard/currencies",
                component: PotentialClientOnboardCurrencies,
              },
              {
                path: "/app/clients/:userId/onboard/new",
                component: PotentialClientOnboardNew,
              },
              {
                path: "/app/clients/:userId/onboard/old",
                component: PotentialClientOnboardOld,
              },
              {
                path: "/app/clients/:userId/onboard/sumSub",
                component: sumSubForClerk,
              },
            ],
          },
        ],
      },
      {
        path: "/app/clients",
        component: PotentialClients,
      },
      ...Profile,
    ],
  },
  {
    path: "/get-app",
    component: GetApp,
  },
  ...PayService,
  ...Auth,
  ...Landing,
];

export default Schema;
