import numbro from "numbro";
import { memo, useCallback, useEffect } from "react";
import { useWatch } from "react-hook-form";

const CalcBuySell = memo(({ form, rate, cursor }: any) => {
  const { setValue, setError } = form;

  const buyAmount = useWatch({
    control: form.control,
    name: "buyAmount",
  });

  const sellAmount = useWatch({
    control: form.control,
    name: "sellAmount",
  });

  const sa = numbro.unformat(sellAmount) || 0;
  const ba = numbro.unformat(buyAmount) || 0;

  useEffect(() => {
    if (sa && sa > 0) {
      setError("sellAmount", {});
    }
  }, [sa, setError]);

  useEffect(() => {
    if (ba && ba > 0) {
      setError("buyAmount", {});
    }
  }, [ba, setError]);

  const calc = useCallback(() => {
    if (!rate) {
      return;
    }

    if (cursor === "buy") {
      const v = ba / rate;

      if (v !== 0) {
        setTimeout(() => setValue("sellAmount", v), 0);
      }
    }

    if (cursor === "sell") {
      const v = rate * sa;

      if (v !== 0) {
        setTimeout(() => setValue("buyAmount", v), 0);
      }
    }
  }, [ba, cursor, rate, sa, setValue]);

  useEffect(() => {
    calc();
  }, [calc]);

  return null;
});

export default CalcBuySell;
