import { grey } from "@material-ui/core/colors";
import React, { useMemo } from "react";
import { formatToCurrencyString } from "../../../../../helpers";
import { MigomTable } from "../../../../System";

const Pending = ({ children, pending }) => {
  return pending ? (
    <div className="opacity-50">{children}</div>
  ) : (
    children || null
  );
};

const PreviewTable = ({ data = [] }) => {
  const columns = useMemo(() => {
    const styleHead = {
      color: grey[600],
      whiteSpace: "nowrap",
      fontSize: ".75rem",
    };

    const style = {
      whiteSpace: "nowrap",
      fontSize: ".85rem",
    };

    return [
      {
        Header: "Acc. ID",
        styleHead: styleHead,
        style: { ...style, fontSize: ".7rem" },
        id: "accountId",
        disableSortBy: true,
        accessor: (rowData) => (
          <Pending pending={rowData.isPending}>{rowData.accountId}</Pending>
        ),
      },
      {
        Header: "Comment",
        styleHead: styleHead,
        id: "comment",
        disableSortBy: true,
        style: {
          minWidth: 240,
          maxWidth: 320,
          wordBreak: "break-word",
          fontSize: ".85rem",
        },
        accessor: (rowData) => (
          <Pending pending={rowData.isPending}>{rowData.comment}</Pending>
        ),
      },
      {
        Header: "Fee ID",
        styleHead: styleHead,
        style: style,
        id: "feeId",
        disableSortBy: true,
        accessor: (rowData) => (
          <Pending pending={rowData.isPending}>{rowData.feeId}</Pending>
        ),
      },
      {
        Header: "Fee str.",
        styleHead: styleHead,
        style: style,
        id: "structureId",
        disableSortBy: true,
        accessor: (rowData) => (
          <Pending pending={rowData.isPending}>{rowData.structureId}</Pending>
        ),
      },
      {
        Header: "Amount",
        styleHead: {
          ...styleHead,
          textAlign: "right",
          whiteSpace: "nowrap",
        },
        style: {
          textAlign: "right",
          whiteSpace: "nowrap",
        },
        id: "amount",
        disableSortBy: true,
        accessor: (rowData) => (
          <Pending pending={rowData.isPending}>
            <b>{formatToCurrencyString(rowData.amount, rowData.currencyId)}</b>
          </Pending>
        ),
      },
    ];
  }, []);

  return (
    <div>
      <MigomTable columns={columns} data={data} hidePagination autoHeight />
    </div>
  );
};

export default PreviewTable;
