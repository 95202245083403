import { lazy } from "react";

const Root = lazy(() => import("../../components/Onboard/Application"));
const Home = lazy(() => import("../../components/Onboard/Application/Home"));

const ClientType = lazy(
  () => import("../../components/Onboard/Application/ClientType")
);
const Message = lazy(
  () => import("../../components/Onboard/Application/Message")
);
const Finish = lazy(
  () => import("../../components/Onboard/Application/Finish")
);

const MessageWait = lazy(
  () => import("../../components/Onboard/Application/MessageWait")
);

export const Application = [
  {
    path: "/application",
    component: Root,
    routes: [
      {
        path: "/application",
        exact: true,
        component: Home,
      },
      {
        path: "/application/finish",
        component: Finish,
      },
      {
        path: "/application/client-type",
        component: ClientType,
      },
      {
        path: "/application/wait",
        component: MessageWait,
      },

      {
        path: "/application/message",
        component: Message,
      },
    ],
  },
];
