import numbro from "numbro";

export const symbols: Record<string, any> = {
  USD: "$",
  EUR: "€",
  GBP: "£",
  BTC: "Ƀ",
  CHF: "₣",
  RUB: "₽",
  CAD: "C$",
  AED: "د.إ",
  ETH: "Ξ",
  USDT: "₮",
  LTC: "Ł",
  TRY: "₺",
  GOLD: "",
  USDC: "",
  DKK: "kr.",
  KWD: "KD",
  MIGG: "ᙢ",
};

interface ICurrency {
  type: string;
  value: string;
  symbol: string;
  description: string;
  imgPath: string;
  decimalScale: number;
  checked?: boolean;
}

export const allCurrencies: ICurrency[] = [
  {
    type: "Fiat",
    value: "EUR",
    symbol: "€",
    description: "European euro",
    imgPath: "/currencies/eur.svg",
    decimalScale: 2,
  },
  {
    type: "Fiat",
    value: "USD",
    symbol: "$",
    description: "United States dollar",
    imgPath: "/currencies/usd.svg",
    decimalScale: 2,
  },
  {
    type: "Fiat",
    value: "GBP",
    symbol: "£",
    description: "Pound sterling",
    imgPath: "/currencies/gbp.svg",
    decimalScale: 2,
  },
  {
    type: "Fiat",
    value: "CHF",
    symbol: "₣",
    description: "Swiss franc",
    imgPath: "/currencies/chf.svg",
    decimalScale: 2,
  },
  {
    type: "Fiat",
    value: "CAD",
    symbol: "$",
    description: "Canadian dollar",
    imgPath: "/currencies/cad.svg",
    decimalScale: 2,
  },
  {
    type: "Fiat",
    value: "AED",
    symbol: "إ",
    description: "UAE dirham",
    imgPath: "/currencies/aed.svg",
    decimalScale: 2,
  },
  {
    type: "Crypto",
    value: "BTC",
    symbol: "₿",
    description: "Bitcoin",
    imgPath: "/currencies/btc.svg",
    decimalScale: 8,
  },
  {
    type: "Crypto",
    value: "ETH",
    symbol: "Ξ",
    description: "Ethereum",
    imgPath: "/currencies/eth.svg",
    decimalScale: 8,
  },
  {
    type: "Fiat",
    value: "TRY",
    symbol: "₺",
    description: "Turkish lira",
    imgPath: "/currencies/try.svg",
    decimalScale: 2,
  },
  {
    type: "Crypto",
    value: "USDT",
    symbol: "₮",
    description: "Tether",
    imgPath: "/currencies/usdt.svg",
    decimalScale: 8,
  },
  {
    type: "Crypto",
    value: "USDC",
    symbol: "USDC ",
    description: "USD Coin",
    imgPath: "/currencies/usdc.svg",
    decimalScale: 8,
  },
  {
    type: "Crypto",
    value: "LTC",
    symbol: "LTC ",
    description: "Litecoin",
    imgPath: "/currencies/ltc.svg",
    decimalScale: 8,
  },
  {
    type: "Fiat",
    value: "RUB",
    symbol: "₽",
    description: "Russian ruble",
    imgPath: "/currencies/rus.svg",
    decimalScale: 2,
  },
  {
    type: "Fiat",
    value: "HKD",
    symbol: "HK$",
    description: "The Hong Kong dollar",
    imgPath: "/currencies/hkd.svg",
    decimalScale: 2,
  },
  {
    type: "Fiat",
    value: "DKK",
    symbol: "kr.",
    description: "Danish krone",
    imgPath: "/currencies/dkk.svg",
    decimalScale: 2,
  },
  {
    type: "Fiat",
    value: "KWD",
    symbol: "KD",
    description: "Kuwaiti dinar",
    imgPath: "/currencies/kwd.svg",
    decimalScale: 2,
  },
  {
    type: "Crypto",
    value: "NEXM",
    symbol: "NEXM ",
    description: "",
    imgPath: "/currencies/nexm.svg",
    decimalScale: 8,
  },
  {
    type: "Crypto",
    value: "MIGG",
    symbol: "ᙢ",
    description: "",
    imgPath: "/currencies/migg.svg",
    decimalScale: 8,
  },
  {
    type: "Fiat",
    value: "JPY",
    symbol: "¥",
    description: "The Japanese yen",
    imgPath: "/currencies/jpy.svg",
    decimalScale: 2,
  },
];

export const allCurrencies2: ICurrency[] = [
  {
    type: "Fiat",
    value: "EUR",
    symbol: "€",
    description: "European euro",
    imgPath: "/currencies/eur.svg",
    decimalScale: 2,
  },
  {
    type: "Fiat",
    value: "USD",
    symbol: "$",
    description: "United States dollar",
    imgPath: "/currencies/usd.svg",
    decimalScale: 2,
  },
  {
    type: "Fiat",
    value: "GBP",
    symbol: "£",
    description: "Pound sterling",
    imgPath: "/currencies/gbp.svg",
    decimalScale: 2,
  },
  {
    type: "Fiat",
    value: "CHF",
    symbol: "₣",
    description: "Swiss franc",
    imgPath: "/currencies/chf.svg",
    decimalScale: 2,
  },
  {
    type: "Fiat",
    value: "CAD",
    symbol: "$",
    description: "Canadian dollar",
    imgPath: "/currencies/cad.svg",
    decimalScale: 2,
  },
  {
    type: "Fiat",
    value: "AED",
    symbol: "إ",
    description: "UAE dirham",
    imgPath: "/currencies/aed.svg",
    decimalScale: 2,
  },
  {
    type: "Crypto",
    value: "BTC",
    symbol: "₿",
    description: "Bitcoin",
    imgPath: "/currencies/btc.svg",
    decimalScale: 8,
  },
  {
    type: "Crypto",
    value: "ETH",
    symbol: "Ξ",
    description: "Ethereum",
    imgPath: "/currencies/eth.svg",
    decimalScale: 8,
  },
  {
    type: "Fiat",
    value: "TRY",
    symbol: "₺",
    description: "Turkish lira",
    imgPath: "/currencies/try.svg",
    decimalScale: 2,
  },
  {
    type: "Crypto",
    value: "USDT",
    symbol: "₮",
    description: "Tether",
    imgPath: "/currencies/usdt.svg",
    decimalScale: 8,
  },
  {
    type: "Crypto",
    value: "USDC",
    symbol: "USDC ",
    description: "USD Coin",
    imgPath: "/currencies/usdc.svg",
    decimalScale: 8,
  },
  {
    type: "Crypto",
    value: "LTC",
    symbol: "LTC ",
    description: "Litecoin",
    imgPath: "/currencies/ltc.svg",
    decimalScale: 8,
  },
  {
    type: "Fiat",
    value: "RUB",
    symbol: "₽",
    description: "Russian ruble",
    imgPath: "/currencies/rus.svg",
    decimalScale: 2,
  },
  {
    type: "Fiat",
    value: "HKD",
    symbol: "HK$",
    description: "The Hong Kong dollar",
    imgPath: "/currencies/hkd.svg",
    decimalScale: 2,
  },
  {
    type: "Fiat",
    value: "JPY",
    symbol: "¥",
    description: "The Japanese yen",
    imgPath: "/currencies/jpy.svg",
    decimalScale: 2,
  },
  {
    type: "Fiat",
    value: "DKK",
    symbol: "kr.",
    description: "Danish krone",
    imgPath: "/currencies/dkk.svg",
    decimalScale: 2,
  },
  {
    type: "Fiat",
    value: "KWD",
    symbol: "KD",
    description: "Kuwaiti dinar",
    imgPath: "/currencies/kwd.svg",
    decimalScale: 2,
  },
  {
    type: "Crypto",
    value: "NEXM",
    symbol: "NEXM ",
    description: "",
    imgPath: "/currencies/nexm.svg",
    decimalScale: 8,
  },
  {
    type: "Crypto",
    value: "MIGG",
    symbol: "ᙢ",
    description: "",
    imgPath: "/currencies/migg.svg",
    decimalScale: 8,
  },
];

export const currenciesForDashboard: ICurrency[] = [
  {
    type: "Fiat",
    value: "EUR",
    symbol: "€",
    description: "European euro",
    imgPath: "/currencies/eur.svg",
    decimalScale: 2,
  },
  {
    type: "Fiat",
    value: "USD",
    symbol: "$",
    description: "United States dollar",
    imgPath: "/currencies/usd.svg",
    decimalScale: 2,
  },
  {
    type: "Crypto",
    value: "USDT",
    symbol: "₮",
    description: "Tether",
    imgPath: "/currencies/usdt.svg",
    decimalScale: 8,
  },
  {
    type: "Crypto",
    value: "BTC",
    symbol: "₿",
    description: "Bitcoin",
    imgPath: "/currencies/btc.svg",
    decimalScale: 8,
  },
  {
    type: "Fiat",
    value: "GBP",
    symbol: "£",
    description: "Pound sterling",
    imgPath: "/currencies/gbp.svg",
    decimalScale: 2,
  },
  {
    type: "Crypto",
    value: "USDC",
    symbol: "USDC ",
    description: "USD Coin",
    imgPath: "/currencies/usdc.svg",
    decimalScale: 8,
  },
  {
    type: "Fiat",
    value: "AED",
    symbol: "إ",
    description: "UAE dirham",
    imgPath: "/currencies/aed.svg",
    decimalScale: 2,
  },

  {
    type: "Crypto",
    value: "ETH",
    symbol: "Ξ",
    description: "Ethereum",
    imgPath: "/currencies/eth.svg",
    decimalScale: 8,
  },

  {
    type: "Fiat",
    value: "RUB",
    symbol: "₽",
    description: "Russian ruble",
    imgPath: "/currencies/rus.svg",
    decimalScale: 2,
  },
  {
    type: "Fiat",
    value: "CAD",
    symbol: "$",
    description: "Canadian dollar",
    imgPath: "/currencies/cad.svg",
    decimalScale: 2,
  },
  {
    type: "Fiat",
    value: "CHF",
    symbol: "₣",
    description: "Swiss franc",
    imgPath: "/currencies/chf.svg",
    decimalScale: 2,
  },
  {
    type: "Fiat",
    value: "HKD",
    symbol: "HK$",
    description: "The Hong Kong dollar",
    imgPath: "/currencies/hkd.svg",
    decimalScale: 2,
  },

  {
    type: "Fiat",
    value: "TRY",
    symbol: "₺",
    description: "Turkish lira",
    imgPath: "/currencies/try.svg",
    decimalScale: 2,
  },
  {
    type: "Fiat",
    value: "JPY",
    symbol: "¥",
    description: "The Japanese yen",
    imgPath: "/currencies/jpy.svg",
    decimalScale: 2,
  },
];

export const ro = (value: number) => {
  /* округление до ближайшего цента */

  const cents = value * 100;
  const roundedCents = Math.round(cents);
  return roundedCents / 100;
};

export const checkIsCryptoCurrency = (currency: string) => {
  return allCurrencies2.some((item) => {
    return item.value === currency && item.type === "Crypto";
  });
};

export const isStableCoin = (currencyId: string) =>
  currencyId === "USDC" || currencyId === "USDT";

export const formatToCurrencyString = (
  value?: number | string,
  currencyId?: string,
  clear?: boolean,
  average?: boolean,
  negative?: "sign" | "parenthesis",
  decimalScale?: number
) => {
  const currency = allCurrencies2.find((c) => c.value === currencyId);

  const fiatOptions: numbro.Format = {
    thousandSeparated: true,
    mantissa: decimalScale || currency?.decimalScale,
    trimMantissa: false,
  };

  const cryptoOptions: numbro.Format = {
    thousandSeparated: true,
    mantissa: decimalScale || currency?.decimalScale,
    trimMantissa: false,
  };

  const otherOptions: numbro.Format = {
    thousandSeparated: true,
    trimMantissa: false,
  };

  if (average) {
    fiatOptions.average = average;
    cryptoOptions.average = average;
  }

  if (negative) {
    fiatOptions.negative = negative;
    cryptoOptions.negative = negative;
  }

  if (clear) {
    delete fiatOptions.mantissa;
    delete cryptoOptions.mantissa;
  }

  const unformatted = numbro.unformat(value as string) || 0;
  const positive = Math.abs(unformatted);

  if (currency?.type === "Fiat") {
    const formatted = numbro(positive).format(fiatOptions);

    if (unformatted < 0) {
      return `-${currency.symbol}${formatted}`;
    }

    return `${currency.symbol}${formatted}`;
  }

  if (currency?.type === "Crypto") {
    const formatted = numbro(positive).format(cryptoOptions);

    if (unformatted < 0) {
      return `-${currency.symbol}${formatted}`;
    }

    return `${currency.symbol}${formatted}`;
  }

  const formatted = numbro(positive).format(otherOptions);

  if (unformatted < 0) {
    return `-${formatted}`;
  }

  return `${formatted}`;
};
