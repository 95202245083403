import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { view } from "@risingstack/react-easy-state";
import React from "react";
import auth from "../../../store/modules/auth";

const close = () => {
  auth.emailConfirmed = false;
};

const EmailConfirmed = view(() => {
  return (
    <Dialog open={auth.emailConfirmed}>
      <DialogTitle>Email Confirmed</DialogTitle>
      <DialogContent>
        <p>You have successfully verified your mail</p>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="primary" variant="contained" autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export default EmailConfirmed;
