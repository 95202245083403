import numbro from "numbro";
import { symbols } from "./formatToCurrencyString";

export const simpleFormatAmount = (
  value?: number,
  currencyId?: string,
  mantissa?: number
) => {
  const c = symbols[currencyId as string];

  if (!value) {
    return "-";
  }

  if (!mantissa) {
    return (c || "") + numbro(value || 0).format({ thousandSeparated: true });
  }

  return (
    (c || "") +
    numbro(value || 0).format({ thousandSeparated: true, mantissa: mantissa })
  );
};
