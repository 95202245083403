import { Button, Container, DialogActions } from "@material-ui/core";
import React, { useState } from "react";
import { formatToCurrencyString } from "../../../../../../helpers";
import Field from "../../../../../System/FormComponents/Field";
import Credit from "../SendWire/Credit";
import GreenButton from "./GreenButton";
import PreviewTable from "./PreviewTable";
import Sepa from "./Sepa";
import SepaBib from "./SepaBib";
import Sfox from "./Sfox";
import Swift from "./Swift";

const WrapCredit = ({ preview, status, task, form }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <a onClick={() => setOpen(true)}>Do you want to make credit?</a>

      <Credit
        open={open}
        setOpen={setOpen}
        task={task}
        successCallback={preview}
        status={status}
        parentForm={form}
      />
    </>
  );
};

const Preview = ({
  data,
  setStep,
  setOpen,
  confirm,
  preview,
  loading,
  hideConfirmButtons,
  dayId,
  task,
  form,
}: any) => {
  // const iDontWantToUseSEPA = useWatch({
  //   control: form?.control,
  //   name: "iDontWantToUseSEPA",
  // });

  if (!data) {
    return null;
  }

  const totalFees = (data.transaction.totalFees || []).map((item) =>
    formatToCurrencyString(item.value, item.currencyId)
  );

  const totalDeposits = (data.transaction.totalDeposits || []).map((item) =>
    formatToCurrencyString(item.value, item.currencyId)
  );

  const totalWithdraws = (data.transaction.totalWithdraws || []).map((item) =>
    formatToCurrencyString(item.value, item.currencyId)
  );

  const feeCalcDescription = data.transaction?.operation[0]?.feeCalcDescription;

  const showCreditLink = data?.status?.includes("desired amount");

  return (
    <div>
      <Container maxWidth="sm">
        {data?.transaction?.transactionId && (
          <Field
            className="text-lg"
            label="Transaction ID"
            value={<b>{data.transaction.transactionId}</b>}
          />
        )}

        {totalFees?.length > 0 && (
          <Field
            className="text-lg"
            label="Total fees"
            value={totalFees.join(", ")}
          />
        )}

        {feeCalcDescription && (
          <Field
            className="text-lg"
            label="Fee calc"
            value={feeCalcDescription}
          />
        )}

        {totalDeposits?.length > 0 && (
          <Field
            className="text-lg"
            label="Total deposits"
            value={totalDeposits.join(", ")}
          />
        )}

        {totalWithdraws?.length > 0 && (
          <Field
            className="text-lg"
            label="Total withdraws"
            value={totalWithdraws.join(", ")}
          />
        )}

        {dayId && (
          <Field className="text-lg" label="Transaction time" value={dayId} />
        )}

        <Field
          className="text-lg"
          label="This will generate"
          value={
            data?.transaction?.isPending ? (
              <span>
                <b>pending</b> transaction
              </span>
            ) : (
              <span>
                <b>real</b> transaction
              </span>
            )
          }
        />

        <Field
          className="text-lg"
          label="Negative balance"
          value={
            data?.request?.allowNegativeBalance ? (
              <b className="text-red-500">allowed</b>
            ) : (
              "not allowed"
            )
          }
        />

        {form && (
          <>
            <Sepa {...form} data={data} task={task} />
            <Swift {...form} data={data} />
            <Sfox {...form} data={data} task={task} />
            <SepaBib {...form} data={data} task={task} />
          </>
        )}
      </Container>

      <div className="h-4" />

      <PreviewTable data={data?.transaction?.operation} />

      <div className="h-2.5" />

      {data?.status && (
        <div className="flex flex-1 justify-end">
          <div className="flex flex-col">
            <b className="text-red-500 text-lg">{data.status}</b>
            <span>
              {showCreditLink && (
                <WrapCredit
                  task={task}
                  preview={preview}
                  status={data?.status}
                  form={form}
                />
              )}
            </span>
          </div>
          {/* {showCreditLink && (
            <MyCheckboxLabel
              {...form}
              name="iDontWantToUseSEPA"
              label="I don't want to use SEPA for this payment"
            />
          )} */}
        </div>
      )}

      {!hideConfirmButtons && (
        <DialogActions>
          {setStep && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => setStep(1)}
              disabled={loading}
            >
              Back
            </Button>
          )}

          <GreenButton
            onClick={confirm}
            disabled={loading || Boolean(data.status)}
            label="Confirm"
            loading={loading}
          />
        </DialogActions>
      )}

      {hideConfirmButtons && (
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpen(false)}
            disabled={loading}
          >
            Close
          </Button>
        </DialogActions>
      )}
    </div>
  );
};

export default Preview;
