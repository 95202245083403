import { InputAdornment, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { useController } from "react-hook-form";

const useStyles = makeStyles({
  helperText: {
    fontSize: ".75rem",
  },
});

const MyTextField = ({
  control,
  name,
  rules,
  helperText,
  disabled,
  label,
  fullWidth,
  endAdornment,
  rows,
  multiline,
  type,
  autoComplete,
  placeholder,
  onKeyPress,
  variant,
  autoFocus,
  useUpperCase,
  useTrim,
  InputProps,
  onPaste,
  onPasteCapture,
  required,
  inputProps,
  id,
}: any) => {
  const classes = useStyles();

  const { field } = useController({
    name: name,
    control: control,
    rules: rules,
  });

  const onChangeInput = (e) => {
    if (useTrim && e.target.value) {
      e.target.value = e.target.value.trim().split(" ").join("");
    }

    if (useUpperCase && e.target.value) {
      e.target.value = e.target.value.toUpperCase();
    }

    field.onChange(e);
  };

  return (
    <TextField
      id={id}
      name={field.name}
      value={field.value}
      onBlur={field.onBlur}
      inputRef={field.ref}
      required={required}
      onChange={onChangeInput}
      autoFocus={autoFocus}
      onKeyPress={onKeyPress}
      disabled={disabled}
      type={type || "text"}
      placeholder={placeholder}
      autoCapitalize="none"
      InputProps={
        InputProps
          ? InputProps
          : {
              inputProps: {
                autoComplete: autoComplete || "new-password",
                ...inputProps,
              },
              endAdornment: endAdornment ? (
                <InputAdornment style={{ height: "100%" }} position="end">
                  {endAdornment}
                </InputAdornment>
              ) : undefined,
            }
      }
      helperText={helperText}
      FormHelperTextProps={{
        className: classes.helperText,
      }}
      InputLabelProps={{
        shrink: true,
      }}
      label={label}
      fullWidth={fullWidth}
      rows={rows}
      multiline={multiline}
      variant={variant}
      onPaste={onPaste}
      onPasteCapture={onPasteCapture}
      autoComplete="off"
    />
  );
};

export default MyTextField;
