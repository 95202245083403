import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { view } from "@risingstack/react-easy-state";
import React from "react";
import auth from "../../../store/modules/auth";

const close = () => {
  auth.checkYourEmailResetPassword = false;
};

const CheckYourEmailResetPassword = view(() => {
  return (
    <Dialog open={auth.checkYourEmailResetPassword}>
      <DialogTitle>Forgot Password</DialogTitle>
      <DialogContent>
        <p>Check your email to reset your account password</p>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="primary" autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export default CheckYourEmailResetPassword;
