import React, { memo } from "react";
import CustomSimpleMenu from "../../../../../System/Components/CustomSimpleMenu";
import { useQueryBranches } from "../queries/useQueryBranches";

export default memo(({ params, setParams }: any) => {
  const branchesQuery = useQueryBranches();

  return (
    <div className="max-w-[8rem] w-full !mr-4 !mb-2">
      <CustomSimpleMenu
        label="Branches"
        value={params.branchId || ""}
        onChange={(e) =>
          setParams((prevState) => ({
            ...prevState,
            branchId: e.target.value,
            page: 1,
          }))
        }
        className="!mr-4"
        options={[
          {
            label: "All",
            value: "",
          },
          ...(branchesQuery?.data?.map((b) => ({
            label: b.name,
            value: b.parentUserId,
          })) || []),
        ]}
        fullWidth
      />
    </div>
  );
});
