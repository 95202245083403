import { green, grey, red, yellow } from "@material-ui/core/colors";
import { ArrowDownward, FiberManualRecord } from "@material-ui/icons";
import numbro from "numbro";
import React from "react";
import {
  formatDateToMMddyyyyHHmm,
  formatToCurrencyString,
} from "../../../../../../helpers";
import Box from "../components/Box";
import FieldBox from "../components/FieldBox";
import { getColorForState } from "../tools/getColorForState";
import CopyAccountNumber from "./../../../../../System/CopyAccountNumber";

const getColorForRisk = (risk) => {
  let color;

  if (risk === "Green") {
    color = green[400];
  }

  if (risk === "Red") {
    color = red[600];
  }

  if (risk === "Yellow") {
    color = yellow[600];
  }

  return color;
};

const CircularRisk = ({ risk }) => {
  const color = getColorForRisk(risk);

  if (risk === "Green") {
    return (
      <FiberManualRecord
        style={{
          fontSize: "1rem",
          color: color,
          marginLeft: 2,
        }}
      />
    );
  }

  if (risk === "Yellow") {
    return (
      <FiberManualRecord
        style={{
          fontSize: "1.2rem",
          color: color,
          marginLeft: 2,
        }}
      />
    );
  }

  if (risk === "Red") {
    return (
      <FiberManualRecord
        style={{
          fontSize: "1.5rem",
          color: color,
          marginLeft: 2,
        }}
      />
    );
  }

  return null;
};

const Transfer = ({ task }) => {
  const fromAccountColorRisk = getColorForRisk(
    task?.fromAccount?.ownerRiskLevelId
  );

  const toAccountColorRisk = getColorForRisk(task?.toAccount?.ownerRiskLevelId);

  const colorState = getColorForState(task);

  return (
    <>
      <Box header="">
        <FieldBox
          label="Task state"
          value={task?.taskStateName}
          style={{ color: colorState }}
        />

        <>
          {task?.taskStateId === "Rejected" && (
            <FieldBox label="Reason" value={task?.internalSubject} />
          )}
        </>

        <FieldBox label="Task ID" value={task?.taskId} showCopy />

        <FieldBox label="Date:" value={formatDateToMMddyyyyHHmm(task?.ts)} />

        <FieldBox label="Client:" value={task?.firstUser?.name} />
      </Box>

      <div
        className="text-xl mb-2 p-3 border-4 border-solid rounded-2xl flex flex-col"
        style={{ borderColor: `${fromAccountColorRisk}` }}
      >
        <div className="flex justify-between">
          <b>
            {task?.fromAccount?.ownerName} ({task?.fromAccount?.currencyId})
          </b>
          <div className="flex items-center">
            <span
              className={
                task?.toAccount?.ownerRiskLevelId === "GoldOnly"
                  ? "text-base"
                  : undefined
              }
            >
              {task?.fromAccount?.ownerRiskLevelId === "GoldOnly"
                ? "Client with incomplete verification"
                : task?.fromAccount?.ownerRiskLevelId}
            </span>{" "}
            <CircularRisk risk={task?.fromAccount?.ownerRiskLevelId} />{" "}
          </div>
        </div>

        <span className="text-sm">
          {task?.fromAccount?.number}
          <CopyAccountNumber number={task?.fromAccount?.number} />{" "}
        </span>
      </div>

      <div className="flex justify-center">
        <div className="flex items-center">
          <ArrowDownward
            style={{ fontSize: "4rem", marginRight: ".5rem", color: grey[400] }}
          />

          <span className="text-xl font-bold">
            {formatToCurrencyString(
              numbro.unformat(task?.amount || 0),
              task?.currencyId
            )}
          </span>
        </div>
      </div>

      <div
        className="text-xl mb-2 p-3 border-4 border-solid rounded-2xl flex flex-col"
        style={{ borderColor: `${toAccountColorRisk}` }}
      >
        <div className="flex justify-between">
          <b>
            {task?.toAccount?.ownerName} ({task?.toAccount?.currencyId})
          </b>

          <div className="flex items-center">
            <span
              className={
                task?.toAccount?.ownerRiskLevelId === "GoldOnly"
                  ? "text-base"
                  : undefined
              }
            >
              {task?.toAccount?.ownerRiskLevelId === "GoldOnly"
                ? "Client with incomplete verification"
                : task?.toAccount?.ownerRiskLevelId}
            </span>{" "}
            <CircularRisk risk={task?.toAccount?.ownerRiskLevelId} />
          </div>
        </div>

        <span className="text-sm">
          {task?.toAccount?.number}{" "}
          <CopyAccountNumber number={task?.toAccount?.number} />
        </span>
      </div>
    </>
  );
};

export default Transfer;
