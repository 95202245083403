import { Button, Dialog } from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { view } from "@risingstack/react-easy-state";
import React, { memo, useEffect, useState } from "react";
import { useTimer } from "../../hooks/useTimer";
import auth from "../../store/modules/auth";
import ui from "../../store/modules/ui";
import Anima from "../System/Anima/index";
import LazyImage from "../System/LazyImage";

const timeWarning = 3600000; // 60m
const timeSignOut = 60000; // 1m

const events = ["mousemove", "mousedown", "click", "scroll", "keypress"];

let timerWarning: number | null = null;
let timerSignOut: number | null = null;

const close = () => {
  ui.autoLogoutMs = -1;
};

const clearTimers = () => {
  if (timerWarning) {
    clearTimeout(timerWarning);
  }
  if (timerSignOut) {
    clearTimeout(timerSignOut);
  }
};

const logout = () => {
  sessionStorage.setItem("autoLogoutSuccess", "1");
  auth.logout();
};

const openWarning = () => {
  removeListeners();
  ui.autoLogoutMs = timeSignOut;

  timerSignOut = setTimeout(logout, timeSignOut);
};

const run = () => {
  clearTimers();
  timerWarning = setTimeout(openWarning, timeWarning);
};

const removeListeners = () => {
  clearTimers();
  events.forEach((item) => {
    removeEventListener(item, run);
  });
};

const addListeners = () =>
  events.forEach((item) => {
    addEventListener(item, run);
  });

const LazyAnima = memo(() => {
  return (
    <Anima json={"/keys/notice.json"} className="w-96 h-96 justify-center" />
  );
});

const AutoLogout = view(() => {
  const [seconds, setSeconds] = useState(ui.autoLogoutMs / 1000);

  const timer = useTimer({
    seconds: seconds,
    setSeconds: setSeconds,
  });

  const { profile } = auth;

  const { autoLogout } = ui;

  useEffect(() => {
    if (profile && autoLogout) {
      addListeners();
    }
    if (profile && !autoLogout) {
      removeListeners();
    }

    return () => removeListeners();
  }, [autoLogout, profile]);

  const autoLog = () => {
    auth.logout();
    close();
  };

  const staySignIn = () => {
    addListeners();
    close();
  };

  return (
    <Dialog
      classes={{ paper: "flex flex-col items-center p-12" }}
      open={ui.autoLogout && ui.autoLogoutMs >= 0 && Boolean(auth.profile)}
    >
      <LazyImage anima={LazyAnima} />

      <h2>Your session is about to end.</h2>
      <p>
        You&apos;ve been inactive for a while. For your security, we&apos;ll
        automatically sign you out in approximately 1 minutes. You may choose
        &quot;Stay signed in&quot; to continue or sign out if you&apos;re done.
      </p>

      <div className="flex">
        <Button
          onClick={autoLog}
          color="primary"
          variant="contained"
          style={{
            color: "#fff",
            fontSize: "1rem",
            border: 0,
            height: "auto",
            backgroundColor: "#017ac1",
            marginRight: "1rem",
          }}
          autoFocus
        >
          <span>Sign out </span>
          <b className="ml-1.5">{timer}</b>
        </Button>

        <Button
          onClick={staySignIn}
          color="primary"
          variant="contained"
          autoFocus
          style={{
            color: "#fff",
            fontSize: "1rem",
            border: 0,
            height: "auto",
            backgroundColor: green[600],
          }}
        >
          Stay signed in
        </Button>
      </div>
    </Dialog>
  );
});

export default AutoLogout;
