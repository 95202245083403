import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { view } from "@risingstack/react-easy-state";
import React from "react";
import auth from "../../../store/modules/auth";

const close = () => {
  auth.deviceConfirmed = false;
};

const DeviceConfirmed = view(() => {
  return (
    <Dialog open={auth.deviceConfirmed}>
      <DialogTitle>Device Confirmed</DialogTitle>
      <DialogContent>
        <p>You have successfully verified your device</p>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="primary" autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export default DeviceConfirmed;
