import { Button, DialogActions } from "@material-ui/core";
import { useSnackbar } from "notistack";
import numbro from "numbro";
import React, { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { formatToCurrencyString } from "../../../../../helpers";
import admin from "../../../../../store/modules/admin";
import { Alert } from "../../../../System";
import MyAmount from "../../../../System/FormComponents/MyAmount";
import MyCheckboxLabel from "../../../../System/FormComponents/MyCheckboxLabel";
import MyDate from "../../../../System/FormComponents/MyDate";
import MyTextField from "../../../../System/FormComponents/MyTextField";
import ShowAndHide from "../../../../System/FormComponents/ShowAndHide";
import GreenButton from "../components/GreenButton";
import Preview from "../components/Preview";
import Amount from "./components/Amount";
import ToAccount from "./components/ToAccount";
import ToUser from "./components/ToUser";
import { useGetAccounts } from "./hooks/useGetAccounts";

const defaultValues = {
  currencyId: "",
  amount: "",
  fixedFeeTo: "",
  fixedFeeFrom: "",

  toAccountId: "",
  toUser: undefined,

  additionalComment: "",

  transactionTime: null,
  allowNegativeBalance: false,
};

const TransferBetweenUsers = ({
  account,
  open,
  setOpen,
  refresh,
  toAccountId: parentToAccountId,
  toUser,
  amount,
  taskId,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [hideConfirmButtons, setHideConfirmButtons] = useState(false);
  const [previewData, setPreviewData] = useState();
  const [step, setStep] = useState(1);

  const form = useForm({
    mode: "onChange",
    defaultValues: defaultValues,
    shouldUnregister: false,
  });

  const { setValue, reset } = form;

  useEffect(() => {
    if (account && open) {
      setTimeout(() => setValue("currencyId", account.currencyId), 0);
    }
  }, [account, open, setValue]);

  useEffect(() => {
    if (toUser && open) {
      setTimeout(() => setValue("toUser", toUser), 0);
    }
  }, [toUser, open, setValue]);

  useEffect(() => {
    if (amount && open) {
      setTimeout(() => setValue("amount", amount), 0);
    }
  }, [amount, open, setValue]);

  useEffect(() => {
    if (parentToAccountId && open) {
      setValue("toAccountId", parentToAccountId);
    }
  }, [parentToAccountId, open, setValue]);

  useEffect(() => {
    if (!open) {
      setStep(1);
      setHideConfirmButtons(false);
      setPreviewData(undefined);
      reset();
    }
  }, [open, reset]);

  const { getToAccount } = useGetAccounts({ account, form });

  const toUserFromForm: any = useWatch({
    name: "toUser",
    control: form.control,
  });

  const preview = async (values) => {
    if (!values.toAccountId) {
      return;
    }

    setLoading(true);

    const toAccount = getToAccount(values.toAccountId);

    const payload: any = {
      isPending: values.isPendingTransaction,
      allowNegativeBalance: values.allowNegativeBalance,
      description: `Transfer from ${account.ownerName}, ${account.number}, ${values.amount} ${account.currencyId} to ${toAccount?.ownerName}, ${toAccount?.number}, ${values.amount} ${toAccount?.currencyId}`,
      operations: [],
      pendingTransactionId: taskId,
      transactionTime: values.transactionTime
        ? new Date(values.transactionTime).toISOString()
        : undefined,
    };

    const amount = numbro.unformat(values.amount) || 0;
    const fixedFeeTo = numbro.unformat(values.fixedFeeTo) || 0;
    const fixedFeeFrom = numbro.unformat(values.fixedFeeFrom) || 0;

    const minusComment = [
      `Transferred to ${toAccount?.currencyId} account ${toAccount?.number}`,
    ];

    if (values.additionalComment) {
      minusComment.push(values.additionalComment);
    }

    const minus: any = {
      accountId: account.accountId,
      currencyId: account.currencyId,
      amount: -amount,
      comment: minusComment.join(", "),
      fee: {
        titleId: "TOUSR",
        miscFee: values.fixedFeeTo ? fixedFeeTo : undefined,
        changeAmount: true,
      },
    };

    const plusComment = [
      `Received from ${account.currencyId} account ${account.number}`,
    ];

    if (values.additionalComment) {
      plusComment.push(values.additionalComment);
    }

    const plus: any = {
      accountId: toAccount?.accountId,
      currencyId: toAccount?.currencyId,
      amount: amount,
      comment: plusComment.join(", "),
      fee: {
        titleId: "FROMUSR",
        miscFee: values.fixedFeeFrom ? fixedFeeFrom : undefined,
        changeAmount: true,
      },
    };

    payload.operations.push(minus);
    payload.operations.push(plus);

    const [status, result] = await admin.preview(payload);

    if (status) {
      setPreviewData(result);
      setStep(2);
    }

    setLoading(false);
  };

  const confirm = async () => {
    setLoading(true);

    const result = await admin.confirm(previewData);

    if (result) {
      setHideConfirmButtons(true);

      enqueueSnackbar("Operation completed successfully", {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });

      if (refresh) {
        refresh();
      }
    }

    setLoading(false);
  };

  return (
    <Alert
      open={open}
      setOpen={setOpen}
      size={step === 1 ? "sm" : "md"}
      content={
        open && (
          <div>
            <h2 className="text-2xl font-bold mb-0">Send to Migom client</h2>

            <ShowAndHide show={step === 1}>
              <div className="flex flex-col mb-4 border-t-2 border-gray-200 border-b-2 py-1">
                <span className="text-lg">From user: {account.ownerName}</span>

                <span className="text-md">
                  From account: {account.currencyId} <b>{account.number}</b>
                </span>

                <span>
                  Available balance:{" "}
                  <b>
                    {formatToCurrencyString(
                      account.availableBalance,
                      account.currencyId
                    )}
                  </b>
                </span>
              </div>

              <div>
                <ToUser form={form} account={account} />
              </div>

              <div className="mb-2">
                <ToAccount form={form} account={account} />
              </div>

              <div className="mb-2">
                <Amount form={form} />
              </div>

              <div className="mb-2">
                <MyAmount
                  control={form.control}
                  controlCurrency={form.control}
                  name="fixedFeeTo"
                  label={`Fixed Fee to: ${account?.ownerName}`}
                  currencyName="currencyId"
                />
              </div>

              <div className="mb-2">
                <MyAmount
                  control={form.control}
                  controlCurrency={form.control}
                  name="fixedFeeFrom"
                  label={`Fixed Fee from: ${toUserFromForm?.name}`}
                  currencyName="currencyId"
                />
              </div>

              <div className="mb-2">
                <MyTextField
                  {...form}
                  label="Additional comment"
                  name="additionalComment"
                  fullWidth
                  multiline
                  rows={2}
                />
              </div>

              <div className="max-w-[160px] mb-2">
                <MyDate
                  {...form}
                  name="transactionTime"
                  label="Transaction date"
                />
              </div>

              <div className="mb-2">
                <MyCheckboxLabel
                  control={form.control}
                  name={"allowNegativeBalance"}
                  label="Allow negative balance"
                />
              </div>

              <DialogActions>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setOpen(false)}
                >
                  Close
                </Button>

                <GreenButton
                  onClick={form.handleSubmit(preview)}
                  label="Next"
                  loading={loading}
                  disabled={loading}
                />
              </DialogActions>
            </ShowAndHide>

            <ShowAndHide show={step === 2}>
              <Preview
                data={previewData}
                form={form}
                setStep={setStep}
                confirm={confirm}
                hideConfirmButtons={hideConfirmButtons}
                loading={loading}
                setOpen={setOpen}
              />
            </ShowAndHide>
          </div>
        )
      }
    />
  );
};

export default TransferBetweenUsers;
