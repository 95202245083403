import {
  HttpTransportType,
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
  LogLevel,
} from "@microsoft/signalr";
import { store } from "@risingstack/react-easy-state";
import ENDPOINTS from "../../endpoints";
import { getBase64 } from "../../helpers";
import IAccount from "../../interfaces/account";
import ITransaction from "../../interfaces/transaction";
import IUser from "../../interfaces/user";
import { get, patch, post, put, showError } from "../api";
import accounts from "./accounts";
import exchange from "./exchange";
import notifications from "./notifications";
import secureMessages from "./secureMessages";
import stocks from "./stocks";
import transactions from "./transactions";
import ui from "./ui";
import usersV2 from "./usersV2";

interface IAuth {
  loading: boolean;
  emailConfirmed: boolean;
  deviceConfirmed: boolean;
  checkYourEmailResetPassword: boolean;
  openDialogReSendEmail: boolean;
  profile?: IUser | false | null;
  notWorkingHubs: string[];
  hubNotification?: HubConnection;
  hubBalance?: HubConnection;
  hubTasks?: HubConnection;
  hubStocks?: HubConnection;
  hubConfig: signalR.IHttpConnectionOptions;
  showTerms: boolean;
  application: Record<string, any>;
  userLocate: Record<string, any>;
  loadingUserLocate: boolean;

  [key: string]: any;
}

const auth = store<IAuth>({
  loading: false,
  emailConfirmed: false,
  deviceConfirmed: false,
  checkYourEmailResetPassword: false,
  openDialogReSendEmail: false,
  profile: null,
  notWorkingHubs: [],
  hubNotification: undefined,
  hubBalance: undefined,
  hubTasks: undefined,
  hubStocks: undefined,
  hubConfig: {
    skipNegotiation: true,
    transport: HttpTransportType.WebSockets,
    accessTokenFactory() {
      return sessionStorage.getItem("token") as string;
    },
  },
  showTerms: false,

  application: {},
  userLocate: {},
  loadingUserLocate: false,
  bellCount: 0,

  async checkAccessToTheSection(section: string) {
    try {
      const headers: {
        Accept: boolean;
        "X-2FA-Section"?: string;
      } = {
        Accept: true,
        "X-2FA-Section": undefined,
      };

      if (section) {
        headers["X-2FA-Section"] = section;
      }

      const { data } = await post(
        ENDPOINTS.auth.checkAccessToTheSection,
        {},
        headers
      );

      if (data.code !== null) {
        sessionStorage.setItem("xt", data.code);
      }

      return [true];
    } catch (err: any) {
      return [false, err?.response?.data];
    }
  },

  async checkTwoFactorCode(code: string, section: string) {
    try {
      const headers: {
        Accept: boolean;
        "X-2FA-Access"?: string;
        "X-2FA-Section"?: string;
      } = {
        Accept: true,
        "X-2FA-Access": undefined,
        "X-2FA-Section": undefined,
      };

      if (code) {
        headers["X-2FA-Access"] = code;
      }

      if (section) {
        headers["X-2FA-Section"] = section;
      }

      const { data } = await post(
        ENDPOINTS.auth.checkAccessToTheSection,
        {},
        headers
      );

      if (data.code !== null) {
        sessionStorage.setItem("xt", data.code);
      } else {
        sessionStorage.setItem("xt", code);
      }

      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },
  async getApp(phoneNumber: string | undefined) {
    try {
      await post(ENDPOINTS.auth.getApp, { phoneNumber });
      return true;
    } catch (err) {
      return false;
    }
  },
  async enableTwoFactor() {
    try {
      const { data } = await post(ENDPOINTS.auth.enableTwoFactor);
      auth.profile = { ...auth.profile, ...data };

      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },
  async disableTwoFactor() {
    try {
      const { data } = await post(ENDPOINTS.auth.disableTwoFactor);
      auth.profile = { ...auth.profile, ...data };
      sessionStorage.removeItem("xt");
      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },
  async disableGoogleAuthentication() {
    try {
      const result = await post(ENDPOINTS.auth.disableGoogleAuthentication);
      if (result && auth.profile) {
        auth.profile.twoFactorAuthenticatorEnabled =
          result.data.twoFactorAuthenticatorEnabled;
      }

      return [true];
    } catch (err: any) {
      showError(err);
      return [false, err?.response?.data];
    }
  },

  async generateGoogleAuthentication() {
    try {
      const res = await post(ENDPOINTS.auth.generateGoogleAuthentication);

      return res.data;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async enableGoogleAuthentication({
    payload,
  }: {
    payload: Record<string, any>;
  }) {
    try {
      const result = await post(
        ENDPOINTS.auth.enableGoogleAuthentication,
        payload
      );
      if (result && auth.profile) {
        auth.profile.twoFactorAuthenticatorEnabled =
          result.data.twoFactorAuthenticatorEnabled;
      }
      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },
  async sendTwoFactorCode(provider: string, section: string) {
    try {
      const headers: {
        Accept: boolean;
        "X-2FA-Section"?: string;
      } = {
        Accept: true,
        "X-2FA-Section": undefined,
      };

      if (section) {
        headers["X-2FA-Section"] = section;
      }

      await post(
        ENDPOINTS.auth.sendTwoFactorCode.replace("{provider}", provider),
        undefined,
        headers
      );
      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async signUp(payload: Record<string, any> | undefined) {
    try {
      const { data } = await post(ENDPOINTS.auth.signUp, payload);

      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async signUpCustomer(payload: Record<string, any> | undefined) {
    try {
      await post(ENDPOINTS.auth.registerCustomer, payload);

      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async acceptTerms() {
    try {
      await post(ENDPOINTS.auth.acceptTerms);

      await auth.auth();

      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },
  async authByUserId(userId: string) {
    try {
      const res = await post(
        ENDPOINTS.auth.authByUserId.replace("{userId}", userId)
      );

      return res?.data?.bearer;
    } catch (err) {
      showError(err);
      return false;
    }
  },
  async signIn(dataSignIn: {
    [x: string]: any;
    email: any;
    password: any;
    code?: any;
  }) {
    auth.profile = null;
    sessionStorage.removeItem("xt");

    try {
      const { email, password, code, ...rest } = dataSignIn;

      const payload = { email, password, ...rest };

      const headers = { Accept: true, "X-2FA-Access": undefined };

      if (code) {
        headers["X-2FA-Access"] = code;
      }

      const { data } = await get(ENDPOINTS.blocks.getDate);

      const res = await post(ENDPOINTS.auth.login, payload, headers);

      if (
        res?.data?.onBoardStatus !== "Completed" &&
        res?.data?.onBoardStatus !== "Rejected" &&
        res?.data?.onBoardStatus !== "None" &&
        res?.data?.onBoardStatus !== "AwaitingOfClerk" &&
        res?.data?.onBoardStatus !== "AwaitingOfController" &&
        res?.data?.onBoardStatus !== "ReturnedToClerk"
      ) {
        ui.openDialogAccessDenied = true;
        return [false];
      }

      localStorage.setItem("autoLogout", "1");

      if (code) {
        sessionStorage.setItem("xt", code);
      }

      if (res.data.bearer) {
        sessionStorage.setItem("token", res.data.bearer);
      }

      if (res.data?.deviceId) {
        localStorage.setItem("deviceId", res.data.deviceId);
      }

      const d1 = res.data.acceptedTermsOfUseDate
        ? new Date(res.data.acceptedTermsOfUseDate).getTime()
        : null;

      const d2 = data.date ? new Date(data.date).getTime() : null;

      if ((d1 && d2 && d1 < d2) || !d1) {
        auth.showTerms = true;

        return [false];
      }

      auth.init(res.data);

      return [true];
    } catch (err: any) {
      showError(err);
      return [false, err?.response?.data];
    }
  },
  init(data: IUser) {
    const rolesIsNotEmpty = (data?.roles?.length || 0) > 0;

    auth.initialUser(data);

    if (rolesIsNotEmpty && auth?.profile && auth?.profile?.avatarId) {
      auth.getAvatar(auth.profile.avatarId);
    }

    if (
      !auth.hubNotification ||
      auth.hubNotification?.state === HubConnectionState.Disconnected
    ) {
      auth.connectToHubNotification();
    }

    auth.connectToHubBalance();

    if (
      auth?.profile &&
      (auth?.profile?.isClient || auth?.profile?.isReadOnly) &&
      data?.onBoardStatus === "Completed"
    ) {
      accounts.fetchAccounts();
    }
  },

  setRoles(user: IUser) {
    if (!auth.profile) {
      return;
    }

    auth.profile.isCorporate = user?.roles?.includes("Corporate");
    auth.profile.isAdmin = user?.roles?.includes("Admin");
    auth.profile.isNewsAdmin = user?.roles?.includes("NewsAdmin");
    auth.profile.isOperator = user?.roles?.includes("Operator");
    auth.profile.isController = user?.roles?.includes("Controller");
    auth.profile.isClerk = user?.roles?.includes("Clerk");
    auth.profile.isClient = user?.roles?.includes("Client");
    auth.profile.isRegulator = user?.roles?.includes("Regulator");
    auth.profile.isSupportAgent = user?.roles?.includes("SupportAgent");
    auth.profile.isBranchAdmin = user?.roles?.includes("BranchAdmin");
    auth.profile.canLoginAsUser = user?.roles?.includes("CanLoginAsUser");
    auth.profile.canPatchUserState = user?.roles?.includes("CanPatchUserState");
    auth.profile.isReadOnly = user?.roles?.includes("ReadOnlyClient");
    auth.profile.isTreasurer = user?.roles?.includes("Treasurer");
    auth.profile.isCardAdmin = user?.roles?.includes("CardAdmin");
    auth.profile.isAnalyst = user?.roles?.includes("Analyst");
    auth.profile.isSecureMessages = user?.roles?.includes("SecureMessageAdmin");
    auth.profile.isTeller = user?.roles?.includes("Teller");
    auth.profile.isAppVersionUpdater =
      user?.roles?.includes("AppVersionUpdater");
  },

  initAutoLogout() {
    const autoLogout = localStorage.getItem("autoLogout");

    if (!autoLogout) {
      localStorage.setItem("autoLogout", "1");
      ui.autoLogout = true;
    }
    if (autoLogout === "0") {
      localStorage.setItem("autoLogout", "0");
      ui.autoLogout = false;
    }
    if (autoLogout === "1") {
      localStorage.setItem("autoLogout", "1");
      ui.autoLogout = true;
    }
  },

  initialUser(user: IUser) {
    auth.profile = user;

    if (user.clientType === "Corporate" && !user.clientApproved) {
      ui.showFiles = true;
    }

    auth.setRoles(user);

    auth.profile.waitForCorporate =
      user?.onBoardStatus === "AwaitingOfClerk" ||
      user?.onBoardStatus === "AwaitingOfController";

    auth.profile.isMerchant = user.isMerchant;
    auth.profile.merchantWebsite = user.merchantWebsite;

    auth.profile.twoFactorAuthenticatorEnabled =
      user.twoFactorAuthenticatorEnabled;

    auth.initAutoLogout();
  },
  async fetchAccessToApplication() {
    try {
      const { data } = await get(ENDPOINTS.auth.application, {});

      auth.application = data;
      auth.connectToHubNotification();

      return data;
    } catch (err) {
      showError(err);
    }
  },
  async completeApplication(payload: Record<string, any> | undefined) {
    try {
      const { data } = await post(ENDPOINTS.auth.completeApplication, payload);

      return data;
    } catch (err) {
      showError(err);

      return false;
    }
  },
  async auth() {
    try {
      const { data } = await post(ENDPOINTS.auth.auth, {});

      auth.init(data);

      return data;
    } catch (err) {
      auth.profile = false;
      showError(err);
    }
  },
  async simpleAuth() {
    try {
      const { data } = await post(ENDPOINTS.auth.auth, {});

      auth.initialUser(data);

      if (auth?.profile && auth.profile?.avatarId) {
        auth.getAvatar(auth.profile?.avatarId);
      }
    } catch (err) {
      showError(err);
      auth.profile = false;
    }
  },
  async reSendConfirmationEmail(payload: Record<string, any>) {
    try {
      await post(ENDPOINTS.auth.reSendConfirmationEmail, payload);

      auth.openDialogReSendEmail = false;
      return true;
    } catch (err) {
      showError(err);

      return false;
    }
  },
  async connectToHubBalance() {
    auth.hubBalance = await new HubConnectionBuilder()
      .configureLogging(LogLevel.Error)
      .withUrl(import.meta.env.VITE_WSS_BALANCE as string, auth.hubConfig)
      .build();

    auth.startHubBalance();
  },
  async startHubBalance() {
    try {
      if (!auth.hubBalance) {
        return;
      }

      await auth.hubBalance.start();

      auth.notWorkingHubs = auth.notWorkingHubs.filter(
        (item) => item !== import.meta.env.VITE_WSS_BALANCE
      );

      auth.hubBalance.on("onBalanceUpdated", auth.onBalanceUpdated);
      return true;
    } catch (err) {
      if (
        !auth.notWorkingHubs.includes(
          import.meta.env.VITE_WSS_BALANCE as string
        )
      ) {
        auth.notWorkingHubs = [
          ...auth.notWorkingHubs,
          import.meta.env.VITE_WSS_BALANCE as string,
        ];
      }

      setTimeout(auth.startHubBalance, 6000);
      return false;
    }
  },
  async connectToHubStocks() {
    auth.hubStocks = await new HubConnectionBuilder()
      .configureLogging(LogLevel.Error)
      .withUrl(`${import.meta.env.VITE_WSS_API}/stocks`, auth.hubConfig)
      .build();

    auth.startHubStocks();
  },
  async startHubStocks() {
    try {
      if (!auth.hubStocks) {
        return;
      }

      await auth.hubStocks.start();

      auth.notWorkingHubs = auth.notWorkingHubs.filter(
        (item) => item !== `${import.meta.env.VITE_WSS_API}/stocks`
      );

      auth.hubStocks.on("OnTrade", auth.onStocks);

      return true;
    } catch (err) {
      if (
        !auth.notWorkingHubs.includes(
          `${import.meta.env.VITE_WSS_API}/stocks` as never
        )
      ) {
        auth.notWorkingHubs = [
          ...auth.notWorkingHubs,
          `${import.meta.env.VITE_WSS_API}/stocks` as never,
        ];
      }

      setTimeout(auth.startHubStocks, 6000);

      return false;
    }
  },
  onStocks(data: { symbol: string; changed: boolean }) {
    if (data && data?.symbol) {
      stocks.items = stocks.items.map((stock) => {
        if (stock.symbol === data.symbol) {
          data.changed = true;
          return data;
        }
        return stock;
      });
    }
  },
  onBalanceUpdated(data: { accountId?: any }) {
    const findExchange = exchange.userAccounts.find((a) => {
      return a.accountId === data.accountId;
    });

    if (
      findExchange &&
      exchange.selectFromAccount &&
      exchange.selectToAccount &&
      exchange.selectFromAccount.accountId &&
      exchange.selectToAccount.accountId
    ) {
      exchange.userAccounts = exchange.userAccounts.map((item) => {
        let newItem = item;

        if (item.accountId === data.accountId) {
          newItem = { ...newItem, ...data };
        }

        return newItem;
      });
    }

    if (
      exchange.selectFromAccount &&
      exchange.selectFromAccount.accountId &&
      exchange.selectFromAccount.accountId === data.accountId
    ) {
      exchange.selectFromAccount = {
        ...exchange.selectFromAccount,
        ...data,
      };
    }

    if (
      exchange.selectToAccount &&
      exchange.selectToAccount.accountId &&
      exchange.selectToAccount.accountId === data.accountId
    ) {
      exchange.selectToAccount = { ...exchange.selectToAccount, ...data };
    }

    const findAccountInItems = accounts.items.find(
      (a) => a.accountId === data.accountId
    );

    if (findAccountInItems) {
      accounts.items = accounts.items.map((item) => {
        let newItem = item;

        if (item.accountId === data.accountId) {
          newItem = { ...newItem, ...data };
        }

        return newItem;
      });
    }

    if (
      accounts.account?.accountId &&
      data.accountId === accounts.account?.accountId
    ) {
      accounts.account = {
        ...accounts.account,
        ...data,
      };
    }
  },
  async connectToHubNotification() {
    auth.hubNotification = await new HubConnectionBuilder()
      .configureLogging(LogLevel.Error)
      .withUrl(
        `${import.meta.env.VITE_WSS_API}/hubNotification`,
        auth.hubConfig
      )
      .build();

    auth.startHubNotification();
  },
  onRobotError(data) {
    ui.robotStatus = data?.message;
  },
  async startHubNotification() {
    try {
      if (!auth.hubNotification) {
        return;
      }
      await auth.hubNotification.start();

      auth.notWorkingHubs = auth.notWorkingHubs.filter(
        (item) => item !== `${import.meta.env.VITE_WSS_API}/hubNotification`
      );

      auth.hubNotification.on("onRobotError", auth.onRobotError);
      auth.hubNotification.on("onAccount", auth.onAccount);
      auth.hubNotification.on("onAccountRemoved", auth.onAccountRemoved);
      auth.hubNotification.on("onTransaction", auth.onTransaction);
      auth.hubNotification.on("onUserProfile", auth.onUserProfile);
      auth.hubNotification.on("onUserRoles", auth.onUserRoles);
      auth.hubNotification.on("onNotification", auth.onNotification);
      auth.hubNotification.on("onConfirmedStatus", auth.onConfirmedStatus);
      auth.hubNotification.on("onApplicationStatus", auth.onApplicationStatus);
      auth.hubNotification.on("onInbox", auth.onInbox);

      auth.hubNotification.on("onNotification", auth.onNotification);
      auth.hubNotification.on("onTaskChanged", auth.onTaskChanged);
    } catch (err) {
      if (
        !auth.notWorkingHubs.includes(
          `${import.meta.env.VITE_WSS_API}/hubNotification`
        )
      ) {
        auth.notWorkingHubs = [
          ...auth.notWorkingHubs,
          `${import.meta.env.VITE_WSS_API}/hubNotification`,
        ];
      }

      setTimeout(auth.startHubNotification, 6000);
    }
  },

  onBellCount(numb) {
    auth.bellCount = numb;
  },

  onTaskChanged(data) {
    auth.onBellCount(data?.bellCount);
  },
  onInbox(obj: { id: any }) {
    secureMessages.newMessagesInInbox.push(obj);

    const index = secureMessages.itemsInbox.findIndex(
      (item) => item.id === obj.id
    );

    if (index > -1) {
      secureMessages.itemsInbox[index] = obj;
      secureMessages.itemsInbox = [...secureMessages.itemsInbox];
    } else {
      secureMessages.itemsInbox = secureMessages.itemsInbox.filter(
        (item, index) => index !== secureMessages.itemsInbox?.length - 1
      );
      secureMessages.itemsInbox.unshift(obj);
    }
  },

  onTalkMessage(callback: any) {
    if (!auth.hubNotification) {
      return;
    }
    auth.hubNotification.on("onTalk", callback);
  },

  subscribeOnTalk(notificationId: any) {
    if (!auth.hubNotification) {
      return;
    }
    auth.hubNotification.send("SubscribeOnTalk", notificationId);
  },

  unSubscribeOnTalk(notificationId: any) {
    if (!auth.hubNotification) {
      return;
    }
    auth.hubNotification.send("UnsubscribeFromTalk", notificationId);
  },

  subSecureMessage(threadId: any) {
    if (!auth.hubNotification) {
      return;
    }
    auth.hubNotification.send("Subscribe", threadId);
  },

  unsubSecureMessage(threadId: any) {
    if (!auth.hubNotification) {
      return;
    }
    auth.hubNotification.send("Unsubscribe", threadId);
  },

  onApplicationStatus(data: Record<string, any>) {
    auth.application = data;
  },

  onConfirmedStatus(data: Record<string, any>) {
    if (!auth.profile) {
      return;
    }
    auth.profile = { ...(auth.profile || {}), ...data };
  },
  onNotification(data: {
    id: any;
    status: any;
    answerTs: any;
    answerId: any;
    previousAnswerId: any;
  }) {
    if (notifications?.notification?.id === data?.id) {
      notifications.notification = {
        ...notifications.notification,
        status: data?.status,
        answerTs: data?.answerTs,
        answerId: data?.answerId,
        previousAnswerId: data?.previousAnswerId,
      };
    }
  },
  onUserRoles(data: any) {
    if (!auth.profile) {
      return;
    }

    auth.profile.roles = data;
  },
  onUserProfile(data: any) {
    if (!auth.profile) {
      return;
    }

    auth.profile = { ...auth.profile, ...data };
  },
  onAccount(data: IAccount) {
    const findItem = accounts.items.find((a) => {
      return a.accountId === data.accountId;
    });

    if (
      exchange.selectFromAccount &&
      exchange.selectFromAccount.accountId &&
      exchange.selectFromAccount.accountId === data.accountId
    ) {
      exchange.selectFromAccount = {
        ...exchange.selectFromAccount,
        ...data,
      };
    }

    if (
      exchange.selectToAccount &&
      exchange.selectToAccount.accountId &&
      exchange.selectToAccount.accountId === data.accountId
    ) {
      exchange.selectToAccount = { ...exchange.selectToAccount, ...data };
    }

    if (findItem) {
      accounts.items = accounts.items.map((item) => {
        let newItem = item;
        if (item.accountId === data.accountId) {
          newItem = { ...newItem, ...data };
        }
        return newItem;
      });

      if (
        Boolean(data?.accountId) &&
        Boolean(accounts?.account?.accountId) &&
        data?.accountId === accounts?.account?.accountId
      ) {
        accounts.account = { ...data };
      }
    } else {
      accounts.items.push(data);
    }
  },
  onAccountRemoved(data: { accountId: any }) {
    const findIndex = accounts.items.findIndex((a) => {
      return a.accountId === data.accountId;
    });

    if (findIndex > -1) {
      accounts.items.splice(findIndex, 1);
    }
  },
  onTransaction(item: ITransaction) {
    const { items } = transactions;

    const findIndex = items.findIndex(
      (i) => i.referenceCode === item.referenceCode
    );

    const { account } = accounts;

    if (account && item.id === account.accountId) {
      if (findIndex > -1) {
        transactions.items = transactions.items.map((i, index) => {
          if (index === findIndex) {
            return item;
          }
          return i;
        });
      } else {
        transactions.items.unshift(item);
      }
    }
  },

  async changePassword(payload: Record<string, any> | undefined) {
    try {
      await post(ENDPOINTS.auth.passwordChange, payload);
      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },
  async confirmEmail(payload: Record<string, any> | undefined) {
    try {
      await post(ENDPOINTS.auth.confirmEmail, payload);
      return true;
    } catch (err: any) {
      if (err?.response?.data?.code === 133) {
        auth.openDialogReSendEmail = true;
      } else {
        showError(err);
      }

      return false;
    }
  },
  async merchantWebhook(payload: Record<string, any> | undefined) {
    try {
      await put(ENDPOINTS.auth.merchantWebhook, payload);

      if (auth.profile && payload?.merchantWebhook) {
        auth.profile.merchantWebhook = payload.merchantWebhook;
      }

      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },
  async confirmDevice(payload: Record<string, any> | undefined) {
    try {
      await post(ENDPOINTS.auth.confirmDevice, payload);

      return true;
    } catch (err) {
      showError(err);

      return false;
    }
  },
  async resetPassword(payload: Record<string, any> | undefined) {
    try {
      await post(ENDPOINTS.auth.resetPassword, payload);

      return true;
    } catch (err) {
      showError(err);

      return false;
    }
  },
  async forgotPassword(payload: Record<string, any> | undefined) {
    try {
      await post(ENDPOINTS.auth.forgotPassword, payload);

      return true;
    } catch (err) {
      showError(err);

      return false;
    }
  },
  async changePhone(phoneNumber: string | undefined) {
    try {
      await post(ENDPOINTS.auth.changePhone, { phoneNumber });

      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async sumSumSendVerifyCode() {
    try {
      await post(ENDPOINTS.auth.sumSumSendVerifyCode);

      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async verifyPhone(phoneNumber: string | undefined, code: string) {
    try {
      await post(ENDPOINTS.auth.verifyPhone, { phoneNumber, code });

      return true;
    } catch (err) {
      return false;
    }
  },

  async sumSubVerifyCode(code: string) {
    try {
      await post(ENDPOINTS.auth.sumSubVerifyCode, { code });

      return true;
    } catch (err) {
      return false;
    }
  },

  async getAvatar(avatarFileId: string) {
    try {
      const response = await get(
        ENDPOINTS.auth.getAvatar.replace("{avatarFileId}", avatarFileId),
        undefined,
        "arraybuffer"
      );

      if (response.data) {
        if (!auth.profile) {
          return;
        }

        if (response?.data?.byteLength > 0) {
          auth.profile = { ...auth.profile, avatar: getBase64(response) };
          return;
        }
        auth.profile = { ...auth.profile, avatar: "/images/pack/friend.svg" };
      }
    } catch (err) {
      showError(err);
    }
  },
  async uploadAvatar(file: string | Blob) {
    try {
      const formData = new FormData();
      formData.append("file", file);
      await post(ENDPOINTS.auth.uploadAvatar, formData, {
        "Content-Type": "multipart/form-data",
        Accept: true,
      });

      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  logout() {
    usersV2.clearState();

    accounts.items = [];

    sessionStorage.removeItem("token");
    sessionStorage.removeItem("xt");

    if (auth?.hubNotification && auth?.hubNotification?.stop) {
      auth.hubNotification.stop();
    }

    location.replace("/");
  },

  async fetchStatement(accountId: string, year: any, month: any) {
    try {
      const params = {
        year,
        month,
      };

      const { data, headers } = await get(
        ENDPOINTS.statements.item.replace("{accountId}", accountId),
        params,
        "arraybuffer"
      );

      const contentType = headers["content-type"];

      const file = new Blob([data], { type: contentType });
      const fileURL = URL.createObjectURL(file);

      open(fileURL, "_blank");
    } catch (err) {
      showError(err);
    }
  },

  async downloadStatement(accountId: string, year: any, month: any, account) {
    try {
      const params = {
        year,
        month,
      };

      const { data, headers } = await get(
        ENDPOINTS.statements.item.replace("{accountId}", accountId),
        params,
        "arraybuffer"
      );

      const contentType = headers["content-type"];

      const file = new Blob([data], { type: contentType });

      const link = document.createElement("a");

      link.href = URL.createObjectURL(file);
      link.download = `${account?.currencyId || ""}-${month}-${year}.pdf`;

      link.click();
      link.remove();
    } catch (err) {
      showError(err);
    }
  },
  async fetchStatements(accountId: string, exportCsv: boolean) {
    try {
      const { data } = await get(
        ENDPOINTS.statements.items.replace("{accountId}", accountId),
        {
          page: 1,
          perPage: 100,
        },
        exportCsv ? "blob" : undefined,
        {
          Accept: exportCsv ? "text/csv" : "application/json",
        }
      );

      if (exportCsv) {
        const blob = data;
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "statements.csv";
        link.click();
      }

      return exportCsv ? true : data.data;
    } catch (err) {
      showError(err);
      return false;
    }
  },
  async onBoardType(clientType: string) {
    try {
      await post(ENDPOINTS.auth.onBoardType, { clientType });

      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async fetchIplocator() {
    try {
      auth.loadingUserLocate = true;

      const { data } = await get(ENDPOINTS.auth.fetchIplocator);

      auth.userLocate = data;

      auth.loadingUserLocate = false;
      return data;
    } catch (err) {
      showError(err);
      auth.loadingUserLocate = false;
      return false;
    }
  },

  async validatePhone(phone: string) {
    try {
      const { data } = await get(ENDPOINTS.auth.validatePhone, { phone });

      return data;
    } catch (err: any) {
      return err?.response?.data;
    }
  },

  async validateBtcWallet(btc: string) {
    try {
      const { data } = await get(
        ENDPOINTS.auth.validateBtcWallet.replace("{btc}", btc)
      );

      if (data?.isValid) {
        return [true, data?.reason];
      }

      return [false, data?.reason];
    } catch (err: any) {
      return [false, err?.response?.data?.message];
    }
  },

  async fetchApplicationAddress() {
    try {
      const { data } = await get(ENDPOINTS.auth.fetchApplicationAddress);

      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async changeApplicationAddress(payload: Record<string, any> | undefined) {
    try {
      await patch(ENDPOINTS.auth.changeApplicationAddress, payload);

      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async checkVerifyCodeEmail(payload: Record<string, any> | undefined) {
    try {
      await post(ENDPOINTS.auth.checkVerifyCodeEmail, payload);

      return true;
    } catch (err: any) {
      return false;
    }
  },

  async sendVerifyCodeEmail(payload: Record<string, any> | undefined) {
    try {
      await post(ENDPOINTS.auth.sendVerifyCodeEmail, payload);

      return true;
    } catch (err: any) {
      return false;
    }
  },

  async checkVerifyCodeEmailWithoutPassword(
    payload: Record<string, any> | undefined
  ) {
    try {
      await post(
        `${import.meta.env.VITE_ACCOUNT}/v1/account/email/fast/verify-code`,
        payload
      );

      return true;
    } catch (err: any) {
      return false;
    }
  },

  async sendVerifyCodeEmailWithoutPassword(
    payload: Record<string, any> | undefined
  ) {
    try {
      await post(
        `${
          import.meta.env.VITE_ACCOUNT
        }/v1/account/email/fast/verify-code/send`,
        payload
      );

      return true;
    } catch (err: any) {
      return false;
    }
  },
});

export default auth;
