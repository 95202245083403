import { useQuery } from "react-query";
import { get } from "../../../../../../store/api";

export const useQueryBranches = () => {
  return useQuery(
    ["useQueryBranches"],
    async () => {
      try {
        // await new Promise((resolve: any) => {
        //   setTimeout(() => resolve(), 3000);
        // });

        const { data } = await get(
          `${import.meta.env.VITE_API}/v1/admin/parents`,
          {
            page: 1,
            perPage: 9999,
          }
        );

        return data?.data || [];
      } catch (err) {
        return;
      }
    },
    {
      // keepPreviousData: false,
      // staleTime: 300000,
      // refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
      keepPreviousData: true,
    }
  );
};
