import React, { useState } from "react";
import { Alert } from "../../../../../System";
import Picture from "../../../../Admin/Client/OnBoard/components/Picture";
import Box from "./../components/Box";

const Attachments = ({ files }) => {
  const [image, setImage] = useState(false);

  const mapFiles = (files || [])?.map((file) => {
    return (
      <div key={file?.fileId}>
        <Picture {...file} setImage={setImage} disableSetFile />
      </div>
    );
  });

  return (
    <Box header="Files" hideBottomLine>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">{mapFiles}</div>

      <Alert
        size="xl"
        open={Boolean(image)}
        setOpen={() => setImage(false)}
        content={
          <div className="w-full flex justify-center items-center">
            <img src={image as any} className="max-w-full" />
          </div>
        }
      />
    </Box>
  );
};

export default Attachments;
