import { Button, DialogActions } from "@material-ui/core";
import { ArrowDownward } from "@material-ui/icons";
import { view } from "@risingstack/react-easy-state";
import { useSnackbar } from "notistack";
import numbro from "numbro";
import React, { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { formatToCurrencyString } from "../../../../../../helpers";
import { getTaskStateBooleans } from "../../../../../../helpers/task";
import admin from "../../../../../../store/modules/admin";
import auth from "../../../../../../store/modules/auth";
import { Alert, AreYouSure } from "../../../../../System";
import CustomRadioButtons from "../../../../../System/Components/CustomRadioButtons";
import { Attention } from "../../../../../System/Design/Animations";
import MyAmount from "../../../../../System/FormComponents/MyAmount";
import MySimpleMenu from "../../../../../System/FormComponents/MySimpleMenu";
import ShowAndHide from "../../../../../System/FormComponents/ShowAndHide";
import { makeDepositFeeTypes } from "../../../../Admin/SmartForms/Deposit/tools";
import AdvancedOptions from "../components/AdvancedOptions";
import AdvancedOptionsPart2 from "../components/AdvancedOptionsPart2";
import Amount from "../components/Amount";
import Comment from "../components/Comment";
import FixedOurCost from "../components/FixedOurCost";
import GreenButton from "../components/GreenButton";
import InitFee from "../components/InitFee";
import Preview from "../components/Preview";
import ShowAdvancedOptions from "../components/ShowAdvancedOptions";
import Title from "../components/Title";
import TitleContent from "../components/TitleContent";

const defaultValues = {
  currencyId: "",
  amount: "",
  feeType: "",
  comment: "Debit Counterparty:",
  fixedOurCost: "",
  percentageOurCost: "",
  usePercentage: true,
  showAdvancedOptions: false,
  fixedFee: "",
  transactionTime: null,
  feeStructure: null,
  feeRule: null,
  isPendingTransaction: false,
  feeInsideAmount: true,
  allowNegativeBalance: false,
  useSepa: false,
};

const WrapActions = ({
  handleSubmit,
  preview,
  loading,
  control,
  setOpen,
  body,
}) => {
  const [openWarning, setOpenWarning] = useState(false);

  const amount = useWatch({
    control: control,
    name: "amount",
  });

  const currencyId = useWatch({
    control: control,
    name: "currencyId",
  });

  const unformattedAmount = numbro.unformat(amount) || 0;

  return (
    <DialogActions>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setOpen(false)}
        disabled={loading}
      >
        Close
      </Button>

      <GreenButton
        onClick={
          body && body?.Amount !== unformattedAmount
            ? () => setOpenWarning(true)
            : handleSubmit(preview)
        }
        label="Next"
        loading={loading}
        disabled={loading}
      />

      <AreYouSure
        open={openWarning}
        setOpen={setOpenWarning}
        customIcon={() => <Attention />}
        confirmCallback={() => {
          setOpenWarning(false);
          handleSubmit(preview)();
        }}
        loading={loading}
        disabledConfirmCallback={loading}
        customText={
          <div className="flex flex-col mt-4">
            <span>
              Client requested to deposit:{" "}
              <b>{formatToCurrencyString(body?.Amount, currencyId)}</b>
            </span>
            <span>
              You about to deposit:{" "}
              <b>{formatToCurrencyString(unformattedAmount, currencyId)}</b>
            </span>
            <div className="h-2.5" />
            <span className="text-red-600">
              The amount shown on the notice is not equal to the amount shown on
              the form. Are you sure you want to continue?
            </span>
          </div>
        }
      />
    </DialogActions>
  );
};

const Deposit = view(({ task, open, setOpen }) => {
  const history = useHistory();

  const { enqueueSnackbar } = useSnackbar();

  const taskStateBooleans = getTaskStateBooleans(task);

  const body =
    !taskStateBooleans.isMessage && task?.body
      ? JSON.parse(task?.body)
      : undefined;

  const [loading, setLoading] = useState(false);
  const [hideConfirmButtons, setHideConfirmButtons] = useState(false);
  const [previewData, setPreviewData] = useState();
  const [step, setStep] = useState(1);

  const form = useForm({
    defaultValues: defaultValues,
    shouldUnregister: false,
  });

  const { setValue } = form;

  useEffect(() => {
    if (task?.amount) {
      setTimeout(() => setValue("amount", task.amount), 0);
    }
  }, [task?.amount, setValue]);

  useEffect(() => {
    if (!open) {
      setStep(1);
      setHideConfirmButtons(false);
      setPreviewData(undefined);
    }
  }, [open]);

  useEffect(() => {
    if (open && task?.currencyId) {
      setTimeout(() => setValue("currencyId", task?.currencyId));
    }
  }, [task?.currencyId, open, setValue]);

  useEffect(() => {
    if (open && task?.toAccount) {
      console.log(2);
      const fees = makeDepositFeeTypes(task?.toAccount);

      const fee = fees[0];

      if (fee?.value) {
        setTimeout(() => setValue("feeType", fee.value));
      }
    }
  }, [task?.toAccount, open, setValue]);

  const preview = async (values) => {
    setLoading(true);

    const payload: any = {
      isPending: values.isPendingTransaction,
      allowNegativeBalance: values.allowNegativeBalance,
      description: `Deposit ${values.amount} ${task?.currencyId} to account ${task?.firstUser?.name}, ${values.comment}`,
      pendingTransactionId: task?.taskId,
      operations: [],
    };

    if (values.transactionTime) {
      payload.transactionTime = new Date(values.transactionTime).toISOString();
    }

    const a = numbro.unformat(values.amount) || 0;
    const f = numbro.unformat(values.fixedOurCost) || 0;

    const plus: any = {
      ourCost: f,
      accountId: task?.toAccount?.accountId,
      currencyId: task?.currencyId,
      amount: a,
      comment: values.comment,
    };

    plus.fee = {};

    let fee = values?.feeType;

    if (fee?.includes("Domestic") || fee?.includes("International")) {
      fee = "DEPBNK";
    } else if (fee?.includes("NO_FEE_")) {
      fee = fee.split("NO_FEE_")[1];
    }

    plus.fee.titleId = fee;
    plus.fee.changeAmount = values.feeInsideAmount;
    plus.fee.miscFee = values.fixedFee
      ? numbro.unformat(values.fixedFee)
      : undefined;

    if (values.feeRule?.ruleId) {
      plus.fee.ruleId = values.feeRule.ruleId;
    }

    if (values.feeStructure?.structureId) {
      plus.fee.structureId = values.feeStructure.structureId;
    }

    payload.operations.push(plus);

    const [status, result] = await admin.preview(payload);

    if (status) {
      setPreviewData(result);
      setStep(2);
    }

    setLoading(false);
  };

  const confirm = async () => {
    setLoading(true);

    const result = await admin.confirm(previewData);

    if (result) {
      setHideConfirmButtons(true);

      enqueueSnackbar("Operation completed successfully", {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });

      if (!!auth?.profile && auth?.profile?.isAdmin) {
        history.goBack();
      } else {
        history.replace(`/app/tasks/inbox/deposit`);
      }
    }

    setLoading(false);
  };

  const [calculatorType, setCalculatorType] = useState("DepositSWIFT");

  const feeType = useWatch({
    name: "feeType",
    control: form.control,
  });

  useEffect(() => {
    if (feeType?.includes("Domestic")) {
      setCalculatorType("DepositSEPA");
    } else if (feeType?.includes("International")) {
      setCalculatorType("DepositSWIFT");
    }
  }, [feeType]);

  return (
    <Alert
      open={open}
      setOpen={setOpen}
      disabledClose={loading}
      hideCloseBtn={loading}
      customTitle={
        <>
          <Title
            icon={
              <ArrowDownward style={{ color: "inherit", fontSize: "2.4rem" }} />
            }
            text={<TitleContent title="Deposit" task={task} />}
          />
        </>
      }
      size={step === 1 ? "sm" : "md"}
      content={
        <div>
          <InitFee
            task={task}
            open={open}
            calculatorType={calculatorType}
            form={form}
          />

          <ShowAndHide show={step === 1}>
            <Amount {...form} autoFocus />

            <div className="h-2.5" />

            <MySimpleMenu
              {...form}
              name="feeType"
              renderOption={(option) => option.label}
              label="Fee Type"
              options={makeDepositFeeTypes(task?.toAccount)}
              fullWidth
            />

            <div className="h-2.5" />

            {task?.currencyId === "EUR" && (
              <CustomRadioButtons
                value={calculatorType}
                onChange={(e, value) => setCalculatorType(value)}
                label="Transfer type"
                row
                items={[
                  {
                    value: "DepositSWIFT",
                    label: "SWIFT",
                  },
                  {
                    value: "DepositSEPA",
                    label: "SEPA",
                  },
                ]}
              />
            )}

            <div className="h-2.5" />

            <Comment {...form} />

            <div className="h-2.5" />

            <MyAmount
              control={form.control}
              controlCurrency={form.control}
              name="fixedFee"
              label="Fixed Fee (the client pays us)"
              currencyName="currencyId"
            />

            <div className="h-2.5" />

            <FixedOurCost {...form} />

            <ShowAdvancedOptions {...form} />
            <AdvancedOptions {...form} />
            <AdvancedOptionsPart2 {...form} />

            <WrapActions
              {...form}
              preview={preview}
              loading={loading}
              setOpen={setOpen}
              body={body}
            />
          </ShowAndHide>

          <ShowAndHide show={step === 2}>
            <Preview
              data={previewData}
              form={form}
              setStep={setStep}
              confirm={confirm}
              hideConfirmButtons={hideConfirmButtons}
              loading={loading}
              setOpen={setOpen}
            />
          </ShowAndHide>
        </div>
      }
    />
  );
});

export default Deposit;
