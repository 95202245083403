import { view } from "@risingstack/react-easy-state";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import accounts from "../../../../../store/modules/accounts";
import auth from "../../../../../store/modules/auth";
import ui from "../../../../../store/modules/ui";
import { en } from "../../../../../translate/en";
import CheckIban from "./components/CheckIban";
import IStepProps, {
  IFormClientDeposit,
  IPayloadForConfirm,
} from "./interfaces/stepProps";
import Step1 from "./Step1";
import Step2 from "./Step2";

const Deposit = view(
  ({
    setOpen,
    initAmount,
    fetch,
    memo,
  }: {
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    initAmount?: string | number;
    fetch?: () => void;
    memo?: string;
  }) => {
    const { account } = accounts;
    const [step, setStep] = useState<number>(1);
    const [data, setData] = useState<Record<string, any>>();
    const [success, setSuccess] = useState<boolean>(false);
    const [payloadForConfirm, setPayloadForConfirm] = useState<
      IPayloadForConfirm | undefined
    >();
    const [canSepa, setCanSepa] = useState<boolean>(false);

    const form = useForm<IFormClientDeposit>({
      defaultValues: {
        nameOnAccount: "",
        email: (!!auth.profile && auth.profile?.email) || "",
        swiftOrSepa: "SWIFT",
        accountNumber: "",
        nameOfBank: memo || "",
        amount: initAmount || "",
        currencyId: "",
      } as IFormClientDeposit,
      mode: "onChange",
    });

    const stepProps: IStepProps = {
      step,
      setStep,
      data,
      setData,
      payloadForConfirm,
      setPayloadForConfirm,
      setOpen,
      account,
      success,
      setSuccess,
      form,
      setCanSepa,
      canSepa,
      fetch,
      formState: form.formState,
    };

    useEffect(() => {
      ui.textLoading = en.deposit.finish.loading;
    }, []);

    const { setValue } = form;

    useEffect(() => {
      setTimeout(() => setValue("currencyId", account?.currencyId || ""), 0);
    }, [account?.currencyId, setValue]);

    useEffect(() => {
      setTimeout(() => setValue("nameOfBank", memo || ""), 0);
    }, [memo, setValue]);

    return (
      <>
        <h1 className="text-center text-gray-600 text-2xl">
          Notification Of Deposit {account?.currencyId}, account
          {account?.shortNumber}
        </h1>

        <CheckIban account={account} {...form} setCanSepa={setCanSepa} />

        {step === 1 && <Step1 {...stepProps} />}
        {step === 2 && <Step2 {...stepProps} />}
      </>
    );
  }
);

export default Deposit;
