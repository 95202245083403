import { CircularProgress } from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import { Delete } from "@material-ui/icons";
import React, {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useState,
} from "react";
import auth from "../../../../../../store/modules/auth";
import files from "../../../../../../store/modules/files";
import { AreYouSure } from "../../../../../System";
import LazyImage from "../../../../../System/LazyImage";

const Picture = memo(
  forwardRef(
    (
      {
        fileId,
        contentType,
        documentId,
        name,
        setImage,
        setFile,
        refresh,
        disableSetFile,
        canOpenPdf,
        thumbnail,
        fileName,
      }: any,
      parentRef: React.LegacyRef<HTMLDivElement> | undefined
    ) => {
      const [img, setImg] = useState(null);

      const [loading, setLoading] = useState(false);
      const [openDeleteAreYouSure, setOpenDeleteAreYouSure] = useState(false);

      const defaultOpen = useCallback(async () => {
        setLoading(true);

        const base64 = await files.openDoc(fileId, false);

        if (setImage) {
          setImage(base64);
        }

        if (!disableSetFile) {
          setFile({ fileId, name, contentType });
        }

        setLoading(false);
      }, [fileId, setImage, disableSetFile, setFile, name, contentType]);

      const fetchImage = useCallback(async () => {
        const result = await files.getMiniImage(fileId);

        if (result) {
          setImg(result);
        }
      }, [fileId]);

      const onClickPdf = useCallback(async () => {
        setLoading(true);

        const base64 = await files.openPdf(fileId, name);

        if (canOpenPdf) {
          setImage(base64);
        }

        setLoading(false);
      }, [fileId, name, setImage, canOpenPdf]);

      useEffect(() => {
        fetchImage();
      }, [fetchImage]);

      let onClick = defaultOpen;

      const deleteFile = useCallback(async () => {
        setLoading(true);

        const result = await files.deleteUserFile(fileId);

        setLoading(false);

        if (result) {
          setOpenDeleteAreYouSure(false);

          if (refresh) {
            refresh();
          }
        }
      }, [refresh, fileId]);

      const openDialogDeleteFile = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setOpenDeleteAreYouSure(true);
      };

      if (!contentType.includes("image")) {
        onClick = onClickPdf;
      }

      const doNotShowDelete =
        auth?.profile &&
        !auth?.profile?.isController &&
        !auth?.profile?.isOperator &&
        !auth.profile?.isTreasurer &&
        !auth.profile?.isTeller;

      return (
        <div className="flex flex-col items-center mb-2">
          {documentId && <b className="mb-2">{documentId}</b>}
          <div
            className={
              thumbnail
                ? "group overflow-hidden border border-gray-300 relative cursor-pointer rounded-md p-2 w-52 hover:ring-2 hover:ring-main"
                : "group overflow-hidden border border-gray-300 relative cursor-pointer w-52 flex rounded-md hover:ring-2 hover:ring-main"
            }
            ref={parentRef}
            onClick={onClick}
          >
            {Boolean(img) && (
              <LazyImage src={img} className="object-cover w-full h-full" />
            )}

            {doNotShowDelete && (
              <div className="invisible group-hover:visible flex absolute bg-black/[0.2] justify-center items-center inset-0 w-full h-full">
                <div
                  className="bg-white p-1 rounded-full flex"
                  onClick={openDialogDeleteFile}
                >
                  <Delete fontSize="large" style={{ color: red[500] }} />
                </div>
              </div>
            )}

            {loading && (
              <div className="w-full h-full absolute left-0 top-0 bg-black/[0.4] flex justify-center items-center">
                <CircularProgress style={{ color: "#fff", fontSize: "4rem" }} />
              </div>
            )}
          </div>

          <div className="relative w-full overflow-hidden flex justify-center">
            {fileName && (
              <b className="mt-2 break-word text-xs text-center max-w-[12rem]">
                {fileName}
              </b>
            )}
          </div>

          {openDeleteAreYouSure && (
            <AreYouSure
              open={openDeleteAreYouSure}
              setOpen={setOpenDeleteAreYouSure}
              confirmCallback={deleteFile}
              loading={loading}
              text="Are you sure you want to delete the file?"
            />
          )}
        </div>
      );
    }
  )
);

export default Picture;
