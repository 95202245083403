import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { allCurrencies } from "../../../../../helpers";
import CustomCheckboxLabel from "../../../../System/Components/CustomCheckboxLabel";

export default memo(({ params, setParams }: any) => {
  const [showCurrencies, setShowCurrencies] = useState(true);
  const [showAllCurrenciesExceptEUR, setShowAllCurrenciesExceptEUR] =
    useState(false);

  const addToCurrencies = useCallback(
    (value) => {
      setParams((prevState) => {
        return {
          ...prevState,
          page: 1,
          currencies: [...prevState.currencies, value],
        };
      });
    },
    [setParams]
  );

  const removeFromCurrencies = useCallback(
    (value) => {
      setParams((prevState) => {
        return {
          ...prevState,
          page: 1,
          currencies: prevState.currencies.filter((item) => item !== value),
        };
      });
    },
    [setParams]
  );

  const filteredCurrencies = allCurrencies.filter(
    (item) => item?.value !== "GOLD" && item.type === "Fiat"
  );

  const filteredCurrenciesForExceptEUR = useMemo(() => {
    return allCurrencies
      .filter(
        (item) =>
          item?.value !== "GOLD" && item.type === "Fiat" && item.value !== "EUR"
      )
      .map((item) => item.value);
  }, []);

  useEffect(() => {
    if (
      params?.currencies?.length === filteredCurrenciesForExceptEUR?.length &&
      !params?.currencies?.includes("EUR")
    ) {
      setShowAllCurrenciesExceptEUR(true);
    } else {
      setShowAllCurrenciesExceptEUR(false);
    }
  }, [filteredCurrenciesForExceptEUR?.length, params?.currencies]);

  const currencies = filteredCurrencies.map((item) => {
    return (
      <CustomCheckboxLabel
        key={item.value}
        checked={params.currencies.includes(item.value) || false}
        label={item.value}
        isSmall
        onChange={(e) => {
          if (e.target.checked) {
            addToCurrencies(item.value);
          } else {
            removeFromCurrencies(item.value);
          }
        }}
      />
    );
  });

  return (
    <>
      <div className="flex items-start justify-start mr-4 mb-2">
        <CustomCheckboxLabel
          checked={showCurrencies}
          isSmall
          label={<span className="whitespace-nowrap">All currencies</span>}
          onChange={(e) => {
            if (e.target.checked && params.currencies.length > 0) {
              setParams((prevState) => {
                return {
                  ...prevState,
                  page: 1,
                  currencies: [],
                };
              });
            }

            setShowCurrencies(e.target.checked);
          }}
        />

        {!showCurrencies && (
          <div className="flex flex-row flex-wrap justify-start items-start min-w-60">
            {currencies}
          </div>
        )}
      </div>

      <div className="flex items-start justify-start mr-4 mb-2">
        <CustomCheckboxLabel
          checked={showAllCurrenciesExceptEUR}
          isSmall
          label={
            <span className="whitespace-nowrap">All currencies except EUR</span>
          }
          onChange={(e) => {
            if (e.target.checked) {
              setParams((prevState) => {
                return {
                  ...prevState,
                  page: 1,
                  currencies: filteredCurrenciesForExceptEUR,
                };
              });

              setShowCurrencies(false);
            } else {
              setParams((prevState) => {
                return {
                  ...prevState,
                  page: 1,
                  currencies: [],
                };
              });

              setShowCurrencies(true);
            }

            setShowAllCurrenciesExceptEUR(e.target.checked);
          }}
        />
      </div>
    </>
  );
});
